import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { getAttributeTag, isLike } from '../../../../utils/functions'
import {
  GetProductAttribute,
  GetProductTemplateAttribute,
  ProductTemplateAttributeInput,
  updateProductTemplateAttributes,
} from '../../../../api/product'
import { DEFAULT_ATTRIBUTE_TYPE } from '../../../../utils/constants'
import Box from '@mui/material/Box'
import CodeInline from '../../../common/CodeInline'
import {
  EtsyPropertyTemplateIntegration,
  EtsyTaxonomyProperty,
} from '../../../../types/Etsy.types'
import { DataFields } from '../../../../api/types'
import { extractAttributeName } from '../../../../classes/Parser'

interface PropertyAttributeSelectProps {
  property: EtsyTaxonomyProperty
  properyIntegration: DataFields<EtsyPropertyTemplateIntegration> | undefined
  templateAttributes: GetProductTemplateAttribute[]
  productAttributes: GetProductAttribute[]
  required?: boolean
  multiple?: boolean
  disabled?: boolean
  onBlur?: () => void
  onChange: (value: string | undefined) => void
  onUpdate?: () => void
  onAddAttribute?: (attributes: GetProductTemplateAttribute[]) => void
}

export default function PropertyAttributeSelect({
  property,
  properyIntegration,
  templateAttributes,
  productAttributes,
  required,
  multiple,
  disabled,
  onBlur,
  onChange,
  onUpdate,
  onAddAttribute,
}: PropertyAttributeSelectProps): JSX.Element {
  const [inputValue, setInputValue] = useState<string>('')
  const [attributeValue, setAttributeValue] = useState<string>('')
  const [error, setError] = useState<string>('')

  const addTemplateAttributeName = templateAttributes.find((a) =>
    isLike(a.attribute.name, property.name),
  )
    ? undefined
    : property.name
  const addTemplateAttributeTag = getAttributeTag(
    addTemplateAttributeName || '',
  )

  const options: string[] = templateAttributes.map((templateAttribute) =>
    getAttributeTag(templateAttribute.attribute.name),
  )

  if (addTemplateAttributeName) {
    options.unshift(addTemplateAttributeTag)
  }

  const handleAddTemplateAttribute = async () => {
    // Get template id from first template attribute
    const templateId = templateAttributes?.[0]?.attribute.templateId
    const newIndex = templateAttributes.length // length+1
    if (!addTemplateAttributeName || !templateId) return
    // add attribute
    const addTemplateAttr: ProductTemplateAttributeInput = {
      attribute: {
        name: addTemplateAttributeName,
        type: DEFAULT_ATTRIBUTE_TYPE,
        index: newIndex,
      },
      attributeOptions: [],
    }
    const res = await updateProductTemplateAttributes(templateId, [
      addTemplateAttr,
    ])
    if (res.success && res.data) {
      console.log(res.data)
      onAddAttribute?.(res.data)
    }
    onUpdate?.()
  }

  useEffect(() => {
    const productValue = extractAttributeName(properyIntegration?.values)
    const productAttributeValue = productAttributes.find(
      (a) => a.templateAttribute.name === productValue,
    )?.attribute?.value

    setAttributeValue(productAttributeValue || '')
    const possibleValues = property.possible_values.map((v) => v.name)
    if (
      productAttributeValue &&
      property.possible_values.length &&
      !possibleValues.includes(productAttributeValue)
    ) {
      setError(
        `${productAttributeValue} is not a valid Etsy ${property.name} value.`,
      )

      if (property.is_required && !attributeValue) {
        setError(`${property.name} value is required.`)
      }
    }
  }, [attributeValue, options, productAttributes, property, properyIntegration])

  return multiple ? (
    <Autocomplete
      disablePortal
      clearOnEscape
      disabled={disabled}
      autoHighlight={true}
      options={options}
      multiple={multiple}
      value={properyIntegration?.values?.split(',') || []}
      onBlur={onBlur}
      inputValue={inputValue}
      onInputChange={(e, value) => {
        setInputValue(value)
      }}
      onChange={(e, newValue) => {
        if (Array.isArray(newValue)) {
          if (!newValue.length) return
          onChange(newValue?.join(',') || undefined)
          return
        }
        onChange(newValue || undefined)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          required={required}
          // onChange={(e) => {
          //   onChange(e.target.value)
          // }}
          label={'Attribute'}
        />
      )}
      getOptionLabel={(option) => option}
      renderOption={(props, option) => {
        return (
          <Box {...props} component="li">
            {option === addTemplateAttributeTag ? (
              <Grid
                container
                justifyContent="space-between"
                onClick={handleAddTemplateAttribute}
              >
                <Grid item>
                  <span>
                    <strong>Add Attribute:</strong>
                    {'  '}
                    <CodeInline>{option}</CodeInline>
                  </span>
                </Grid>
              </Grid>
            ) : (
              <CodeInline>{option}</CodeInline>
            )}
          </Box>
        )
      }}
    ></Autocomplete>
  ) : (
    <Autocomplete
      disablePortal
      clearOnEscape
      disabled={disabled}
      autoHighlight={true}
      freeSolo={true}
      options={options}
      value={attributeValue || properyIntegration?.values || ''}
      onBlur={onBlur}
      onChange={(e, newValue) => {
        onChange(newValue || undefined)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={error}
          error={!!error}
          size="small"
          required={required}
          onChange={(e) => {
            onChange(e.target.value)
          }}
          label={'Attribute'}
        />
      )}
      getOptionLabel={(option) => option}
      renderOption={(props, option) => {
        return (
          <Box {...props} component="li">
            {option === addTemplateAttributeTag ? (
              <Grid
                container
                justifyContent="space-between"
                onClick={handleAddTemplateAttribute}
              >
                <Grid item>
                  <span>
                    <strong>Add Attribute:</strong>
                    {'  '}
                    <CodeInline>{option}</CodeInline>
                  </span>
                </Grid>
              </Grid>
            ) : (
              <CodeInline>{option}</CodeInline>
            )}
          </Box>
        )
      }}
    ></Autocomplete>
  )
}
