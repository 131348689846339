import React from 'react'
import Typography from '@mui/joy/Typography'
import Button from '@mui/joy/Button'
import { log } from '../../../../utils/functions'
import Grid from '@mui/material/Grid'
import { Integration } from '../../../../api/types'

interface EbayAuthorizationProps {
  integration: Integration | undefined
  authorized: boolean | undefined
  oAuthUrl?: string
}
export default function EbayAuthorization({
  integration,
  authorized,
  oAuthUrl,
}: EbayAuthorizationProps): JSX.Element | null {
  const handleClick = () => {
    if (oAuthUrl) {
      window.open(oAuthUrl, '_blank')
    } else {
      log('Invalid OAuth Url')
    }
  }

  return authorized !== false ? (
    <Grid container justifyContent="center" direction="column" spacing={1}>
      <Grid item xs={12}>
        <Button sx={{ mt: 2 }} variant="solid" onClick={handleClick}>
          Re-Authorize
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography level="body-xs">
          Authentication Last Updated:{' '}
          {integration?.updatedAt
            ? new Date(integration?.updatedAt).toLocaleString()
            : 'Unknown'}
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography>
            In order to access your Ebay APIs, link your Ebay account below
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button sx={{ mt: 2 }} variant="solid" onClick={handleClick}>
            Authorize
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
