import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import {
  Aspect,
  AspectUsageEnum,
  EbayAspectTemplateIntegration,
  EbayTemplateIntegration,
} from '../../../../types/Ebay.types'
import {
  GetProduct,
  GetProductTemplateAttribute,
} from '../../../../api/product'
import { useMemo } from 'react'
import Alert, { AlertInput } from '../../../common/Alert'
import { getAttributeName } from '../../../../utils/functions'
import NamedSingleSelect from '../../../common/NamedSingleSelect'
import NamedInput from '../../../common/NamedInput'

export default function EbayProductAspects({
  product,
  templateIntegration,
  aspects,
  aspectIntegrations,
  templateAttributes,
  disabled,
  onChange,
  onUpdate,
}: {
  product: GetProduct
  templateIntegration?: EbayTemplateIntegration
  aspects: Aspect[]
  aspectIntegrations: EbayAspectTemplateIntegration[]
  templateAttributes: GetProductTemplateAttribute[]
  disabled?: boolean
  onChange: (aspect: EbayAspectTemplateIntegration) => void
  onUpdate?: () => void
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  // const handleChange = (aspect: AspectIntegrationInput) => {
  //   onChange(aspect)
  //   // onUpdate?.()
  // }

  const productAspectAttributeValues: { [k: string]: string | undefined } =
    useMemo(() => {
      const aspectAttributes: { [k: string]: string | undefined } = {}
      aspectIntegrations.forEach((aspectIntegration) => {
        const attributeName = getAttributeName(aspectIntegration.value)
        if (!attributeName) return
        aspectAttributes[aspectIntegration.aspectName] = attributeName
      })

      const productAspectAttributeValuesObj: {
        [k: string]: string | undefined
      } = {}
      product.attributes.forEach((attribute) => {
        const aspectname = aspectAttributes[attribute.templateAttribute.name]
        if (!aspectname) return
        productAspectAttributeValuesObj[aspectname] = attribute.attribute?.value
      })
      return productAspectAttributeValuesObj
    }, [aspectIntegrations, product.attributes])

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Typography level="title-lg">Aspects</Typography>
        <Typography level="body-xs">
          Aspect Attribute mapping can be updated on Template Ebay Integration
          page
        </Typography>
      </Grid>
      {aspects.map((aspect) => {
        const value =
          productAspectAttributeValues[aspect.localizedAspectName] || ''
        let valueInOptions = false
        const options: string[] = []
        aspect.aspectValues?.forEach((v) => {
          if (v.localizedValue === value) {
            valueInOptions = true
          }
          options.push(v.localizedValue)
        })

        console.log(aspect.aspectConstraint)

        const isError =
          aspect.aspectConstraint.aspectMode === 'SELECTION_ONLY' &&
          valueInOptions

        return (
          <Grid item xs={12} key={aspect.localizedAspectName}>
            {aspect.aspectValues?.length ? (
              <NamedSingleSelect
                name={aspect.localizedAspectName}
                nameEditable={false}
                freeSolo={aspect.aspectConstraint.aspectMode === 'FREE_TEXT'}
                required={aspect.aspectConstraint.aspectRequired}
                label={aspect.localizedAspectName}
                disabled={disabled}
                helperText={
                  (aspect.aspectConstraint.aspectRequired ? 'Required' : '') ||
                  (!aspect.aspectConstraint.aspectRequired &&
                  aspect.aspectConstraint.aspectUsage ===
                    AspectUsageEnum.RECOMMENDED
                    ? 'Reccommended'
                    : '')
                }
                errorText={isError ? 'Value is not recognized by Ebay.' : ''}
                options={options}
                value={value}
              />
            ) : (
              <NamedInput
                name={aspect.localizedAspectName}
                nameEditable={false}
                required={aspect.aspectConstraint.aspectRequired}
                disabled={disabled}
                helperText={
                  (aspect.aspectConstraint.aspectRequired ? 'Required' : '') ||
                  (!aspect.aspectConstraint.aspectRequired &&
                  aspect.aspectConstraint.aspectUsage ===
                    AspectUsageEnum.RECOMMENDED
                    ? 'Reccommended'
                    : '')
                }
                value={productAspectAttributeValues[aspect.localizedAspectName]}
              />
            )}
          </Grid>
        )
      })}

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
