import * as React from 'react'
import Input from '@mui/joy/Input'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Typography from '@mui/joy/Typography'
// import { styled } from '@mui/joy/styles'

// interface CustomProps {
//   onChange: (event: { target: { name: string; value: string } }) => void
//   name: string
//   prefix: string
// }
// const NumericFormatAdapter = React.forwardRef<NumericFormatProps, CustomProps>(
//   function NumericFormatAdapter(props, ref) {
//     const { onChange, ...other } = props

//     return (
//       <NumericFormat
//         {...other}
//         getInputRef={ref}
//         onValueChange={(values) => {
//           onChange({
//             target: {
//               name: props.name,
//               value: values.value,
//             },
//           })
//         }}
//         thousandSeparator
//         valueIsNumericString
//         prefix={props.prefix}
//       />
//     )
//   },
// )

// const StyledInput = styled('input')({
//   border: 'none', // remove the native input border
//   minWidth: 0, // remove the native input width
//   outline: 0, // remove the native input outline
//   padding: 0, // remove the native input padding
//   paddingTop: '1em',
//   flex: 1,
//   color: 'inherit',
//   backgroundColor: 'transparent',
//   fontFamily: 'inherit',
//   fontSize: 'inherit',
//   fontStyle: 'inherit',
//   fontWeight: 'inherit',
//   lineHeight: 'inherit',
//   textOverflow: 'ellipsis',
//   '&::placeholder': {
//     opacity: 0,
//     transition: '0.1s ease-out',
//   },
//   '&:focus::placeholder': {
//     opacity: 1,
//   },
//   '&:focus ~ label, &:not(:placeholder-shown) ~ label, &:-webkit-autofill ~ label':
//     {
//       top: '0.5rem',
//       fontSize: '0.75rem',
//     },
//   '&:focus ~ label': {
//     color: 'var(--Input-focusedHighlight)',
//   },
//   '&:-webkit-autofill': {
//     alignSelf: 'stretch', // to fill the height of the root slot
//   },
//   '&:-webkit-autofill:not(* + &)': {
//     marginInlineStart: 'calc(-1 * var(--Input-paddingInline))',
//     paddingInlineStart: 'var(--Input-paddingInline)',
//     borderTopLeftRadius:
//       'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
//     borderBottomLeftRadius:
//       'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
//   },
// })

// const StyledLabel = styled('label')(({ theme }) => ({
//   position: 'absolute',
//   lineHeight: 1,
//   top: 'calc((var(--Input-minHeight) - 1em) / 2)',
//   color: theme.vars.palette.text.tertiary,
//   fontWeight: theme.vars.fontWeight.md,
//   transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
// }))

// interface InputLabelProps {
//   label?: string
// }
// const InnerInput = React.forwardRef<HTMLInputElement, InputLabelProps>(
//   function InnerInput(props, ref) {
//     const id = React.useId()
//     return (
//       <React.Fragment>
//         <StyledInput {...props} ref={ref} id={id} />
//         <StyledLabel htmlFor={id}>{props.label}</StyledLabel>
//       </React.Fragment>
//     )
//   },
// )

export default function LabelInput({
  value,
  onChange,
  onClick,
  onBlur,
  onFocus,
  onKeyDown,
  label,
  placeholder,
  type,
  size,
  fullWidth,
  required,
  disabled,
  readOnly,
  errorText,
  helperText,
  autoComplete,
  autoCapitalize,
  autoCorrect,
  format,
  endDecorator,
}: {
  value?: string | number
  onChange?: (value: string) => void
  onBlur?: (e?: React.FocusEvent<HTMLInputElement, Element>) => void
  onClick?: () => void
  onFocus?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  label?: string | JSX.Element
  placeholder?: string
  type?: React.HTMLInputTypeAttribute
  size?: 'sm' | 'md' | 'lg'
  fullWidth?: boolean
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  errorText?: string
  helperText?: string
  autoComplete?: string
  autoCapitalize?: boolean
  autoCorrect?: boolean
  format?: 'USD'
  endDecorator?: React.ReactNode
}): JSX.Element {
  return (
    // <Input
    //   value={value}
    //   onChange={(e) => onChange?.(e.target.value)}
    //   fullWidth={fullWidth}
    //   error={!!errorText}
    //   slots={{ input: (props) => <InnerInput {...props} label={label} /> }}
    //   slotProps={{
    //     input: { placeholder: 'A placeholder' },
    //   }}
    //   sx={{
    //     '--Input-minHeight': '56px',
    //     '--Input-radius': '6px',
    //   }}
    // />

    <FormControl>
      {label && typeof label === 'string' ? (
        <FormLabel sx={{ width: '100%' }}>
          <Typography level="title-sm">
            <strong>{label}</strong>
          </Typography>
        </FormLabel>
      ) : null}
      {label && typeof label !== 'string' ? (
        <FormLabel sx={{ width: '100%' }}>{label}</FormLabel>
      ) : null}
      <Input
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onClick={onClick}
        autoComplete={autoComplete ? autoComplete : 'off'}
        slotProps={{
          input: {
            autoCapitalize: autoCapitalize === false ? 'none' : undefined,
            autoCorrect: autoCorrect === false ? 'off' : undefined,
          },
        }}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        size={size}
        fullWidth={fullWidth}
        error={!!errorText}
        type={type}
        readOnly={readOnly}
        endDecorator={endDecorator}
      />
      {errorText || helperText ? (
        <FormHelperText sx={{ ml: 1 }}>
          {errorText || helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}
