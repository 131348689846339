import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import EbayAuthorization from '../../components/product/integrations/ebay/EbayAuthorization'
import { GetIntegrationResult, IntegrationName } from '../../api/integration'
import EbayPolicies from '../../components/product/integrations/ebay/EbayPolicies'
import Alert, { AlertInput } from '../../components/common/Alert'

export default function EbayIntegration({
  integration,
}: {
  integration: GetIntegrationResult<IntegrationName.EBAY>
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  return (
    <Grid container spacing={8} key={integration.integration?.id}>
      <Grid item xs={12}>
        <EbayPolicies integration={integration.integration} />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography level="title-lg">Authentication</Typography>

            {integration.authenticated && integration.oAuthUrl ? (
              <>
                <Typography level="body-sm">
                  Third-party-app access{' '}
                  <a
                    href="https://accounts.ebay.com/acctsec/security-center/third-party-app-access"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Edit on Ebay
                  </a>
                </Typography>
                <Typography level="body-sm">
                  If encountering authentication errors, you may need to
                  re-authorize
                </Typography>
              </>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <EbayAuthorization
              integration={integration.integration}
              authorized={integration.authenticated}
              oAuthUrl={integration.oAuthUrl}
            />
          </Grid>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
