import React, { useState } from 'react'
import Autocomplete from '@mui/joy/Autocomplete'
import AutocompleteOption from '@mui/joy/AutocompleteOption'
import { getAttributeTag } from '../../../../utils/functions'
import { GetProductTemplateAttribute } from '../../../../api/product'
import CodeInline from '../../../common/CodeInline'

type AttributeAutocompleteProps = {
  label: string
  name?: string
  nameEditable?: boolean
  value?: string
  values?: string[]
  templateAttributes: GetProductTemplateAttribute[] | undefined
  options?: string[]
  multiple?: boolean
  required?: boolean
  freeSolo?: boolean
  onBlur?: () => void
  onChange?: (value: string | undefined) => void
  onSelect?: (values: string[]) => void
  onUpdate?: () => void
}

export default function AttributeAutocomplete({
  label,
  name,
  nameEditable,
  value,
  values,
  templateAttributes,
  options,
  multiple,
  required,
  freeSolo,
  onBlur,
  onChange,
  onSelect,
}: AttributeAutocompleteProps): JSX.Element {
  const [inputValue, setInputValue] = useState<string>('')

  const templateOptions: string[] =
    templateAttributes?.map((templateAttribute) =>
      getAttributeTag(templateAttribute.attribute.name),
    ) || []

  const allOptions = (options || [])?.concat(templateOptions)

  const renderOption = (
    props: Omit<React.HTMLAttributes<HTMLLIElement>, 'color'>,
    option: string,
  ) => (
    <AutocompleteOption {...props}>
      <CodeInline>{option}</CodeInline>
    </AutocompleteOption>
  )

  return multiple ? (
    <Autocomplete
      placeholder={label}
      clearOnEscape
      autoHighlight={true}
      options={allOptions}
      multiple={multiple}
      value={values}
      freeSolo={freeSolo}
      onBlur={onBlur}
      inputValue={inputValue}
      onInputChange={(e, value) => {
        setInputValue(value)
      }}
      onChange={(e, newValues) => {
        onSelect?.(newValues)
      }}
      getOptionLabel={(option) => option}
      renderOption={renderOption}
    ></Autocomplete>
  ) : (
    <Autocomplete
      placeholder={label}
      clearOnEscape
      autoHighlight={true}
      freeSolo={freeSolo}
      options={allOptions}
      value={value}
      onBlur={onBlur}
      onChange={(e, newValue) => {
        onChange?.(newValue || undefined)
      }}
      getOptionLabel={(option) => option}
      renderOption={renderOption}
    ></Autocomplete>
  )
}
