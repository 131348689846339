import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/joy/Button'
import Alert, { AlertInput } from '../common/Alert'
import { SEC_TO_MS } from '../../utils/constants'
import { GetProduct } from '../../api/product'
import Tabs from '../common/Tabs'
import EbayTemplateIntegrationComponent from './integrations/ebay/EbayTemplateIntegrationComponent'
import SquareTemplateIntegrationComponent from './integrations/square/SquareTemplateIntegrationComponent'
import ShopifyTemplateIntegrationComponent from './integrations/shopify/ShopifyTemplateIntegrationComponent'
import BigCommerceTemplateIntegrationComponent from './integrations/bigcommerce/BigCommerceTemplateIntegrationComponent'
import CloverTemplateIntegrationComponent from './integrations/clover/CloverTemplateIntegrationComponent'
import EtsyTemplateIntegrationComponent from './integrations/etsy/EtsyTemplateIntegrationComponent'
import CSVTemplateIntegrationComponent from './integrations/csv/CSVTemplateIntegrationComponent'
import { useHistory, useParams } from 'react-router-dom'
import {
  IntegrationDisplayName,
  IntegrationDisplayNameToName,
  IntegrationName,
} from '../../api/integration'

interface ProductIntegrationsProps {
  product: GetProduct
}
export default function ProductIntegrations({
  product,
}: ProductIntegrationsProps): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })
  const [errorMessage, setErrorMessage] = useState('')

  const { channel } = useParams<{
    productId?: string
    tab?: string
    channel?: string
  }>()
  const history = useHistory()

  const selectedTab =
    IntegrationDisplayName[channel as IntegrationName] || 'Ebay'
  const setSelectedChannel = (channel: string) => {
    const channelName = IntegrationDisplayNameToName[channel] || 'ebay'
    history.push(`/product/${product.product.id}/channels/${channelName}`, {
      shallow: true,
    })
  }
  return (
    <>
      {/* <Box p={3}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography level="title-md">
              <strong>Bulk Actions</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Button size="sm" variant="outlined">
              List
            </Button>
          </Grid>
          <Grid item>
            <Button size="sm" variant="outlined">
              Sync
            </Button>
          </Grid>
          <Grid item>
            <Button size="sm" variant="outlined">
              Sync Quantity
            </Button>
          </Grid>
        </Grid>
      </Box> */}

      <Tabs
        tabs={[
          'Ebay',
          'Etsy',
          'Square',
          'Shopify',
          'Big Commerce',
          'Clover',
          // 'Bulk View',
          'CSV',
        ]}
        selected={selectedTab}
        onSelect={setSelectedChannel}
        panels={[
          <EbayTemplateIntegrationComponent key="ebay" product={product} />,
          <EtsyTemplateIntegrationComponent key="etsy" product={product} />,
          <SquareTemplateIntegrationComponent key="square" product={product} />,
          <ShopifyTemplateIntegrationComponent
            key="shopify"
            product={product}
          />,
          <BigCommerceTemplateIntegrationComponent
            key="bigcommerce"
            product={product}
          />,
          <CloverTemplateIntegrationComponent key="clover" product={product} />,
          // <BulkProductIntegrations key="bulk" product={product} />,
          <CSVTemplateIntegrationComponent key="csv" product={product} />,
        ]}
      />

      {errorMessage ? (
        <Box p={3}>
          <Grid container justifyContent="flex-end">
            <Button
              variant="outlined"
              color="danger"
              onClick={() => setErrorMessage('')}
            >
              Close
            </Button>
          </Grid>
          <div dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
        </Box>
      ) : null}
      <Alert
        alert={alert}
        duration={10 * SEC_TO_MS}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </>
  )
}
