import React, { useEffect, useState } from 'react'
import {
  GetIntegrationResult,
  IntegrationName,
  getIntegration,
  listProduct,
  unlistProduct,
} from '../../../../api/integration'
import { GetProduct, GetProductTemplate } from '../../../../api/product'
import Grid from '@mui/material/Grid'
import { ProductAttributeInputText } from '../../../../pages/products/product/ProductAttributeInputText'
import Button from '@mui/joy/Button'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import { DataFields, Integration } from '../../../../api/types'
import {
  err,
  getDescriptionValue,
  getTitleValue,
} from '../../../../utils/functions'
import ListSyncComponent from '../common/ListSyncComponent'
import {
  BigCommerceLocation,
  BigCommerceProductIntegration,
  BigCommerceTemplateIntegration,
  SetBigCommerceTemplateIntegration,
} from '../../../../types/BigCommerce.types'
import {
  getBigCommerceIntegrations,
  getBigCommerceLocations,
  setBigCommerceProductIntegration,
  setBigCommerceTemplateIntegration,
} from '../../../../api/integrations/bigcommerce'
import LoadingBox from '../../../common/LoadingBox'
import { ListUnlistButtons } from '../common/ListUnlistButtons'
import { ProductTitleDescription } from '../../ProductTitleDescription'
import MissingIntegration from '../MissingIntegration'
import NamedInput from '../../../common/NamedInput'
import AttributeSelect from '../../../common/AttributeSelect'
import MissingTemplateIntegration from '../MissingTemplateIntegration'

interface BigCommerceTemplateIntegrationComponentProps {
  integration?: GetIntegrationResult<IntegrationName.BIG_COMMERCE> | undefined
  template?: GetProductTemplate
  product?: GetProduct
  onUpdate?: () => void
}
export default function BigCommerceTemplateIntegrationComponent({
  integration: integrationResult,
  template,
  product,
}: BigCommerceTemplateIntegrationComponentProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(!integrationResult)

  const [templateIntegration, setTemplateIntegration] = useState<
    DataFields<BigCommerceTemplateIntegration> | undefined
  >()
  const [productIntegration, setProductIntegration] = useState<
    DataFields<BigCommerceProductIntegration> | undefined
  >()
  const [integration, setIntegration] = useState<Integration | undefined>(
    integrationResult?.integration,
  )

  const [detailUpdate, setDetailUpdate] = useState<number>(0)

  const [title, setTitle] = useState<string>('')

  const [description, setDescription] = useState<string>('')
  const [category, setCategory] = useState<string>('')
  const [brand, setBrand] = useState<string>('')
  const [tags, setTags] = useState<string>('')
  const [list, setList] = useState<boolean>(false)
  const [sync, setSync] = useState<boolean>(false)
  const [syncQuantity, setSyncQuantity] = useState<boolean>(false)

  const [locations, setLocations] = useState<BigCommerceLocation[]>([])
  const [selectedLocation, setSelectedLocation] = useState<
    | {
        name: string
        id: number
      }
    | undefined
  >()

  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleListProduct = () => {
    const productId = product?.product.id
    if (!productId) {
      return
    }
    listProduct(IntegrationName.BIG_COMMERCE, productId)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully listed product.')
      })
      .catch((e) => {
        setAlert({
          open: true,
          severity: 'error',
          message: `${e.response.data.message}`,
        })
      })
  }

  const handleUnlistProduct = () => {
    const productId = product?.product.id
    if (!integration?.id || !productId) return
    unlistProduct(IntegrationName.BIG_COMMERCE, productId, integration.id)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully Unlisted.')
      })
      .catch((e) =>
        setAlert({
          open: true,
          severity: 'error',
          message: e.response.data.message,
        }),
      )
  }

  // const handleSelectLocation = (locationName: string | undefined) => {
  //   if (!locationName) {
  //     setSelectedLocation(undefined)
  //     return
  //   }
  //   const location = locations.find((l) => l.label === locationName)
  //   if (!location) {
  //     setSelectedLocation(undefined)
  //     return
  //   }
  //   setSelectedLocation({ id: location.id, name: location.label })
  // }

  // Get and set BigCommerceTemplateIntegration
  useEffect(() => {
    // Integration
    if (!integration) {
      getIntegration<IntegrationName.BIG_COMMERCE>(IntegrationName.BIG_COMMERCE)
        .then((res) => {
          setLoading(false)
          if (res.success && res.data) {
            setIntegration(res.data.integration)
          }
        })
        .catch((e) => err(e))
    }
  }, [integration])

  useEffect(() => {
    getBigCommerceLocations()
      .then((res) => {
        if (res.success && res.data) {
          console.log('Setting Locations')
          setLocations(res.data)
        }
      })
      .catch((e) => err(e))
  }, [])

  useEffect(() => {
    const locationId = productIntegration
      ? productIntegration.locationId
      : templateIntegration?.locationId
    if (!locations.length || !locationId) return
    const location = locations.find((l) => `${l.id}` === locationId)
    if (!location) return

    setSelectedLocation({ id: location?.id, name: location.label })
  }, [templateIntegration, productIntegration, locations])

  useEffect(() => {
    if (!integration) {
      return
    }

    const integrationId = integration.id
    const templateId = template?.template.id || product?.template.id
    // BigCommerce Template Integration
    if (integrationId && templateId) {
      getBigCommerceIntegrations(integrationId, templateId, product?.product.id)
        .then((res) => {
          if (res.success && res.data) {
            const bigcommerceProductIntegration = res.data.productIntegration
            const bigcommerceTemplateIntegration = res.data.templateIntegration

            console.log(
              'Setting Template Integration',
              bigcommerceTemplateIntegration,
            )
            setTemplateIntegration(bigcommerceTemplateIntegration)
            setProductIntegration(bigcommerceProductIntegration)
            // TODO:
            // setBrand
            setList(
              bigcommerceProductIntegration?.list ??
                bigcommerceTemplateIntegration?.list ??
                false,
            )
            setSync(
              bigcommerceProductIntegration?.sync ??
                bigcommerceTemplateIntegration?.sync ??
                false,
            )
            setSyncQuantity(
              bigcommerceProductIntegration?.syncQuantity ??
                bigcommerceTemplateIntegration?.syncQuantity ??
                false,
            )
          }
        })
        .catch((e) => err(e))
    }
  }, [integration, product, template])

  // Set title and description state
  useEffect(() => {
    const title = getTitleValue(
      productIntegration,
      templateIntegration,
      product,
    )
    const description = getDescriptionValue(
      productIntegration,
      templateIntegration,
      product,
    )

    setTitle(title)
    setDescription(description)
    setDetailUpdate(detailUpdate + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, template, productIntegration, templateIntegration])

  const handleSave = () => {
    if (product && templateIntegration?.id) {
      const updateProductIntegration = {
        templateIntegrationId: templateIntegration.id,
        productId: product.product.id,
        title,
        description,
        locationId: selectedLocation?.id ? `${selectedLocation.id}` : undefined,
        list,
        sync,
        syncQuantity,
      }
      setBigCommerceProductIntegration(updateProductIntegration)
        .then((res) => {
          handleAlert(setAlert, res, 'Set BigCommerce Product Integration.')
        })
        .catch((e) => {
          err(e)
        })
      return
    }

    if (!integration || !template) return
    const shopifyTemplateIntegration: SetBigCommerceTemplateIntegration = {
      templateId: template?.template.id,
      integrationId: integration.id,
      // brandId: brand,
      locationId: selectedLocation?.id ? `${selectedLocation.id}` : undefined,
      title,
      description,
      list,
      sync,
      syncQuantity,
    }
    setBigCommerceTemplateIntegration(shopifyTemplateIntegration)
      .then((res) => {
        handleAlert(setAlert, res, 'Set BigCommerce Template Integration.')
      })
      .catch((e) => {
        err(e)
      })
  }

  const attributeNames =
    template?.attributes.map((a) => a.attribute.name) ||
    product?.attributes.map((a) => a.templateAttribute.name)

  if (loading) {
    return <LoadingBox />
  }

  if (!loading && !integration) {
    return <MissingIntegration />
  }

  if (product && !templateIntegration) {
    return (
      <MissingTemplateIntegration
        templateId={product.product.templateId}
        channelName={IntegrationName.CSV}
      />
    )
  }

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <ListUnlistButtons
          list={list}
          hasProductIntegration={!!productIntegration}
          product={product}
          onList={handleListProduct}
          onUnlist={handleUnlistProduct}
        />
      </Grid>
      <Grid item xs={12}>
        <ListSyncComponent
          list={list}
          sync={sync}
          syncQuantity={syncQuantity}
          onListChange={setList}
          onSyncChange={setSync}
          onSyncQuantityChange={setSyncQuantity}
        />
      </Grid>
      <Grid item xs={12}>
        <ProductTitleDescription
          key={`product-title-description-${detailUpdate}`}
          template={template}
          product={product}
          title={title}
          description={description}
          onTitleChange={setTitle}
          onDescriptionChange={setDescription}
          disableUnparsedEdit={true}
        />
      </Grid>

      {product && (
        <>
          <Grid item xs={12}>
            <ProductAttributeInputText
              label="SKU"
              value={product.product.sku}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <ProductAttributeInputText
              label="Condition"
              value={product.product.condition}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <NamedInput
              name="Price"
              nameEditable={false}
              value={product.product.price}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <NamedInput
              name="Quantity"
              nameEditable={false}
              value={product.product.quantity}
              disabled={true}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <AttributeSelect
          name="Category"
          nameEditable={false}
          value={category}
          attributeNames={attributeNames}
          multiple={false}
          onChange={(v) => setCategory(v || '')}
        />
      </Grid>

      <Grid item xs={12}>
        <AttributeSelect
          name="Brand"
          nameEditable={false}
          value={brand}
          attributeNames={attributeNames}
          multiple={false}
          onChange={(v) => setBrand(v || '')}
        />
      </Grid>

      <Grid item xs={12}>
        <AttributeSelect
          name="Tags"
          nameEditable={false}
          values={tags ? tags.split(',') : []}
          attributeNames={attributeNames}
          multiple={true}
          onSelect={(v) => setTags((v as string[]).join(','))}
        />
      </Grid>

      {/* 
      <Grid item xs={12}>
        <NamedSingleSelect
          name="BigCommerce Location"
          nameEditable={false}
          value={selectedLocation?.name || ''}
          onChange={(location) => handleSelectLocation(location)}
          options={locations.map((l) => l.label)}
        />
      </Grid> */}

      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button variant="solid" onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
