import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box } from '@mui/material'
import Typography from '@mui/joy/Typography'

const MENU_ITEM_HEIGHT = 48

interface VerticalIconMenuProps {
  options: string[]
  disabledOptions?: string[]
  onClick?: (option: string) => void
  p?: number | string
  size?: 'small' | 'medium' | 'large'
}

export default function VerticalIconMenu({
  options,
  disabledOptions,
  onClick,
  p,
  size,
}: VerticalIconMenuProps): JSX.Element {
  const [open, setOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }
  const handleMenuClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  return (
    <Box>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        sx={{ p }}
        onClick={handleMenuClick}
        size={size}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: MENU_ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            data-option={option}
            disabled={disabledOptions?.includes(option)}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={(e) => onClick?.((e.target as any).innerText)}
          >
            <Typography>{option}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
