import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/joy/Typography'
import { deleteProductImage } from '../../api/product'
import { err, log } from '../../utils/functions'
import ImageDrag, { ImageDragItem } from './ImageDrag'
import Box from '@mui/joy/Box'
import IconButton from '@mui/joy/IconButton'
import Add from '@mui/icons-material/Add'
import Modal from '@mui/joy/Modal'
import ModalDialog from '@mui/joy/ModalDialog'
import ModalClose from '@mui/joy/ModalClose'

interface ImageUploadProps {
  id: string
  productId?: number
  variationId?: number
  images: string[]
  selectedImages: File[]
  onChange: (images: string[]) => void
  onSelect: (files: File[]) => void
}
export default function ImageUpload({
  id,
  productId,
  variationId,
  images,
  selectedImages,
  onChange,
  onSelect,
}: ImageUploadProps): JSX.Element {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedImage, setSelectedImage] = useState<string | File>('')

  const handleSelect = (files: File[]) => {
    let newSelectedImages = [...selectedImages]
    newSelectedImages = newSelectedImages.concat(files)

    // do not add duplicates
    const names: string[] = []
    newSelectedImages = newSelectedImages.reduce(
      (accum: File[], curr: File): File[] => {
        if (!names.includes(curr.name)) {
          names.push(curr.name)
          accum.push(curr)
          return accum
        } else {
          return accum
        }
      },
      [],
    )

    onSelect(newSelectedImages)
  }

  const handleSelectedImageRemove = (index: number) => {
    const newSelectedImages = selectedImages.slice()
    newSelectedImages.splice(index, 1)
    onSelect(newSelectedImages)
  }

  const handleImageRemove = (index: number) => {
    const newImages = images.slice()
    newImages.splice(index, 1)
    onChange(newImages)
  }

  const handleImageDelete = (index: number) => {
    const imageUrl = images[index]
    if (!imageUrl) return
    if (!productId) return
    deleteProductImage(productId, variationId, imageUrl)
      .then((res) => {
        log(res)
        if (res.success) {
          handleImageRemove(index)
          log('success')
        }
      })
      .catch((e) => err(e))
  }

  const handleImageReorder = (item: ImageDragItem, newIndex: number) => {
    const oldIndex = item.index
    const newImages = images.slice() // copy
    const url = newImages[oldIndex] // get value
    if (!url) return
    newImages.splice(oldIndex, 1) // remove old
    newImages.splice(newIndex, 0, url) // splice value into new location
    onChange(newImages)
  }

  const handleSelectedImageReorder = (
    item: ImageDragItem,
    newIndex: number,
  ) => {
    const oldIndex = item.index
    const newSelectedImages = selectedImages.slice() // copy
    const url = newSelectedImages[oldIndex] // get value
    if (!url) return
    newSelectedImages.splice(oldIndex, 1) // remove old
    newSelectedImages.splice(newIndex, 0, url) // splice value into new location
    onSelect(newSelectedImages)
  }

  const handleImageClick = (image: string | File) => {
    setSelectedImage(image)
    setModalOpen(true)
  }

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12} md={3} lg={2}>
        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            position: 'relative',
            height: '100%',
            top: 10,
          }}
        >
          <Typography sx={{ mr: '0.5em', mb: '0.5em' }}>
            <strong>Images</strong>
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={9} lg={10}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              direction="row"
              wrap="nowrap"
              justifyContent="flex-start"
              style={{
                maxHeight: '100vh',
                overflow: 'auto',
                overflowX: 'auto',
                padding: '1em',
              }}
            >
              {images.map((image: string, i) => (
                <Grid item key={image}>
                  <ImageDrag
                    value={image}
                    index={i}
                    onChange={handleImageReorder}
                    onDelete={(index) => handleImageDelete(index)}
                    onClick={(image) => handleImageClick(image)}
                  />
                </Grid>
              ))}
              {selectedImages.map((image: File, i) => (
                <Grid item key={image.name}>
                  <ImageDrag
                    name={image.name}
                    selected={true}
                    value={image}
                    index={i}
                    onChange={handleSelectedImageReorder}
                    onDelete={(index) => handleSelectedImageRemove(index)}
                    onClick={(image) => handleImageClick(image)}
                  />
                </Grid>
              ))}
              <Grid item>
                <input
                  hidden
                  id={'image-upload' + id}
                  type="file"
                  name="myImage"
                  multiple
                  accept=".png,.jpeg,.jpg"
                  onChange={(event) => {
                    handleSelect(Array.from(event.target.files || []))
                  }}
                />

                <Box
                  width={150}
                  minHeight={150}
                  height={'100%'}
                  sx={{ borderRadius: '6px' }}
                >
                  <IconButton
                    onClick={(e) =>
                      document.getElementById('image-upload' + id)?.click()
                    }
                    sx={{ height: '100%', width: '100%' }}
                    size="lg"
                  >
                    <Add sx={{ fontSize: '40px' }} color="disabled" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <ModalDialog layout="center" sx={{ width: '100%', height: '100%' }}>
          <ModalClose />
          <Box sx={{ p: 2, overflow: 'scroll', width: '100%', height: '100%' }}>
            <img
              src={
                typeof selectedImage === 'string'
                  ? selectedImage
                  : URL.createObjectURL(selectedImage as Blob)
              }
              style={{
                maxHeight: '100%',
                maxWidth: '100%',
                margin: 'auto',
                borderRadius: 3,
              }}
              loading="lazy"
              alt=""
            />
          </Box>
        </ModalDialog>
      </Modal>
    </Grid>
  )
}
