import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import React, { useEffect, useState } from 'react'
import {
  EtsyProductIntegration,
  EtsyPropertyProductIntegration,
  EtsyPropertyTemplateIntegration,
  EtsyShopReturnPolicy,
  EtsyShopShippingProfile,
  EtsyTaxonomyNode,
  EtsyTaxonomyProperty,
  EtsyTemplateIntegration,
  EtsyWhenMade,
  EtsyWhoMade,
  GetEtsyProductIntegration,
  GetEtsyTemplateIntegration,
} from '../../../../types/Etsy.types'
import {
  getEtsyIntegrations,
  getEtsyShopReturnPolicies,
  getEtsyShopShippingProfiles,
  getEtsyTaxonomy,
  getEtsyTaxonomyProperties,
  setEtsyProductIntegration,
  setEtsyTemplateIntegration,
} from '../../../../api/integrations/etsy'
import { err, isDefined, log } from '../../../../utils/functions'
import { TreeView } from '@mui/x-tree-view/TreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Box from '@mui/material/Box'
import Button from '@mui/joy/Button'
import Input from '@mui/joy/Input'
import {
  GetProduct,
  GetProductTemplate,
  GetProductTemplateAttribute,
  ProductTemplateAttributeInput,
  getProductTemplate,
  updateProductTemplateAttributes,
} from '../../../../api/product'
import AspectExamples from '../ebay/AspectExamples'
import {
  GetIntegrationResult,
  IntegrationName,
  listProduct,
  unlistProduct,
} from '../../../../api/integration'
import PropertyAttributeSelect from './PropertyAttributeSelect'
import { extractAttributeNames } from '../../../../classes/Parser'
import { DataFields, Integration } from '../../../../api/types'
import { DEFAULT_ATTRIBUTE_TYPE } from '../../../../utils/constants'
import { SingleSelect } from '../../../common/SingleSelect'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import { ProductTitleDescription } from '../../ProductTitleDescription'
import AttributeAutocomplete from './AttributeAutocomplete'
import ListSyncComponent from '../common/ListSyncComponent'
import LoadingBox from '../../../common/LoadingBox'
import { Link } from 'react-router-dom'
import { ListUnlistButtons } from '../common/ListUnlistButtons'
import MissingIntegration from '../MissingIntegration'
import FloatLabelInput from '../../../common/FloatLabelInput'
import MissingTemplateIntegration from '../MissingTemplateIntegration'

interface EtsyTemplateIntegrationComponentProps {
  integration?: GetIntegrationResult<IntegrationName.ETSY> | undefined
  template?: GetProductTemplate
  product?: GetProduct
  onUpdate?: () => void
}

export default function EtsyTemplateIntegrationComponent({
  integration: integrationValue,
  template: templateValue,
  product,
  onUpdate,
}: EtsyTemplateIntegrationComponentProps): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })
  const [loading, setLoading] = useState<boolean>(true)

  const [integration, setIntegration] = useState<Integration | undefined>(
    integrationValue?.integration,
  )

  const [update] = useState<number>(0)

  const [template, setTemplate] = useState<GetProductTemplate | undefined>(
    templateValue,
  )

  const [title, setTitle] = useState<string | undefined>(undefined)
  const [description, setDescription] = useState<string | undefined>(undefined)
  const [materials, setMaterials] = useState<string>('')

  const [list, setList] = useState<boolean>(false)
  const [sync, setSync] = useState<boolean>(false)
  const [syncQuantity, setSyncQuantity] = useState<boolean>(false)

  const [detailUpdate, setDetailUpdate] = useState<number>(0)

  const [shopShippingProfiles, setShopShippingProfiles] = useState<
    EtsyShopShippingProfile[]
  >([])

  const [selectedShippingProfileName, setSelectedShippingProfileName] =
    useState<string>('')

  const [shopReturnPolicies, setShopReturnPolicies] = useState<
    EtsyShopReturnPolicy[]
  >([])

  const [selectedReturnPolicyName, setSelectedReturnPolicyName] =
    useState<string>('')
  const [whoMade, setWhoMade] = useState<string>('')
  const [whenMade, setWhenMade] = useState<string>('')

  const [taxonomyNodes, setTaxonomyNodes] = useState<EtsyTaxonomyNode[]>([])
  const [taxonomyProperties, setTaxonomyProperties] = useState<
    EtsyTaxonomyProperty[]
  >([])
  const [filteredTaxonomyNodes, setFilteredTaxonomyNodes] = useState<
    EtsyTaxonomyNode[]
  >([])
  const [taxonomySearch, setTaxonomySearch] = useState<string>('')
  const [selectedTaxonomy, setSelectedTaxonomy] = useState<
    { id: number; name: string } | undefined
  >()

  const [templateIntegration, setTemplateIntegration] =
    useState<EtsyTemplateIntegration>()

  const [propertyTemplateIntegrations, setPropertyTemplateIntegrations] =
    useState<DataFields<EtsyPropertyTemplateIntegration>[]>([])

  const [productIntegration, setProductIntegration] =
    useState<EtsyProductIntegration>()

  const [propertyProductIntegrations, setPropertyProductIntegrations] =
    useState<DataFields<EtsyPropertyProductIntegration>[]>([])

  useEffect(() => {
    if (!shopShippingProfiles.length) return

    const shippingProfile = shopShippingProfiles.find(
      (p) =>
        `${p.shipping_profile_id}` === templateIntegration?.shippingProfileId,
    )
    if (shippingProfile) {
      console.log('Setting profile', shippingProfile.title)

      setSelectedShippingProfileName(shippingProfile.title)
    }
  }, [shopShippingProfiles, templateIntegration?.shippingProfileId])

  useEffect(() => {
    if (!shopReturnPolicies.length) return

    const returnPolicy = shopReturnPolicies.find(
      (p) => `${p.return_policy_id}` === templateIntegration?.returnPolicyId,
    )
    if (returnPolicy) {
      console.log('Setting policy', createReturnPolicyTag(returnPolicy))

      setSelectedReturnPolicyName(createReturnPolicyTag(returnPolicy))
    }
  }, [shopReturnPolicies, templateIntegration?.returnPolicyId])

  /* Get Integration and Auth */
  useEffect(() => {
    const templateId = template?.template?.id || product?.template.id
    const productId = product?.product.id
    getEtsyIntegrations(templateId, productId)
      .then((res) => {
        setLoading(false)
        if (res.success && res.data) {
          const { integration, productIntegration, templateIntegration } =
            res.data
          handleSetState(integration, templateIntegration, productIntegration)
        }
      })
      .catch((e) => log(e))

    if (!template && product?.template?.id) {
      getProductTemplate(product.template.id)
        .then((res) => {
          setLoading(false)
          if (res.success && res.data) {
            setTemplate(res.data)
          }
        })
        .catch((e) => log(e))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, template, update])

  const handleSetState = (
    integration: Integration | undefined,
    templateIntegration: GetEtsyTemplateIntegration | undefined,
    productIntegration: GetEtsyProductIntegration | undefined,
  ) => {
    setIntegration(integration)
    setTemplateIntegration(templateIntegration?.etsyTemplateIntegration)
    setPropertyTemplateIntegrations(
      templateIntegration?.etsyPropertyTemplateIntegrations || [],
    )
    setProductIntegration(productIntegration?.etsyProductIntegration)
    setPropertyProductIntegrations(
      productIntegration?.etsyPropertyProductIntegrations || [],
    )

    const etsyProductIntegration = productIntegration?.etsyProductIntegration
    const etsyTemplateIntegration = templateIntegration?.etsyTemplateIntegration
    setTitle(
      etsyProductIntegration?.title || etsyTemplateIntegration?.title || '',
    )
    setDescription(
      etsyProductIntegration?.description ||
        etsyTemplateIntegration?.description ||
        '',
    )
    setDetailUpdate(detailUpdate + 1)
    setWhenMade(
      etsyProductIntegration?.whenMade ||
        etsyTemplateIntegration?.whenMade ||
        '',
    )
    setWhoMade(
      etsyProductIntegration?.whoMade || etsyTemplateIntegration?.whoMade || '',
    )
    setMaterials(
      etsyProductIntegration?.materials ||
        etsyTemplateIntegration?.materials ||
        '',
    )

    setList(
      etsyProductIntegration?.list || etsyTemplateIntegration?.list || false,
    )

    setSync(
      etsyProductIntegration?.sync || etsyTemplateIntegration?.sync || false,
    )
    setSyncQuantity(
      etsyProductIntegration?.syncQuantity ||
        etsyTemplateIntegration?.syncQuantity ||
        false,
    )

    if (etsyTemplateIntegration?.taxonomyId) {
      setSelectedTaxonomy({
        id: etsyTemplateIntegration.taxonomyId,
        name: etsyTemplateIntegration.taxonomyName,
      })
    }
  }

  useEffect(() => {
    getEtsyTaxonomy()
      .then((res) => {
        if (res.success && res.data) {
          setTaxonomyNodes(res.data)
        }
      })
      .catch((e) => err(e))

    getEtsyShopShippingProfiles()
      .then((res) => {
        if (res.success && res.data) {
          setShopShippingProfiles(res.data)
        }
      })
      .catch((e) => err(e))

    getEtsyShopReturnPolicies()
      .then((res) => {
        if (res.success && res.data) {
          setShopReturnPolicies(res.data)
        }
      })
      .catch((e) => err(e))
  }, [])

  useEffect(() => {
    if (!selectedTaxonomy?.id) return
    getEtsyTaxonomyProperties(selectedTaxonomy.id)
      .then((res) => {
        if (res.success && res.data) {
          setTaxonomyProperties(res.data)
        }
      })
      .catch((e) => err(e))
  }, [selectedTaxonomy?.id])

  const handleTaxonomyChange = (id: number, name: string) => {
    setSelectedTaxonomy({ id, name })
  }

  const handleSave = () => {
    const shippingProfileIdNum = shopShippingProfiles.find(
      (p) => p.title === selectedShippingProfileName,
    )?.shipping_profile_id
    const shippingProfileId = shippingProfileIdNum
      ? `${shippingProfileIdNum}`
      : ''

    const returnPolicyIdNum = shopReturnPolicies.find(
      (p) => createReturnPolicyTag(p) === selectedReturnPolicyName,
    )?.return_policy_id
    const returnPolicyId = returnPolicyIdNum ? `${returnPolicyIdNum}` : ''

    if (product && templateIntegration?.id) {
      const updateProductIntegration: DataFields<EtsyProductIntegration> = {
        templateIntegrationId: templateIntegration?.id,
        productId: product.product.id,
        title: title,
        description: description,
        materials: materials,
        whenMade: whenMade,
        whoMade: whoMade,
        shippingProfileId: shippingProfileId,
        returnPolicyId: returnPolicyId,
        list,
        sync,
        syncQuantity,
      }
      setEtsyProductIntegration(
        updateProductIntegration,
        propertyProductIntegrations,
      )
        .then((res) => {
          handleSetState(
            integration,
            {
              etsyTemplateIntegration: templateIntegration,
              etsyPropertyTemplateIntegrations: (propertyTemplateIntegrations ||
                []) as EtsyPropertyTemplateIntegration[],
            },
            res.data,
          )
          handleAlert(setAlert, res, 'Set Etsy Product Integration.')
        })
        .catch((e) => {
          err(e)
        })
      return
    }

    if (!integration?.id || !template?.template?.id) return

    const newEtsyIntegration: Omit<
      DataFields<EtsyTemplateIntegration>,
      'clientId'
    > = {
      integrationId: integration.id,
      templateId: template?.template.id,
      taxonomyId: selectedTaxonomy?.id || 0,
      taxonomyName: selectedTaxonomy?.name || '',
      title: title,
      description: description,
      materials: materials,
      whenMade: whenMade,
      whoMade: whoMade,
      shippingProfileId: shippingProfileId,
      returnPolicyId: returnPolicyId,
      list,
      sync,
      syncQuantity,
    }

    // setEtsyIntegration(ebayInt)
    setEtsyTemplateIntegration(newEtsyIntegration, propertyTemplateIntegrations)
      .then((res) => {
        handleAlert(setAlert, res, 'Saved Etsy Template Integration')
        if (res.success && res.data) {
          // handleSetState({ templateIntegration: res.data })
          // log(res)
        }
      })
      .catch((e) => err(e))
  }

  useEffect(() => {
    if (!taxonomySearch) {
      setFilteredTaxonomyNodes(taxonomyNodes)
      return
    }

    const filterTaxonomy = (nodes: EtsyTaxonomyNode[]): EtsyTaxonomyNode[] =>
      nodes
        .map((node): EtsyTaxonomyNode | undefined => {
          if (node.name.toLowerCase().includes(taxonomySearch.toLowerCase())) {
            return node
          } else {
            const childrenIncluded = filterTaxonomy(node.children)
            if (childrenIncluded?.length) {
              return { ...node, children: childrenIncluded }
            }
            return undefined
          }
        })
        .filter(isDefined)

    const filteredTaxonomy = filterTaxonomy(taxonomyNodes)
    setFilteredTaxonomyNodes(filteredTaxonomy)
  }, [taxonomyNodes, taxonomySearch])

  const handlePropertyAttributeChange = (
    property: EtsyTaxonomyProperty,
    value: string | undefined,
    scaleName?: string,
  ) => {
    const newPropertyTemplateIntegrations = propertyTemplateIntegrations.slice()
    const index = newPropertyTemplateIntegrations.findIndex(
      (p) => p.propertyName === property.name,
    )

    const scaleIdNum = property.scales.find(
      (scale) => scale.display_name === scaleName,
    )?.scale_id
    const scaleId = scaleIdNum ? `${scaleIdNum}` : undefined

    if (index < 0) {
      newPropertyTemplateIntegrations.push({
        etsyTemplateIntegrationId: templateIntegration?.id || 0,
        propertyId: `${property.property_id}`,
        propertyName: property.name,
        values: value,
        scaleId: scaleId,
        required: property.is_required,
      })

      setPropertyTemplateIntegrations(newPropertyTemplateIntegrations)
      return
    }

    // update value
    const newPropertyTemplateIntegration =
      newPropertyTemplateIntegrations[index]
    if (!newPropertyTemplateIntegration) return
    if (value !== undefined) {
      newPropertyTemplateIntegration.values = value
    }
    if (scaleId) {
      newPropertyTemplateIntegration.scaleId = scaleId
    }

    newPropertyTemplateIntegrations[index] = newPropertyTemplateIntegration

    setPropertyTemplateIntegrations(newPropertyTemplateIntegrations)
  }

  const handleAddTemplateAttributeOption = async (
    selectedTemplateAttribute: GetProductTemplateAttribute | undefined,
    options: string[],
  ) => {
    if (!selectedTemplateAttribute) return
    // Get template id from template attribute
    const templateId = selectedTemplateAttribute.attribute.templateId
    const templateAttributeName = selectedTemplateAttribute.attribute.name

    const newIndex = template?.attributes.length // length+1
    const newOptionIndex = selectedTemplateAttribute.attributeOptions.length // length+1
    if (!templateId || !templateAttributeName) return

    // add attribute with options
    const addTemplateAttr: ProductTemplateAttributeInput = {
      attribute: {
        name: templateAttributeName,
        type: DEFAULT_ATTRIBUTE_TYPE,
        index: newIndex,
      },
      attributeOptions: options.map((o) => ({
        value: o,
        type: DEFAULT_ATTRIBUTE_TYPE,
        index: newOptionIndex,
      })),
    }

    const res = await updateProductTemplateAttributes(templateId, [
      addTemplateAttr,
    ])
    if (res.success && res.data) {
      console.log('success')
    }
    onUpdate?.()
  }

  const createReturnPolicyTag = (p: EtsyShopReturnPolicy): string => {
    return `${p.accepts_returns ? 'Returns, ' : 'No Returns, '}${
      p.accepts_exchanges ? 'Exchanges, ' : 'No Exchanges, '
    }${p.return_deadline} Days`
  }

  // if (product && !templateIntegration && !loading) {
  //   return (
  //     <Grid container justifyContent="center">
  //       <Grid item xs={12}></Grid>
  //     </Grid>
  //   )
  // }

  if (loading) {
    return <LoadingBox />
  }

  if (!integration) {
    return <MissingIntegration />
  }

  if (product && !templateIntegration) {
    return (
      <MissingTemplateIntegration
        templateId={product.product.templateId}
        channelName={IntegrationName.CSV}
      />
    )
  }

  if (product && !templateIntegration) {
    return (
      <Grid container spacing={1} sx={{ pt: 4 }}>
        <Grid item xs={12}>
          <Typography level="h4">Missing Template Integration</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box p={4}>
            <Typography>
              Configure Template Integration:{' '}
              <Link to={`/template/${product?.template?.id}`}>Template</Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    )
  }

  if (!integration?.shop) {
    return (
      <Grid container spacing={1} sx={{ pt: 4 }}>
        <Grid item xs={12}>
          <Typography level="h4">Missing Integration</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box p={4}>
            <Typography>
              Configure Etsy Integration:{' '}
              <Link to={`/integrations/etsy`}>Integrations</Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={4} sx={{ pt: 2 }}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={8}>
          {product ? (
            <Grid item xs={12}>
              <ListUnlistButtons
                list={list}
                hasProductIntegration={!!productIntegration}
                product={product}
                onList={() =>
                  listProduct(IntegrationName.ETSY, product.product.id)
                }
                onUnlist={() => {
                  unlistProduct(IntegrationName.ETSY, product.product.id)
                    .then((res): void => {
                      handleAlert(
                        setAlert,
                        res,
                        'Successfully unlisted product.',
                      )
                    })
                    .catch((e) => err(e))
                }}
              />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <ListSyncComponent
              list={list}
              sync={sync}
              syncQuantity={syncQuantity}
              onListChange={setList}
              onSyncChange={setSync}
              onSyncQuantityChange={setSyncQuantity}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={1}>
              <Grid item xs={12}>
                <Typography level="h4">Category</Typography>
              </Grid>
              <Grid item xs={12}>
                {selectedTaxonomy ? (
                  <>
                    <Grid item xs={12}>
                      <Grid container alignItems="center">
                        <Grid item lg={2} md={3} xs={12}>
                          <Typography>
                            <strong>Selected Category</strong>
                          </Typography>
                        </Grid>
                        <Grid item lg={8} md={7} xs={9}>
                          <Input
                            placeholder="Selected Etsy Category"
                            value={selectedTaxonomy.name}
                            disabled={true}
                            autoComplete="off"
                            fullWidth
                          />
                        </Grid>
                        <Grid item lg={1} md={1} xs={2}>
                          <Typography
                            sx={{ ml: 1 }}
                            component="span"
                            level="body-xs"
                          >
                            (id: {selectedTaxonomy.id})
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Button
                            size="sm"
                            onClick={() => setSelectedTaxonomy(undefined)}
                          >
                            Clear
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container justifyContent="center" spacing={1}>
                      <Grid item xs={12}>
                        <FloatLabelInput
                          fullWidth
                          placeholder="Search Categories"
                          value={taxonomySearch}
                          onChange={(v) => setTaxonomySearch(v)}
                          button={<Button variant="solid">Search</Button>}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            px: 12,
                            pt: 4,
                            backgroundColor: '#FBFCFE',
                            borderStyle: 'solid',
                            borderRadius: '6px',
                            borderColor:
                              'var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)))',
                            boxShadow:
                              '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08)',
                            padding: '1em',
                          }}
                        >
                          <TreeView
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            sx={{
                              height: '300px',
                              flexGrow: 1,
                              overflowY: 'auto',
                            }}
                          >
                            {filteredTaxonomyNodes.map((node) => {
                              return (
                                <DisplayTaxonomyNode
                                  key={node.id}
                                  node={node}
                                  onChange={(id, name) =>
                                    handleTaxonomyChange(id, name)
                                  }
                                />
                              )
                            })}
                          </TreeView>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* Title and Description */}
          <Grid item xs={12}>
            <ProductTitleDescription
              key={`product-title-description-${detailUpdate}`}
              template={template}
              product={product}
              title={title}
              description={description}
              required={!!product}
              onTitleChange={setTitle}
              onDescriptionChange={setDescription}
              disableUnparsedEdit={true}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={1}>
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item sm={12} md={3} lg={2}>
                    <Typography sx={{ mt: '8px' }}>
                      <strong>Materials:</strong>
                    </Typography>
                  </Grid>
                  <Grid item sm={12} md={9} lg={10}>
                    <AttributeAutocomplete
                      templateAttributes={template?.attributes}
                      label="Materials"
                      freeSolo={true}
                      multiple={true}
                      values={materials ? materials.split(', ') : []}
                      onSelect={(values) => {
                        setMaterials(values.join(', '))
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={1}>
                      <Grid item sm={12} md={3} lg={2}>
                        <Typography sx={{ mt: '10px' }}>
                          <strong>Who Made:</strong>
                        </Typography>
                      </Grid>
                      <Grid item sm={12} md={9} lg={10}>
                        <AttributeAutocomplete
                          templateAttributes={template?.attributes}
                          label="Who Made"
                          options={Object.values(EtsyWhoMade)}
                          freeSolo={true}
                          value={whoMade}
                          onChange={(value) => {
                            setWhoMade(value || '')
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={1}>
                      <Grid item sm={12} md={3} lg={2}>
                        <Typography sx={{ mt: '10px' }}>
                          <strong>When Made:</strong>
                        </Typography>
                      </Grid>
                      <Grid item sm={12} md={9} lg={10}>
                        <AttributeAutocomplete
                          templateAttributes={template?.attributes}
                          label="When Made"
                          options={Object.values(EtsyWhenMade)}
                          freeSolo={true}
                          value={whenMade}
                          onChange={(value) => {
                            setWhenMade(value || '')
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item xs={12}>
                    <Typography level="h4">Shipping Profile</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    {shopShippingProfiles ? (
                      <SingleSelect
                        key={selectedShippingProfileName}
                        label="Shipping Profile"
                        required={true}
                        options={shopShippingProfiles.map((p) => `${p.title}`)}
                        value={selectedShippingProfileName}
                        onChange={(value) =>
                          setSelectedShippingProfileName(value || '')
                        }
                      ></SingleSelect>
                    ) : (
                      <Typography level="body-xs">
                        No shipping profiles found, create Etsy Shipping
                        Profiles to use. See{' '}
                        <a
                          target="__blank"
                          href="https://www.etsy.com/your/shops/me/tools/shipping-profiles"
                        >
                          Shipping Profiles
                        </a>
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item xs={12}>
                    <Typography level="h4">Return Policy</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    {shopReturnPolicies?.length ? (
                      <SingleSelect
                        key={selectedReturnPolicyName}
                        label="Return Policy"
                        required={true}
                        options={shopReturnPolicies.map((p) =>
                          createReturnPolicyTag(p),
                        )}
                        value={selectedReturnPolicyName}
                        onChange={(value) =>
                          setSelectedReturnPolicyName(value || '')
                        }
                      ></SingleSelect>
                    ) : (
                      <Typography level="body-xs">
                        No policies found, create Etsy Return Policy to use. See{' '}
                        <a
                          target="__blank"
                          href="https://help.etsy.com/hc/en-us/articles/360000572888-Refunds-Returns-and-Exchanges-for-Sellers?segment=selling"
                        >
                          Return Policies
                        </a>
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {taxonomyProperties?.length ? (
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                  <Typography level="h4">Attributes</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={1}>
                    {taxonomyProperties.map((property) => {
                      const properyIntegration =
                        propertyTemplateIntegrations.find(
                          (p) => p.propertyName === property.name,
                        )

                      let templateAttribute:
                        | GetProductTemplateAttribute
                        | undefined
                      const attributeNames = extractAttributeNames(
                        properyIntegration?.values || '',
                      )
                      if (attributeNames?.length) {
                        templateAttribute = template?.attributes.find(
                          (attribute) =>
                            attribute.attribute.name === attributeNames[0],
                        )
                      }

                      return (
                        <Grid item xs={12} key={property.property_id}>
                          <Grid container justifyContent="center" spacing={1}>
                            <Grid item xs={12} md={3}>
                              <Typography sx={{ mt: '8px' }}>
                                <strong>
                                  {property.name}
                                  {property.is_required ? '*' : ''}:{' '}
                                </strong>
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={
                                12 -
                                (property.possible_values.length ? 3 : 0) -
                                (property.scales.length ? 3 : 0)
                              }
                              md={
                                9 -
                                (property.possible_values.length ? 2 : 0) -
                                (property.scales.length ? 2 : 0)
                              }
                            >
                              <PropertyAttributeSelect
                                required={property.is_required}
                                property={property}
                                disabled={!!product}
                                productAttributes={product?.attributes || []}
                                templateAttributes={template?.attributes || []}
                                onChange={(value) => {
                                  handlePropertyAttributeChange(property, value)
                                }}
                                properyIntegration={properyIntegration}
                              />
                            </Grid>
                            {property.scales.length ? (
                              <Grid item xs={3} md={2}>
                                <SingleSelect
                                  placeholder="Scales"
                                  disabled={!!product}
                                  value={
                                    property.scales.find(
                                      (scale) =>
                                        `${scale.scale_id}` ===
                                        properyIntegration?.scaleId,
                                    )?.display_name || ''
                                  }
                                  options={property.scales.map(
                                    (v) => v.display_name,
                                  )}
                                  onChange={(scaleName) =>
                                    handlePropertyAttributeChange(
                                      property,
                                      undefined,
                                      scaleName,
                                    )
                                  }
                                />
                              </Grid>
                            ) : null}
                            {property.possible_values.length ? (
                              <Grid item xs={3} md={2}>
                                <AspectExamples
                                  label="Possible Values"
                                  options={property.possible_values.map(
                                    (v) => v.name,
                                  )}
                                  onChange={(options) =>
                                    handleAddTemplateAttributeOption(
                                      templateAttribute,
                                      options,
                                    )
                                  }
                                  templateAttribute={templateAttribute}
                                />
                              </Grid>
                            ) : null}
                          </Grid>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button variant="solid" onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      />
    </Grid>
  )
}

function DisplayTaxonomyNode({
  node,
  onChange,
}: {
  node: EtsyTaxonomyNode
  onChange: (taxonomyId: number, taxonomyName: string) => void
}): JSX.Element {
  return <TreeNode node={node} key={node.id} onChange={onChange} />
}

function TreeNode({
  node,
  onChange,
}: {
  node: EtsyTaxonomyNode
  onChange: (taxonomyId: number, taxonomyName: string) => void
}): JSX.Element {
  return (
    <TreeItem
      key={node.id}
      nodeId={`${node.id}`}
      label={node.name}
      onClick={() => !node.children.length && onChange(node.id, node.name)}
    >
      {node.children?.map((node) => (
        <TreeNode node={node} key={node.id} onChange={onChange} />
      ))}
    </TreeItem>
  )
}
