import { GetProduct, GetProductAttribute } from '../api/product'
import { Product, ProductTemplateAttribute } from '../api/types'
import { PRODUCT_DISPLAY_NAMES, PRODUCT_FIELD_NAMES } from '../types'
import {
  ATTRIBUTE_TAG_END,
  ATTRIBUTE_TAG_START,
  DEFAULT_ATTRIBUTE_TYPE,
} from '../utils/constants'
import { isDefined } from '../utils/functions'

export type AttributeValue = {
  name: string
  type: ProductTemplateAttribute['type']
  value: string | undefined
}
export function replaceAttributes(str: string, getProduct: GetProduct): string {
  const productAttributeValues = getProductAttributeValues(getProduct)

  let parsingStr = str
  // loop through
  for (const { name, value } of productAttributeValues) {
    const pattern = `${ATTRIBUTE_TAG_START}\\s*?${name}\\s*?${ATTRIBUTE_TAG_END}`
    const reg = new RegExp(pattern, 'gim')
    parsingStr = parsingStr.replaceAll(reg, value || '')
  }

  // replace unmatched
  const pattern = `${ATTRIBUTE_TAG_START}\\s*?.*?\\s*?${ATTRIBUTE_TAG_END}`
  const reg = new RegExp(pattern, 'gim')
  parsingStr = parsingStr.replaceAll(reg, '')

  return replaceValues(parsingStr, getProduct)
}

export function replaceDefinedAttributes(
  str: string,
  getProduct: GetProduct,
): string {
  const productAttributeValues = getProductAttributeValues(getProduct)

  let parsingStr = str
  for (const { name, value } of productAttributeValues) {
    if (value === undefined || value === '') continue

    const pattern = `${ATTRIBUTE_TAG_START}\\s*?${name}\\s*?${ATTRIBUTE_TAG_END}`
    const reg = new RegExp(pattern, 'gim')
    parsingStr = parsingStr.replaceAll(reg, value)
  }

  return replaceValues(parsingStr, getProduct)
}

export function replaceValues(str: string, getProduct: GetProduct): string {
  const fields = Object.values(PRODUCT_FIELD_NAMES)
  const fieldValues = fields.map(
    (field): AttributeValue => ({
      name: field,
      type: 'text',
      value:
        getProduct.product[field] === undefined ||
        getProduct.product[field] === ''
          ? undefined
          : `${getProduct.product[field]}`,
    }),
  )

  let parsingStr = str
  // loop through
  for (const { name, value } of fieldValues) {
    if (value === undefined) continue
    const pattern = `${ATTRIBUTE_TAG_START}\\s*?${name}\\s*?${ATTRIBUTE_TAG_END}`
    const reg = new RegExp(pattern, 'gim')
    parsingStr = parsingStr.replaceAll(reg, value || '')
  }

  return parsingStr
}

export function extractAttributeNames(input: string): string[] {
  const matches = input.matchAll(/\{\{\s*(.*?)\s*\}\}/gim)
  if (!matches) return []
  return [...matches].map((match) => match[1]).filter(isDefined)
}

export function extractAttributeName(input: string | undefined): string {
  if (!input) return ''
  const matches = input.matchAll(/\{\{\s*(.*?)\s*\}\}/gim)
  if (!matches) return ''
  return [...matches].map((match) => match[1]).filter(isDefined)[0] || ''
}

export function parseDescription(
  description: string,
  getProduct: GetProduct,
): string {
  // parse paragraphs

  const paragraphs = description.split(/\n{2,}/gim)

  const parsedParagraphs = paragraphs.map((paragraph) => {
    // split paragraph into lines
    const lines = paragraph.split(/\n/gim)

    const parseLines = lines
      .map((line) => {
        const parsedLine = replaceDefinedAttributes(line, getProduct)

        if (
          parsedLine.includes(ATTRIBUTE_TAG_START) ||
          parsedLine.includes(ATTRIBUTE_TAG_END)
        ) {
          return undefined
        }
        return parsedLine
      })
      .filter(isDefined)

    return parseLines.join('\n')
  })

  // parse
  const parsedDescription = parsedParagraphs.join('\n\n')
  return parsedDescription.trim().replaceAll(/\n{2,}/gim, '\n\n') // replace multiple new lines
}

export function parseTitle(title: string, getProduct: GetProduct): string {
  const parsedTitle = replaceAttributes(title, getProduct)
  return parsedTitle.trim().replaceAll(/\s{2,}/gim, ' ') // replace multiple spaces
}

export function getProductValueAttributesValues(
  product: Product,
): AttributeValue[] {
  const attributeValues: AttributeValue[] = []
  Object.entries(product).forEach(
    ([name, value]): AttributeValue | undefined => {
      const displayName = PRODUCT_DISPLAY_NAMES[name]
      if (!displayName) return undefined
      attributeValues.push({
        name: displayName,
        type: DEFAULT_ATTRIBUTE_TYPE,
        value: `${value}`,
      })
    },
  )
  return attributeValues
}

export function getProductAttributeAttributeValues(
  productAttributes: GetProductAttribute[],
): AttributeValue[] {
  return productAttributes.map(
    (attribute): AttributeValue => ({
      name: attribute.templateAttribute.name,
      type: attribute.templateAttribute.type,
      value: attribute?.attribute?.value,
    }),
  )
}

export function getProductAttributeValues(
  getProduct: GetProduct,
): AttributeValue[] {
  const attributeValues = getProductAttributeAttributeValues(
    getProduct.attributes,
  )
  const productValues = getProductValueAttributesValues(getProduct.product)
  return attributeValues.concat(productValues)
}
