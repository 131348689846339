import React, { useState } from 'react'
import Button from '@mui/joy/Button'
import Grid from '@mui/material/Grid'
import Component from '../common/Component'
import { addUserRole } from '../../api/admin'
import Alert, { AlertInput, handleAlert } from '../common/Alert'
import LabelInput from '../common/LabelInput'
import { SingleSelect } from '../common/SingleSelect'
import { ROLE_ID_MAP } from '../../types'
import Typography from '@mui/joy/Typography'

interface AddUserRoleProps {
  clientId: number
}

export default function AddUserRole({
  clientId,
}: AddUserRoleProps): JSX.Element {
  const [userEmail, setUserEmail] = useState('')
  const [userRole, setUserRole] = useState<string>('Manager')
  const [userRoleId, setUserRoleId] = useState<number | undefined>(
    ROLE_ID_MAP['Manager'] || 3,
  )
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleSelect = (roleName?: string) => {
    if (!roleName) return
    setUserRole(roleName)
    const roleNumber = ROLE_ID_MAP[roleName]
    if (!roleNumber) return
    setUserRoleId(roleNumber)
  }

  const handleSubmit = async (): Promise<void> => {
    if (!userEmail || !userRoleId) return
    const res = await addUserRole(clientId, userEmail, userRoleId)
    handleAlert(
      setAlert,
      res,
      res.message || 'User invited to join your client.',
    )
  }
  return (
    <>
      <Component title="Invite User to Organization">
        <Typography level="body-sm">
          Invite a new user or edit an existing user
        </Typography>
        <Grid container spacing={2} justifyContent="center" direction="column">
          <Grid item xs={12}></Grid>
          <Grid item>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={6}>
                <LabelInput
                  label="User Email"
                  fullWidth
                  required
                  value={userEmail}
                  onChange={(v) => setUserEmail(v)}
                />
              </Grid>
              <Grid item xs={6}>
                <SingleSelect
                  label="Role"
                  options={['Admin', 'Manager', 'Employee', 'Guest']}
                  value={userRole}
                  onChange={(v) => handleSelect(v)}
                  defaultOption={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button
                  type="submit"
                  variant="solid"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Component>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      />
    </>
  )
}
