import instance from '..'
import {
  InventoryLocation,
  LocationAddress,
  ListingPolicies,
  EbayAspectTemplateIntegration,
  EbayTemplateIntegration,
  GetEbayTemplateIntegration,
  EbayTemplateListingConfiguration,
  EbayProductListingConfiguration,
  EbayProductIntegration,
  PriceSuggestion,
  EbayAttributeAspectValues,
  EbayOffer,
  InventoryItem,
  GetEbayIntegrationsResult,
} from '../../types/Ebay.types'
import { getResult } from '../../utils/functions'
import { APIResult } from '../../utils/types'
import { GetProductTemplate } from '../product'
import { DataFields } from '../types'

export async function createNotifications(): Promise<APIResult<void>> {
  const result = await instance
    .post('/api/ebay/createNotifications')
    .then((res) => getResult<void>(res))
  return result
}

export async function validateUser(): Promise<APIResult<void>> {
  const result = await instance
    .post('/api/ebay/validateUser')
    .then((res) => getResult<void>(res))
  return result
}

export async function getEbayIntegrations(
  integrationId: number,
  templateId?: number,
  productId?: number,
): Promise<APIResult<GetEbayIntegrationsResult>> {
  const ebayTemplateIntegration = await instance
    .get('/api/integration/ebay/integrations', {
      params: {
        integrationId,
        templateId,
        productId,
      },
    })
    .then((res) => getResult<GetEbayIntegrationsResult>(res))
  return ebayTemplateIntegration
}

export async function getEbayTemplateIntegration(
  integrationId: number,
  templateId: number,
): Promise<APIResult<GetEbayTemplateIntegration>> {
  const ebayTemplateIntegration = await instance
    .get('/api/integration/ebay/templateIntegration', {
      params: {
        integrationId,
        templateId,
      },
    })
    .then((res) => getResult<GetEbayTemplateIntegration>(res))
  return ebayTemplateIntegration
}

export async function setEbayTemplateIntegration(
  ebayTemplateIntegration: EbayTemplateIntegration,
  ebayAspectTemplateIntegration: EbayAspectTemplateIntegration[],
): Promise<APIResult<GetEbayTemplateIntegration>> {
  const setEbayTemplateIntegration = await instance
    .post('/api/integration/ebay/templateIntegration', {
      ebayTemplateIntegration,
      ebayAspectTemplateIntegration,
    })
    .then((res) => getResult<GetEbayTemplateIntegration>(res))
  return setEbayTemplateIntegration
}

export async function getEbayProductIntegration(
  integrationId: number,
  productId: number,
): Promise<APIResult<EbayProductIntegration>> {
  const ebayProductIntegration = await instance
    .get('/api/integration/ebay/productIntegration', {
      params: {
        integrationId,
        productId,
      },
    })
    .then((res) => getResult<EbayProductIntegration>(res))
  return ebayProductIntegration
}

export async function setEbayProductIntegration(
  ebayProductIntegration: DataFields<EbayProductIntegration>,
): Promise<APIResult<string>> {
  const setEbayProductIntegration = await instance
    .post('/api/integration/ebay/productIntegration', {
      ebayProductIntegration,
    })
    .then((res) => getResult<string>(res))
  return setEbayProductIntegration
}

export async function deleteOffer(offerId: string): Promise<APIResult<string>> {
  const response = await instance
    .post('/api/integration/ebay/deleteOffer', {
      offerId,
    })
    .then((res) => getResult<string>(res))
  return response
}

export async function getInventoryLocation(): Promise<
  APIResult<InventoryLocation>
> {
  const inventoryLocation = await instance
    .get('/api/integration/ebay/inventoryLocation')
    .then((res) => getResult<InventoryLocation>(res))
  return inventoryLocation
}

export async function setInventoryLocation(
  address: LocationAddress,
): Promise<APIResult<InventoryLocation>> {
  const inventoryLocation = await instance
    .post('/api/integration/ebay/inventoryLocation', { address })
    .then((res) => getResult<InventoryLocation>(res))
  return inventoryLocation
}

export async function getNotificationPreferences(): Promise<APIResult<string>> {
  const res = await instance
    .get('/api/integration/ebay/notificationPreferences')
    .then((res) => getResult<string>(res))
  return res
}

export async function setNotificationPreferences(): Promise<APIResult<string>> {
  const res = await instance
    .post('/api/integration/ebay/notificationPreferences')
    .then((res) => getResult<string>(res))
  return res
}

export async function getListingPolicies(): Promise<
  APIResult<ListingPolicies>
> {
  const listingPolicies = await instance
    .get('/api/integration/ebay/listingPolicies')
    .then((res) => getResult<ListingPolicies>(res))
  return listingPolicies
}

export async function setListingPolicies(): Promise<
  APIResult<{ [k: string]: boolean }>
> {
  const listingPolicies = await instance
    .post('/api/integration/ebay/listingPolicies')
    .then((res) => getResult<{ [k: string]: boolean }>(res))
  return listingPolicies
}

export interface RequiredIntegrationTemplateAttributes {
  aspect?: EbayAspectTemplateIntegration
  templateAttributeNames: string[]
}
export async function getIntegrationRequiredAttributes(
  templateId: number,
): Promise<APIResult<RequiredIntegrationTemplateAttributes[]>> {
  const result = await instance
    .post('/api/integration/ebay/getIntegrationRequiredAttributes', {
      templateId,
    })
    .then((res) => getResult<RequiredIntegrationTemplateAttributes[]>(res))
  return result
}

export async function setEbayTemplateListingConfiguration(
  ebayTemplateListingConfiguration: EbayTemplateListingConfiguration,
): Promise<APIResult<EbayTemplateListingConfiguration>> {
  const result = await instance
    .post('/api/integration/ebay/templateListingConfiguration', {
      ebayTemplateListingConfiguration,
    })
    .then((res) => getResult<EbayTemplateListingConfiguration>(res))
  return result
}

export async function getEbayTemplateListingConfiguration(
  ebayTemplateIntegrationId: number,
): Promise<APIResult<EbayTemplateListingConfiguration | undefined>> {
  const result = await instance
    .get('/api/integration/ebay/templateListingConfiguration', {
      params: {
        ebayTemplateIntegrationId,
      },
    })
    .then((res) => getResult<EbayTemplateListingConfiguration | undefined>(res))
  return result
}

export async function setEbayProductListingConfiguration(
  ebayProductListingConfiguration: EbayProductListingConfiguration,
): Promise<APIResult<EbayProductListingConfiguration>> {
  const result = await instance
    .post('/api/integration/ebay/productListingConfiguration', {
      ebayProductListingConfiguration,
    })
    .then((res) => getResult<EbayProductListingConfiguration>(res))
  return result
}

export async function getEbayProductListingConfiguration(
  ebayProductIntegrationId: number,
): Promise<APIResult<EbayProductListingConfiguration | undefined>> {
  const result = await instance
    .get('/api/integration/ebay/productListingConfiguration', {
      params: {
        ebayProductIntegrationId,
      },
    })
    .then((res) => getResult<EbayProductListingConfiguration | undefined>(res))
  return result
}

export async function ebayBulkImport(params?: {
  skus?: string[]
  template?: string
  updateExistingProducts?: boolean
}): Promise<APIResult<void>> {
  const result = await instance
    .post('/api/integration/ebay/bulkImport', params)
    .then((res) => getResult<void>(res))
  return result
}

export async function ebayCreateTemplate(
  categoryId: number,
  template: string,
): Promise<APIResult<GetProductTemplate>> {
  const result = await instance
    .post('/api/integration/ebay/createTemplate', {
      categoryId,
      template,
    })
    .then((res) => getResult<GetProductTemplate>(res))
  return result
}

export async function getPriceSuggestion(
  productId: number | undefined,
  title?: string | undefined,
  aspects?: string[],
): Promise<APIResult<PriceSuggestion>> {
  const result = await instance
    .get('/api/integration/ebay/priceSuggestion', {
      params: {
        productId,
        title,
        aspects,
      },
    })
    .then((res) => getResult<PriceSuggestion>(res))
  return result
}

export async function setEbayPriceSuggestionConfiguration(
  templateId: number,
  title?: string,
  aspects?: string[],
): Promise<APIResult<void>> {
  const result = await instance
    .post('/api/integration/ebay/priceSuggestionConfiguration', {
      templateId,
      title,
      aspects,
    })
    .then((res) => getResult<void>(res))
  return result
}

export async function getEbayAttributeAspectValues(
  templateId: number,
): Promise<APIResult<EbayAttributeAspectValues>> {
  const result = await instance
    .get('/api/integration/ebay/attributeAspectValues', {
      params: {
        templateId,
      },
    })
    .then((res) => getResult<EbayAttributeAspectValues>(res))
  return result
}

export interface GetInventoryItemOffers {
  inventoryItemOffers: { inventoryItem: InventoryItem; offer?: EbayOffer }[]
  count?: number
}
export interface GetInventoryItemOffer {
  inventoryItem: InventoryItem
  offer?: EbayOffer
}
export async function getEbayInventoryItemOffers(query?: {
  offset?: number
  limit?: number
}): Promise<APIResult<GetInventoryItemOffers>> {
  const result = await instance
    .get('/api/integration/ebay/inventoryItemOffers', {
      params: {
        offset: query?.offset,
        limit: query?.limit,
      },
    })
    .then((res) => getResult<GetInventoryItemOffers>(res))
  return result
}

export async function getEbayOffer(sku: string): Promise<APIResult<EbayOffer>> {
  const result = await instance
    .get('/api/integration/ebay/offer', {
      params: {
        sku,
      },
    })
    .then((res) => getResult<EbayOffer>(res))
  return result
}
