import React, { useState } from 'react'
import Autocomplete from '@mui/joy/Autocomplete'
import AutocompleteOption from '@mui/joy/AutocompleteOption'
import Grid from '@mui/material/Grid'
import { SxProps, Theme } from '@mui/material'
import FloatLabelInput from './FloatLabelInput'
import Typography from '@mui/joy/Typography'
import AttributeInline from './AttributeInline'
import Chip from '@mui/joy/Chip'
import { getAttributeName, getAttributeTag } from '../../utils/functions'
import Close from '@mui/icons-material/Close'
import { SvgIcon } from '@mui/joy'

type AttributeSelectProps = {
  label?: string
  name?: string
  nameEditable?: boolean
  nameEditDisabled?: boolean
  value?: string
  values?: string[]
  attributeNames?: string[]
  options?: string[]
  multiple?: boolean
  required?: boolean
  freeSolo?: boolean
  endDecorator?: React.ReactNode
  sx?: SxProps<Theme> | undefined
  xs?: [number, number]
  sm?: [number, number]
  md?: [number, number]
  lg?: [number, number]
  onBlur?: () => void
  onChange?: (value: string | undefined) => void
  onSelect?: (values: string[] | string | undefined) => void
  onUpdate?: () => void
  onNameChange?: (value: string) => void
  onNameBlur?: (e?: React.FocusEvent<HTMLInputElement, Element>) => void
  onClick?: () => void
  onFocus?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  nameLabel?: string | JSX.Element
  placeholder?: string
  namePlaceholder?: string
  type?: React.HTMLInputTypeAttribute
  size?: 'sm' | 'md' | 'lg'
  fullWidth?: boolean
  disabled?: boolean
  readOnly?: boolean
  errorText?: string
  helperText?: string | React.ReactNode
  autoComplete?: string
  autoCapitalize?: boolean
  autoCorrect?: boolean
  format?: 'USD'

  addValues?: boolean
  defaultOption?: boolean
  warningText?: string
  onAddValue?: (value: string) => void
}

export default function AttributeSelect({
  label,
  name,
  nameEditable,
  nameEditDisabled,
  value: defaultValue,
  values,
  attributeNames,
  options,
  multiple,
  required,
  freeSolo,
  endDecorator,
  sx,
  xs,
  sm,
  md,
  lg,
  onBlur,
  onChange,
  onSelect,
  onNameChange,
  onClick,
  onNameBlur,
  onFocus,
  onKeyDown,
  nameLabel,
  placeholder,
  namePlaceholder,
  type,
  size,
  fullWidth,
  disabled,
  readOnly,
  errorText,
  helperText,
  autoComplete,
  autoCapitalize,
  autoCorrect,
  format,
  addValues,
  defaultOption,
  warningText,
  onAddValue,
}: AttributeSelectProps): JSX.Element {
  const [inputValue, setInputValue] = useState<string>('')
  const allOptions = (options || [])?.concat(
    attributeNames?.map((a) => `@${a}`) || [],
  )

  let value = defaultValue
  const attributeValue = getAttributeName(value)
  if (value?.startsWith('{{') && attributeValue) {
    value = `@${attributeValue}`
  }

  const attributeValues = values?.map((value) => {
    const attributeValue = getAttributeName(value)
    if (value?.startsWith('{{') && attributeValue) {
      return `@${attributeValue}`
    }
    return value
  })

  const renderOption = (
    props: Omit<React.HTMLAttributes<HTMLLIElement>, 'color'>,
    option: string,
  ) => (
    <AutocompleteOption {...props}>
      <AttributeInline size="s">{option}</AttributeInline>
    </AutocompleteOption>
  )

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid
        item
        xs={xs?.[0] || 12}
        sm={sm?.[0] || 12}
        md={md?.[0] || 3}
        lg={lg?.[0] || 2}
      >
        {nameEditable ? (
          <FloatLabelInput
            value={name}
            onChange={onNameChange}
            onBlur={onNameBlur}
            label={nameLabel}
            placeholder={namePlaceholder}
            type={type}
            size={size}
            fullWidth={fullWidth}
            required={required}
            disabled={disabled || nameEditDisabled}
            readOnly={readOnly}
            errorText={errorText}
            helperText={helperText}
            autoComplete={autoComplete}
            autoCapitalize={autoCapitalize}
            autoCorrect={autoCorrect}
            format={format}
          />
        ) : (
          <Typography level="title-md" sx={{ pt: '0.5em' }}>
            <strong>{name}</strong>
          </Typography>
        )}
      </Grid>

      <Grid
        item
        xs={xs?.[1] || 12}
        sm={sm?.[1] || 12}
        md={md?.[1] || 9}
        lg={lg?.[1] || 10}
      >
        {multiple ? (
          <Autocomplete
            placeholder={!value ? label : ''}
            clearOnEscape
            options={allOptions}
            multiple={multiple}
            value={attributeValues}
            freeSolo={true}
            onBlur={onBlur}
            inputValue={inputValue}
            onInputChange={(e, inputChange) => {
              if (value && !inputValue) {
                setInputValue(value + inputChange)
                onChange?.('')
                return
              }
              setInputValue(inputChange)
            }}
            onChange={(e, newValues) => {
              const updateValues = newValues.map((value): string => {
                if (value?.startsWith('@')) {
                  return getAttributeTag(value.replace('@', ''))
                }
                return value
              })
              onSelect?.(updateValues)
            }}
            getOptionLabel={(option) => option}
            renderOption={renderOption}
            renderTags={(tags, getTagProps) =>
              tags.map((item, index) => {
                if (
                  item.startsWith('@') &&
                  attributeNames?.includes(item.slice(1))
                ) {
                  return (
                    <Chip
                      variant="outlined"
                      endDecorator={
                        <SvgIcon fontSize="md">
                          <Close />
                        </SvgIcon>
                      }
                      {...getTagProps({ index })}
                      key={item}
                      sx={{ borderRadius: '6px', minWidth: 0 }}
                    >
                      <strong>{item}</strong>
                    </Chip>
                  )
                }

                return (
                  <Chip
                    variant="outlined"
                    {...getTagProps({ index })}
                    endDecorator={
                      <SvgIcon fontSize="md">
                        <Close />
                      </SvgIcon>
                    }
                    key={item}
                  >
                    {item}
                  </Chip>
                )
              })
            }
          ></Autocomplete>
        ) : (
          <Autocomplete
            placeholder={!value ? label : ''}
            clearOnEscape
            freeSolo={true}
            options={allOptions}
            multiple={true}
            value={value ? [value] : []}
            onBlur={(e) => {
              if (!value && inputValue) {
                setInputValue('')
                onChange?.(inputValue)
              }
              onBlur?.()
            }}
            onKeyDown={(e) => {
              if (e.key === 'Backspace') {
                if (value && !inputValue) {
                  setInputValue(value)
                  return
                }
              }
            }}
            inputValue={inputValue}
            onInputChange={(e, inputChange) => {
              if (value && !inputValue) {
                setInputValue(value + inputChange)
                onChange?.('')
                return
              }
              setInputValue(inputChange)
            }}
            onChange={(e, newValue) => {
              const v = newValue?.length ? newValue.pop() : undefined
              if (v?.startsWith('@')) {
                onChange?.(getAttributeTag(v.replace('@', '')))
                return
              }
              onChange?.(v)
            }}
            getOptionLabel={(option) => option}
            renderOption={renderOption}
            renderTags={(tags, getTagProps) =>
              tags.map((item, index) => {
                if (
                  item.startsWith('@') &&
                  attributeNames?.includes(item.slice(1))
                ) {
                  return (
                    <Chip
                      variant="outlined"
                      {...getTagProps({ index })}
                      key={item}
                      sx={{ borderRadius: '6px' }}
                      slotProps={{
                        root: {
                          style: {
                            height: '28px',
                          },
                        },
                      }}
                    >
                      <Typography>
                        <strong>{item}</strong>
                      </Typography>
                    </Chip>
                  )
                }

                return (
                  <Typography
                    ml={'7.5px'}
                    mr={'-11px'}
                    sx={{ letterSpacing: 'normal' }}
                    key={item}
                  >
                    {item}
                  </Typography>
                )
              })
            }
          ></Autocomplete>
        )}
      </Grid>
    </Grid>
  )
}
