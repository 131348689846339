import instance from '.'
import {
  BigCommerceProductIntegration,
  BigCommerceTemplateIntegration,
} from '../types/BigCommerce.types'
import {
  CloverProductIntegration,
  CloverTemplateIntegration,
} from '../types/Clover.types'
import {
  EbayProductIntegration,
  EbayTemplateIntegration,
  OfferResponse,
} from '../types/Ebay.types'
import {
  ShopifyProductIntegration,
  ShopifyTemplateIntegration,
} from '../types/Shopify.types'
import {
  SquareTemplateIntegration,
  SquareProductIntegration,
} from '../types/Square.types'
import { getResult } from '../utils/functions'
import { APIResult } from '../utils/types'
import {
  DataFields,
  Integration,
  LoginAuthentication,
  OAuthAuthentication,
} from './types'

// Enum of current Integration names
export enum IntegrationName {
  EBAY = 'ebay',
  ETSY = 'etsy',
  SHOPIFY = 'shopify',
  BIG_COMMERCE = 'bigcommerce',
  SQUARE = 'square',
  CLOVER = 'clover',
  CSV = 'csv',
}

export type IntegrationNames =
  | 'ebay'
  | 'etsy'
  | 'shopify'
  | 'bigcommerce'
  | 'square'

export const IntegrationDisplayName: Record<
  IntegrationName,
  string | undefined
> = {
  ebay: 'Ebay',
  etsy: 'Etsy',
  shopify: 'Shopify',
  bigcommerce: 'Big Commerce',
  square: 'Square',
  clover: 'Clover',
  csv: 'CSV',
}

export const IntegrationDisplayNameToName: Record<
  string,
  IntegrationName | undefined
> = {
  Ebay: IntegrationName.EBAY,
  Etsy: IntegrationName.ETSY,
  Shopify: IntegrationName.SHOPIFY,
  'Big Commerce': IntegrationName.BIG_COMMERCE,
  Square: IntegrationName.SQUARE,
  Clover: IntegrationName.CLOVER,
  CSV: IntegrationName.CSV,
}

export type TemplateIntegrationType<T> = T extends IntegrationName.EBAY
  ? EbayTemplateIntegration
  : T extends IntegrationName.BIG_COMMERCE
  ? BigCommerceTemplateIntegration
  : T extends IntegrationName.SHOPIFY
  ? ShopifyTemplateIntegration
  : T extends IntegrationName.SQUARE
  ? SquareTemplateIntegration
  : T extends IntegrationName.CLOVER
  ? CloverTemplateIntegration
  : never

export type ProductIntegrationType<T> = T extends IntegrationName.EBAY
  ? EbayProductIntegration
  : T extends IntegrationName.BIG_COMMERCE
  ? BigCommerceProductIntegration
  : T extends IntegrationName.SHOPIFY
  ? ShopifyProductIntegration
  : T extends IntegrationName.SQUARE
  ? SquareProductIntegration
  : T extends IntegrationName.CLOVER
  ? CloverProductIntegration
  : never

export async function getAvailableIntegrations(): Promise<APIResult<string[]>> {
  const availableIntegrations = await instance
    .post('/api/integration/getAvailableIntegrations')
    .then((res) => getResult<string[]>(res))
  return availableIntegrations
}

export async function getIntegrations(params?: {
  templateId?: number
  productId?: number
}): Promise<APIResult<GetIntegrationsResult>> {
  const integrations = await instance
    .get('/api/integration/getIntegrations', {
      params: {
        templateId: params?.templateId,
        productId: params?.productId,
      },
    })
    .then((res) => getResult<GetIntegrationsResult>(res))
  return integrations
}

// type SetProductIntegration = {
//   templateIntegrationId: number
//   productId: number
//   externalId?: string
//   sku?: string
//   status?: string
//   list?: boolean
//   sync?: boolean
//   syncQuantity?: boolean
// }
// export type SetProductIntegrations = {
//   ebay?: SetProductIntegration
//   etsy?: SetProductIntegration
//   bigcommerce?: SetProductIntegration
//   shopify?: SetProductIntegration
//   square?: SetProductIntegration
// }

export async function setProductIntegrations({
  integrations,
  productId,
}: {
  integrations?: GetIntegrationsResult
  productId?: number
}): Promise<APIResult<void>> {
  return await instance
    .post('/api/integration/setProductIntegrations', {
      integrations,
      productId,
    })
    .then((res) => getResult<void>(res))
}

export async function deleteIntegration<T>(
  channel: string,
): Promise<APIResult<GetIntegrationResult<T>>> {
  const integrations = await instance
    .post(`/api/integration/${channel}/delete`)
    .then((res) => getResult<GetIntegrationResult<T>>(res))
  return integrations
}

export async function removeProductTemplateIntegration(
  templateId: number,
  integrationId: number,
  listed?: boolean | undefined,
): Promise<APIResult<void>> {
  const integrations = await instance
    .post('/api/integration/removeProductTemplateIntegration', {
      templateId,
      integrationId,
      listed,
    })
    .then((res) => getResult<void>(res))
  return integrations
}

export async function removeProductIntegration(
  integrationId: number,
): Promise<APIResult<void>> {
  const integrations = await instance
    .post('/api/integration/removeProductIntegration', {
      integrationId,
    })
    .then((res) => getResult<void>(res))
  return integrations
}

export type Authentication = OAuthAuthentication | LoginAuthentication
export type GetIntegrationResult<T> = {
  integration?: Integration
  productIntegration?: DataFields<ProductIntegrationType<T>> | undefined
  templateIntegration?: DataFields<TemplateIntegrationType<T>>
  authenticationType?: string
  authenticated: boolean
  oAuthUrl?: string
}
export type GetIntegrationsResult = {
  ebay?: GetIntegrationResult<IntegrationName.EBAY>
  etsy?: GetIntegrationResult<IntegrationName.ETSY>
  shopify?: GetIntegrationResult<IntegrationName.SHOPIFY>
  bigcommerce?: GetIntegrationResult<IntegrationName.BIG_COMMERCE>
  square?: GetIntegrationResult<IntegrationName.SQUARE>
  clover?: GetIntegrationResult<IntegrationName.CLOVER>
  csv?: GetIntegrationResult<IntegrationName.CSV>
}
export async function getIntegration<T>(
  name: IntegrationName,
): Promise<APIResult<GetIntegrationResult<T>>> {
  const integration = await instance
    .post('/api/integration/getIntegration', {
      name,
    })
    .then((res) => getResult<GetIntegrationResult<T>>(res))
  return integration
}

export async function updateIntegration(
  name: string,
  description?: string,
): Promise<APIResult<Integration>> {
  const integration = await instance
    .post('/api/integration/updateIntegration', {
      name,
      description,
    })
    .then((res) => getResult<Integration>(res))
  return integration
}

export async function createIntegration(
  name: string,
): Promise<APIResult<Integration>> {
  const integration = await instance
    .post(`/api/integration/${name}/integration`, {
      name,
    })
    .then((res) => getResult<Integration>(res))
  return integration
}

export async function authenticateOAuth(
  name: string,
  params: Record<string, unknown>,
): Promise<APIResult<void>> {
  return await instance
    .post(`/api/integration/${name}/oauth`, params)
    .then((res) => getResult<void>(res))
}

export async function getOAuthUrl(
  name: string,
  params?: { [k: string]: unknown },
): Promise<APIResult<string>> {
  return await instance
    .get(`/api/integration/${name}/oauthUrl`, {
      params,
    })
    .then((res) => getResult<string>(res))
}

export async function getTemplateIntegration<T>(
  name: string,
  integrationId: number,
  templateId: number,
): Promise<APIResult<T>> {
  return await instance
    .get(`/api/integration/${name}/templateIntegration`, {
      params: {
        integrationId,
        templateId,
      },
    })
    .then((res) => getResult<T>(res))
}

export async function getProductIntegration<T>(
  name: string,
  integrationId: number,
  productId: number,
): Promise<APIResult<T>> {
  return await instance
    .get(`/api/integration/${name}/templateIntegration`, {
      params: {
        integrationId,
        productId,
      },
    })
    .then((res) => getResult<T>(res))
}

export async function setTemplateIntegration<T>(
  name: string,
  templateIntegration: T,
): Promise<APIResult<T>> {
  return await instance
    .post(`/api/integration/${name}/templateIntegration`, {
      templateIntegration,
    })
    .then((res) => getResult<T>(res))
}

export async function listProduct(
  channel: string,
  productId: number,
): Promise<APIResult<OfferResponse>> {
  const response = await instance
    .post(`/api/integration/${channel}/listProduct`, {
      productId,
    })
    .then((res) => getResult<OfferResponse>(res))
  return response
}

export async function unlistProduct(
  channel: string,
  productId: number,
  integrationId?: number,
): Promise<APIResult<string>> {
  const response = await instance
    .post(`/api/integration/${channel}/unlistProduct`, {
      productId,
      integrationId,
    })
    .then((res) => getResult<string>(res))
  return response
}

export interface GetProductIntegrationsResult {
  ebay?: EbayProductIntegration[]
  // etsy?: EtsyProductIntegration[]
  bigcommerce?: BigCommerceProductIntegration[]
  shopify?: ShopifyProductIntegration[]
}
export async function getAllProductIntegrations(): Promise<
  APIResult<GetProductIntegrationsResult>
> {
  const listings = await instance
    .get('/api/integration/allProductIntegrations', {})
    .then((res) => getResult<GetProductIntegrationsResult>(res))
  return listings
}
