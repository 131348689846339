import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import {
  AspectMetadata,
  EbayAspectTemplateIntegration,
  EbayProductIntegration,
  EbayTemplateIntegration,
  GetEbayIntegrationsResult,
  OfferResponse,
} from '../../../../types/Ebay.types'
import {
  err,
  getDescriptionValue,
  getTitleValue,
  log,
} from '../../../../utils/functions'
import {
  GetProduct,
  getProductAspectsForCategory,
  GetProductTemplate,
} from '../../../../api/product'
import { DataFields, Integration } from '../../../../api/types'
import {
  getIntegration,
  IntegrationName,
  listProduct,
  unlistProduct,
} from '../../../../api/integration'
import {
  getEbayIntegrations,
  setEbayProductIntegration,
  setEbayTemplateIntegration,
} from '../../../../api/integrations/ebay'
import EbayCategories, { EbayCategory } from './EbayCategories'
import Button from '@mui/joy/Button'
import Stack from '@mui/material/Stack'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import EbayIntegrationConditions from '../../ConditionsInput'
import ItemAspects from './ItemAspects'
import EbayListingConfigurationsComponent from './EbayListingConfigurationsComponent'
import EbayProductAspects from './EbayProductAspects'
import { ProductAttributeInputText } from '../../../../pages/products/product/ProductAttributeInputText'
import ListSyncComponent from '../common/ListSyncComponent'
import LoadingBox from '../../../common/LoadingBox'
import { ProductTitleDescription } from '../../ProductTitleDescription'
import { ListUnlistButtons } from '../common/ListUnlistButtons'
import { APIResult } from '../../../../utils/types'
import MissingIntegration from '../MissingIntegration'
import AccordionGroup from '@mui/joy/AccordionGroup'
import Accordion from '@mui/joy/Accordion'
import AccordionSummary from '@mui/joy/AccordionSummary'
import ListItemContent from '@mui/joy/ListItemContent'
import AccordionDetails from '@mui/joy/AccordionDetails'
import NamedInput from '../../../common/NamedInput'
import AttributeSelect from '../../../common/AttributeSelect'
import MissingTemplateIntegration from '../MissingTemplateIntegration'

export type AspectIntegrationInput = Omit<
  DataFields<EbayAspectTemplateIntegration>,
  'ebayTemplateIntegrationId' | 'type'
>

interface EbayTemplateIntegrationComponentProps {
  template?: GetProductTemplate
  product?: GetProduct
  onUpdate?: () => void
}
export default function EbayTemplateIntegrationComponent({
  template,
  product,
  onUpdate,
}: EbayTemplateIntegrationComponentProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true)
  const [category, setCategory] = useState<EbayCategory>()
  const [categoryVersion, setCategoryVersion] = useState<number>(0)
  const [productAspects, setProductAspects] = useState<
    AspectMetadata | undefined
  >()
  const [integration, setIntegration] = useState<Integration | undefined>()
  const [templateIntegration, setTemplateIntegration] = useState<
    EbayTemplateIntegration | undefined
  >()
  const [productIntegration, setProductIntegration] = useState<
    EbayProductIntegration | undefined
  >()
  // const [ebayIntegration, setEbayIntegration] = useState<
  //   EbayTemplateIntegration | undefined
  // >()
  const [ebayAspectIntegrations, setEbayAspectIntegrations] = useState<
    EbayAspectTemplateIntegration[]
  >([])
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [oAuthUrl, setOAuthUrl] = useState<string | undefined>()
  const [alert, setAlert] = useState<AlertInput>({ open: false })
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const [title, setTitle] = useState<string | undefined>(undefined)
  const [subtitle, setSubtitle] = useState<string>('')
  const [description, setDescription] = useState<string | undefined>(
    product?.product.description,
  )

  const [brand, setBrand] = useState<string>('')
  const [sku] = useState<string>(product?.product.sku || '')

  const [conditionPrimary, setConditionPrimary] = useState<string>(
    product?.product.condition || 'New',
  )
  const [conditionSecondary, setConditionSecondary] = useState<
    string | undefined
  >()
  const [conditionDescription, setConditionDescription] = useState<string>(
    product?.attributes?.find(
      (a) => a.templateAttribute.name === 'Condition Description',
    )?.attribute?.value || '',
  )
  const [price] = useState<number>(product?.product.price ?? 0)
  const [quantity] = useState<number>(product?.product.quantity ?? 0)
  const [list, setList] = useState<boolean>(false)
  const [sync, setSync] = useState<boolean>(false)
  const [syncQuantity, setSyncQuantity] = useState<boolean>(false)

  const [detailUpdate, setDetailUpdate] = useState<number>(0)

  const templateId = template?.template.id
  const templateAttributes = template?.attributes || []

  const handleListProduct = () => {
    const productId = product?.product.id
    if (!productId) {
      return
    }
    listProduct(IntegrationName.EBAY, productId)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully listed product.')
      })
      .catch((e) => {
        const res = e.response.data as APIResult<OfferResponse>
        if (res.data) {
          const errors = res.data.errors || []
          const warnings = res.data.warnings || []

          // const severity = errors.length ? 'error' : 'warning'
          const msg = errors
            .concat(warnings)
            .map(
              (e) =>
                e.message +
                '<br />' +
                (e.parameters
                  ?.map((p) => `${p.name}: ${p.value}`)
                  .join('<br />') || ''),
            )
            .join('<br /><br />')

          setErrorMessage(msg)
        } else {
          setAlert({
            open: true,
            severity: 'error',
            message: `${e.response.data.message}`,
          })
        }
      })
  }

  const handleUnlistProduct = () => {
    const productId = product?.product.id
    if (!productId) {
      return
    }
    if (!integration?.id) {
      return
    }
    unlistProduct(IntegrationName.EBAY, productId, integration.id)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully Unlisted.')
      })
      .catch((e) =>
        setAlert({
          open: true,
          severity: 'error',
          message: e.response.data.message,
        }),
      )
  }

  const handleSelectCategory = (
    categoryVersion: number,
    category: EbayCategory | undefined,
  ) => {
    setCategoryVersion(categoryVersion)
    setCategory(category)
  }

  const handleSave = () => {
    if (product && templateIntegration?.id) {
      const updateProductIntegration: DataFields<EbayProductIntegration> = {
        templateIntegrationId: templateIntegration?.id,
        productId: product.product.id,
        title,
        description,
        list,
        sync,
        syncQuantity,
      }
      setEbayProductIntegration(updateProductIntegration)
        .then((res) => {
          handleAlert(setAlert, res, 'Set Ebay Product Integration.')
        })
        .catch((e) => {
          err(e)
        })
      return
    }

    if (!integration || !template) return
    console.log('Setting', category)
    const ebayInt: EbayTemplateIntegration = {
      integrationId: integration.id,
      templateId: templateId,
      ebayCategoryId: category?.id || 0,
      ebayCategoryName: category?.name || '',
      ebayCategoryVersion: categoryVersion,
      title: title || '',
      subtitle,
      description: description || '',
      condition: conditionPrimary,
      conditionDescription,
      brand: '',
      list,
      sync,
      syncQuantity,
    }

    // setEbayIntegration(ebayInt)
    setEbayTemplateIntegration(ebayInt, ebayAspectIntegrations)
      .then((res) => {
        handleAlert(setAlert, res, 'Saved Ebay Template Integration')
        if (res.success && res.data) {
          handleSetState({ templateIntegration: res.data })
          log(res)
        }
      })
      .catch((e) => err(e))
  }

  const handleAspectChange = (aspect: AspectIntegrationInput) => {
    // find index
    const indexOf = ebayAspectIntegrations.findIndex(
      (a) => a.aspectName === aspect.aspectName,
    )
    // if not found, insert at end
    const index = indexOf === -1 ? ebayAspectIntegrations.length : indexOf

    if (index === -1) return
    // update index
    const newAspectIntegrations = ebayAspectIntegrations.slice()
    newAspectIntegrations[index] = aspect as EbayAspectTemplateIntegration

    // save
    setEbayAspectIntegrations(newAspectIntegrations)
  }

  /* Get Integration and Auth */
  useEffect(() => {
    getIntegration(IntegrationName.EBAY)
      .then((res) => {
        setLoading(false)
        if (res.success && res.data) {
          const { integration, authenticated, oAuthUrl } = res.data
          setIntegration(integration)
          setIsAuthenticated(authenticated)
          setOAuthUrl(oAuthUrl)
        }
      })
      .catch((e) => log(e))
  }, [])

  /* Get Template, Product Integrations */
  useEffect(() => {
    if (!integration?.id) return
    const tid = templateId || product?.template.id
    const productId = product?.product.id

    getEbayIntegrations(integration.id, tid, productId)
      .then((res) => {
        if (res.success && res.data) {
          handleSetState(res.data)
        }
      })
      .catch((e) => log(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integration, templateId, product])

  const handleSetState = ({
    templateIntegration,
    productIntegration,
  }: GetEbayIntegrationsResult) => {
    const ebayTemplateIntegration = templateIntegration?.ebayTemplateIntegration
    const ebayAspectTemplateIntegrations =
      templateIntegration?.ebayAspectTemplateIntegrations
    setTemplateIntegration(ebayTemplateIntegration)
    setEbayAspectIntegrations(ebayAspectTemplateIntegrations || [])
    setProductIntegration(productIntegration)

    // Title
    const title = getTitleValue(
      productIntegration,
      ebayTemplateIntegration,
      product,
    )
    const description = getDescriptionValue(
      productIntegration,
      ebayTemplateIntegration,
      product,
    )
    setTitle(title)
    setSubtitle('') // Not setting subtitle
    setDescription(description)
    setDetailUpdate(detailUpdate + 1)

    // Listing
    setList(productIntegration?.list ?? ebayTemplateIntegration?.list ?? false)
    setSync(productIntegration?.sync ?? ebayTemplateIntegration?.sync ?? false)
    setSyncQuantity(
      productIntegration?.syncQuantity ??
        ebayTemplateIntegration?.syncQuantity ??
        false,
    )

    // Set Category
    if (ebayTemplateIntegration) {
      setCategory({
        id: ebayTemplateIntegration.ebayCategoryId || 0,
        name: ebayTemplateIntegration.ebayCategoryName || '',
      })
      setCategoryVersion(ebayTemplateIntegration.ebayCategoryVersion || 0)
      return
    }
  }

  useEffect(() => {
    category?.id &&
      getProductAspectsForCategory(category.id)
        .then((res) => setProductAspects(res?.data || ({} as AspectMetadata)))
        .catch((e) => log(e))
  }, [category])

  const attributeNames =
    template?.attributes.map((a) => a.attribute.name) ||
    product?.attributes.map((a) => a.templateAttribute.name)

  if (loading) {
    return <LoadingBox />
  }

  if (!integration && isAuthenticated === false) {
    return <MissingIntegration />
  }

  if (product && !templateIntegration) {
    return (
      <MissingTemplateIntegration
        templateId={product.product.templateId}
        channelName={IntegrationName.CSV}
      />
    )
  }

  return (
    <>
      {integration && isAuthenticated ? (
        <Grid container justifyContent="center" spacing={3} sx={{ pt: 2 }}>
          {product ? (
            <Grid item xs={12}>
              <ListUnlistButtons
                list={list}
                hasProductIntegration={!!productIntegration}
                product={product}
                onList={handleListProduct}
                onUnlist={handleUnlistProduct}
              />
            </Grid>
          ) : null}

          {errorMessage ? (
            <Grid item xs={12}>
              <Typography>{errorMessage}</Typography>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <ListSyncComponent
              list={list}
              sync={sync}
              syncQuantity={syncQuantity}
              onListChange={setList}
              onSyncChange={setSync}
              onSyncQuantityChange={setSyncQuantity}
            />
          </Grid>

          <Grid item xs={12}>
            <AccordionGroup
              size="lg"
              variant="outlined"
              sx={{ borderRadius: '6px' }}
            >
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  slotProps={{
                    button: {
                      style: {
                        borderRadius: '6px',
                      },
                    },
                  }}
                >
                  <ListItemContent sx={{ p: 2 }}>
                    <Typography level="title-lg">
                      Category Integration
                    </Typography>
                    <Typography level="body-xs">
                      Configure Categories and how values and aspects are listed
                    </Typography>
                  </ListItemContent>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container justifyContent="center" spacing={3} pt={4}>
                    {/* Category */}
                    <Grid item xs={12}>
                      {template ? (
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item xs={12}>
                            <EbayCategories
                              category={category}
                              categoryVersion={categoryVersion}
                              onChange={handleSelectCategory}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <ProductAttributeInputText
                          label="Category"
                          value={
                            '' +
                            category?.name +
                            (category?.id ? ` - ${category.id}` : '')
                          }
                          disabled={true}
                        />
                      )}
                    </Grid>

                    {/* Title and Description */}
                    <Grid item xs={12}>
                      <ProductTitleDescription
                        key={`product-title-description-${detailUpdate}`}
                        template={template}
                        product={product}
                        title={title}
                        description={description}
                        required={!!product}
                        onTitleChange={setTitle}
                        onDescriptionChange={setDescription}
                        disableUnparsedEdit={true}
                      />
                    </Grid>

                    {/* Product Values */}
                    {product && (
                      <>
                        <Grid item xs={12}>
                          <ProductAttributeInputText
                            label="SKU"
                            value={sku}
                            disabled={true}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <EbayIntegrationConditions
                            conditionPrimary={conditionPrimary}
                            conditionSecondary={conditionSecondary}
                            disabled={true}
                            onChange={(primary, secondary) => {
                              setConditionPrimary(primary)
                              setConditionSecondary(secondary)
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <NamedInput
                            name="Price"
                            nameEditable={false}
                            value={price}
                            disabled={true}
                            helperText={
                              <Typography level="body-xs">
                                Price may vary according to pricing
                                configuration.
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <NamedInput
                            name="Quantity"
                            nameEditable={false}
                            value={quantity}
                            disabled={true}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <AttributeSelect
                            name="Condition Description"
                            nameEditable={false}
                            value={conditionDescription}
                            attributeNames={attributeNames}
                            multiple={false}
                            onChange={(v) => setConditionDescription(v || '')}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12}>
                      <AttributeSelect
                        name="Brand"
                        nameEditable={false}
                        value={brand}
                        attributeNames={attributeNames}
                        multiple={false}
                        onChange={(v) => setBrand(v || '')}
                      />
                    </Grid>

                    {template && (
                      <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item xs={12}>
                            <ItemAspects
                              templateIntegration={templateIntegration}
                              aspects={productAspects?.aspects || []}
                              aspectIntegrations={ebayAspectIntegrations}
                              templateAttributes={templateAttributes}
                              onChange={handleAspectChange}
                              onUpdate={onUpdate}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {product && (
                      <Grid item xs={12}>
                        <EbayProductAspects
                          product={product}
                          templateIntegration={templateIntegration}
                          aspects={productAspects?.aspects || []}
                          aspectIntegrations={ebayAspectIntegrations}
                          templateAttributes={templateAttributes}
                          disabled={true}
                          onChange={handleAspectChange}
                          onUpdate={onUpdate}
                        />
                      </Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </AccordionGroup>
          </Grid>

          <Grid item xs={12}>
            <EbayListingConfigurationsComponent
              templateIntegrationId={templateIntegration?.id}
              productIntegrationId={productIntegration?.id}
              productId={product?.product?.id}
            />
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <Button variant="solid" onClick={handleSave}>
                Save
              </Button>
            </Stack>
          </Grid>
          <Alert
            alert={alert}
            onClose={() => setAlert({ ...alert, open: false })}
          ></Alert>
        </Grid>
      ) : null}
    </>
  )
}
