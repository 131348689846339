import React from 'react'
import Grid from '@mui/material/Grid'
import { ProductAttributeInputText } from '../../pages/products/product/ProductAttributeInputText'
import { ProductAttributeInputSelect } from '../../pages/products/product/ProductAttributeInputSelect'

export enum SizeWeightChangeEnum {
  WEIGHT_UNIT = 'Weight Unit',
  SIZE_UNIT = 'Size Unit',
  WEIGHT = 'Weight',
  L = 'L',
  W = 'W',
  H = 'H',
}

interface ProductSizeWeightInputProps {
  weight?: string
  width?: string
  length?: string
  height?: string
  weightUnit: string
  sizeUnit: string
  onChange: (name: SizeWeightChangeEnum, value: string) => void
  template?: boolean
}
export default function ProductSizeWeightInput({
  weight,
  width,
  length,
  height,
  weightUnit,
  sizeUnit,
  onChange,
  template,
}: ProductSizeWeightInputProps): JSX.Element {
  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <ProductAttributeInputText
              type="number"
              label="Weight"
              required
              value={weight}
              onChange={(v) => onChange(SizeWeightChangeEnum.WEIGHT, v)}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductAttributeInputSelect
              label="Weight Unit"
              value={weightUnit}
              options={['kg', 'lb', 'g', 'oz']}
              onChange={(v) => onChange(SizeWeightChangeEnum.WEIGHT_UNIT, v)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item xs={12}>
            <ProductAttributeInputText
              type="number"
              label="Length"
              required
              value={length}
              onChange={(v) => onChange(SizeWeightChangeEnum.L, v)}
            />
          </Grid>

          <Grid item xs={12}>
            <ProductAttributeInputText
              type="number"
              label="Width"
              required
              value={width}
              onChange={(v) => onChange(SizeWeightChangeEnum.W, v)}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductAttributeInputText
              type="number"
              label="Height"
              required
              value={height}
              onChange={(v) => onChange(SizeWeightChangeEnum.H, v)}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductAttributeInputSelect
              label="Size Unit"
              value={sizeUnit}
              options={['feet', 'in', 'm', 'cm', 'mm']}
              onChange={(v) => onChange(SizeWeightChangeEnum.SIZE_UNIT, v)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
