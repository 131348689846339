import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/joy/Typography'
import LabelInput from '../../../components/common/LabelInput'

interface ProductAttributeInputTextProps {
  label?: string
  hideLabel?: boolean
  tag?: string
  placeholder?: string
  value?: string | undefined
  required?: boolean
  onChange?: (typeParam: string) => void
  onBlur?: () => void
  onFocus?: () => void
  disabled?: boolean
  multiline?: boolean
  type?: 'text' | 'number'
  richText?: boolean
  format?: 'USD'
  endDecorator?: React.ReactNode
}

export function ProductAttributeInputText({
  label,
  hideLabel,
  tag,
  placeholder,
  value,
  required,
  onChange,
  onBlur,
  onFocus,
  disabled,
  multiline,
  type,
  richText,
  endDecorator,
  format,
}: ProductAttributeInputTextProps): JSX.Element {
  const [errorText, setErrorText] = useState('')

  return (
    <Grid container spacing={1} justifyContent="center">
      {!hideLabel && (
        <Grid item xs={12} md={3} lg={2}>
          <Stack
            direction="row"
            justifyContent="center"
            sx={{
              position: 'relative',
              height: '100%',
              top: 8,
            }}
          >
            <Typography
              sx={{
                mr: '0.5em',
                mb: '0.5em',
                color: disabled ? 'rgba(0, 0, 0, 0.38);' : 'inherit',
              }}
            >
              <strong>{label}</strong>
            </Typography>
          </Stack>
        </Grid>
      )}
      <Grid item xs={12} md={hideLabel ? 12 : 9} lg={hideLabel ? 12 : 10}>
        {multiline ? (
          <TextField
            onBlur={() => {
              onBlur?.()
              setErrorText(!value && required ? label + ' is required.' : '')
            }}
            onFocus={() => onFocus?.()}
            error={!!errorText}
            helperText={errorText}
            type={type}
            disabled={disabled}
            label={tag}
            size="small"
            multiline
            minRows={15}
            maxRows={30}
            fullWidth
            value={value !== undefined ? value : ''}
            autoComplete={'off'}
            required={required}
            onChange={(e) => {
              onChange?.(e.target.value)
              setErrorText(
                !e.target.value && required ? label + ' is required.' : '',
              )
            }}
            placeholder={placeholder}
            sx={{ resize: 'vertical' }}
          />
        ) : (
          <LabelInput
            onBlur={() => {
              onBlur?.()
              setErrorText(!value && required ? label + ' is required.' : '')
            }}
            onFocus={() => onFocus?.()}
            format={format}
            helperText={errorText}
            type={type}
            label={tag}
            size="md"
            fullWidth
            value={value !== undefined ? value : ''}
            disabled={disabled}
            required={required}
            placeholder={placeholder}
            onChange={(v) => {
              onChange?.(v)
              setErrorText(!v && required ? label + ' is required.' : '')
            }}
            endDecorator={endDecorator}
          />
        )}
      </Grid>
    </Grid>
  )
}
