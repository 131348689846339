import React, { useEffect, useState } from 'react'
import Typography from '@mui/joy/Typography'
import Button from '@mui/joy/Button'
import { Integration } from '../../../../api/types'
import Grid from '@mui/material/Grid'
import {
  getInventoryLocation,
  getListingPolicies,
  setInventoryLocation,
  setListingPolicies,
} from '../../../../api/integrations/ebay'
import { err, log } from '../../../../utils/functions'
import { CountryCodeEnum, ListingPolicies } from '../../../../types/Ebay.types'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import LabelInput from '../../../common/LabelInput'
import { SingleSelect } from '../../../common/SingleSelect'

interface EbayPoliciesProps {
  integration: Integration | undefined
}
export default function EbayPolicies({
  integration,
}: EbayPoliciesProps): JSX.Element | null {
  // const [location, setLocation] = useState<InventoryLocation>()
  const [listingPoliciesState, setListingPoliciesState] =
    useState<ListingPolicies>()
  const [country, setCountry] = useState<CountryCodeEnum>(CountryCodeEnum.US)
  const [postalCode, setPostalCode] = useState<string>('')
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleSetInventoryLocation = () => {
    if (!country || !postalCode) {
      return
    }
    setInventoryLocation({ country, postalCode })
      .then((res) => {
        handleAlert(setAlert, res, 'Inventory location set.')
      })
      .catch((e) => err(e))
  }

  const handleSetDefaultPolicies = () => {
    setListingPolicies()
      .then((res) => {
        handleAlert(setAlert, res, 'Inventory location set.')
      })
      .catch((e) => err(e))
  }

  useEffect(() => {
    getListingPolicies()
      .then((res) => {
        if (res.success && res.data) {
          setListingPoliciesState(res.data)
        }
      })
      .catch((e) => err(e))
    return
  }, [])

  useEffect(() => {
    getInventoryLocation()
      .then((res) => {
        if (res.success && res.data) {
          setCountry(res.data.location.address.country as CountryCodeEnum)
          setPostalCode(res.data.location.address.postalCode)
        } else {
          log(res.message)
        }
      })
      .catch((e) => err(e))
    return
  }, [])

  return (
    <Grid container justifyContent="center" spacing={8}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography level="title-lg">Ebay Listing Policies</Typography>

            <Typography level="body-sm">
              Your store must have payment, shipping, and return policies.{' '}
              <a
                href="https://www.bizpolicy.ebay.com/businesspolicy/manage"
                target="_blank"
                rel="noreferrer"
              >
                Edit on Ebay
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {listingPoliciesState ? (
              <>
                <Typography>
                  <strong>Payment Policy:</strong>{' '}
                  <Typography level="body-sm">
                    {listingPoliciesState.paymentPolicies?.[0]?.name}
                  </Typography>
                </Typography>
                <Typography>
                  <strong>Return Policy:</strong>{' '}
                  <Typography level="body-sm">
                    {listingPoliciesState.returnPolicies?.[0]?.name}
                  </Typography>
                </Typography>
                <Typography>
                  <strong>Shipping Policy:</strong>{' '}
                  <Typography level="body-sm">
                    {listingPoliciesState.fulfillmentPolicies?.[0]?.name}
                  </Typography>
                </Typography>
              </>
            ) : (
              <Button
                variant="solid"
                onClick={() => {
                  handleSetDefaultPolicies()
                }}
              >
                Set Default Policies
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={12}>
            <Typography level="title-lg">Ebay Inventory Location</Typography>

            <Typography level="body-sm">
              Set your store location.{' '}
              <a
                href="https://accountsettings.ebay.com/uas/addresses"
                target="_blank"
                rel="noreferrer"
              >
                Edit on Ebay
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              spacing={3}
              direction="column"
            >
              <Grid item xs={12} sm={6}>
                <SingleSelect
                  label="Country Code"
                  value={country}
                  onChange={(v) => setCountry(v as CountryCodeEnum)}
                  options={Object.keys(CountryCodeEnum)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LabelInput
                  label="Postal Code"
                  fullWidth
                  value={postalCode}
                  onChange={setPostalCode}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="solid"
                  onClick={() => {
                    handleSetInventoryLocation()
                  }}
                >
                  Set Location
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography level="title-lg">Ebay Notifications</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Notfications allow us to recieve live updates when an item is
              sold.
              <br />
              Set up this configuration by clicking below.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="solid"
              onClick={() => {
                handleSetNotificationPreferences()
              }}
            >
              Set Notification Preferences
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="solid"
              onClick={() => {
                handleGetNotificationPreferences()
              }}
            >
              Get Notification Preferences
            </Button>
          </Grid>
        </Grid>
      </Grid> */}

      {/* <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography level="title-lg">Validate Test User</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => {
                handleValidateUser()
              }}
            >
              Validate
            </Button>
          </Grid>
        </Grid>
      </Grid> */}
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
