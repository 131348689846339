import instance from '..'
import { APIResult } from '../../utils/types'
import { getResult } from '../../utils/functions'
import {
  EtsyProductIntegration,
  EtsyPropertyProductIntegration,
  EtsyPropertyTemplateIntegration,
  EtsyShopReturnPolicy,
  EtsyShopShippingProfile,
  EtsyTaxonomyNode,
  EtsyTaxonomyProperty,
  EtsyTemplateIntegration,
  GetEtsyIntegrationsResult,
  GetEtsyProductIntegration,
  GetEtsyTemplateIntegration,
} from '../../types/Etsy.types'
import { DataFields } from '../types'

export async function getEtsyTaxonomy(): Promise<
  APIResult<EtsyTaxonomyNode[]>
> {
  const taxonomy = await instance
    .get('/api/integration/etsy/taxonomy')
    .then((res) => getResult<EtsyTaxonomyNode[]>(res))
  return taxonomy
}

export async function getEtsyShopShippingProfiles(): Promise<
  APIResult<EtsyShopShippingProfile[]>
> {
  const shippingProfiles = await instance
    .get('/api/integration/etsy/shopShippingProfiles')
    .then((res) => getResult<EtsyShopShippingProfile[]>(res))
  return shippingProfiles
}

export async function getEtsyShopReturnPolicies(): Promise<
  APIResult<EtsyShopReturnPolicy[]>
> {
  const shippingProfiles = await instance
    .get('/api/integration/etsy/shopReturnPolicies')
    .then((res) => getResult<EtsyShopReturnPolicy[]>(res))
  return shippingProfiles
}

export async function getEtsyTaxonomyProperties(
  taxonomyId: number,
): Promise<APIResult<EtsyTaxonomyProperty[]>> {
  const taxonomy = await instance
    .get('/api/integration/etsy/taxonomyProperties', {
      params: { taxonomyId },
    })
    .then((res) => getResult<EtsyTaxonomyProperty[]>(res))
  return taxonomy
}

export async function getEtsyTemplateIntegration(
  integrationId: number,
  templateId: number,
): Promise<APIResult<GetEtsyTemplateIntegration>> {
  const etsyTemplateIntegration = await instance
    .get('/api/integration/etsy/templateIntegration', {
      params: {
        integrationId,
        templateId,
      },
    })
    .then((res) => getResult<GetEtsyTemplateIntegration>(res))
  return etsyTemplateIntegration
}

export async function setEtsyTemplateIntegration(
  etsyTemplateIntegration: Omit<
    DataFields<EtsyTemplateIntegration>,
    'clientId'
  >,
  etsyPropertyTemplateIntegration: DataFields<EtsyPropertyTemplateIntegration>[],
): Promise<APIResult<GetEtsyTemplateIntegration>> {
  const setEtsyTemplateIntegration = await instance
    .post('/api/integration/etsy/templateIntegration', {
      etsyTemplateIntegration,
      etsyPropertyTemplateIntegration,
    })
    .then((res) => getResult<GetEtsyTemplateIntegration>(res))
  return setEtsyTemplateIntegration
}

export async function getEtsyProductIntegration(
  integrationId: number,
  productId: number,
): Promise<APIResult<GetEtsyProductIntegration>> {
  const etsyProductIntegration = await instance
    .get('/api/integration/etsy/productIntegration', {
      params: {
        integrationId,
        productId,
      },
    })
    .then((res) => getResult<GetEtsyProductIntegration>(res))
  return etsyProductIntegration
}

export async function getEtsyIntegrations(
  templateId?: number,
  productId?: number,
): Promise<APIResult<GetEtsyIntegrationsResult>> {
  const ebayTemplateIntegration = await instance
    .get('/api/integration/etsy/integrations', {
      params: {
        templateId,
        productId,
      },
    })
    .then((res) => getResult<GetEtsyIntegrationsResult>(res))
  return ebayTemplateIntegration
}

export async function setEtsyProductIntegration(
  etsyProductIntegration: DataFields<EtsyProductIntegration>,
  etsyPropertyProductIntegration: DataFields<EtsyPropertyProductIntegration>[],
): Promise<APIResult<GetEtsyProductIntegration>> {
  const setEtsyProductIntegration = await instance
    .post('/api/integration/etsy/productIntegration', {
      etsyProductIntegration,
      etsyPropertyProductIntegration,
    })
    .then((res) => getResult<GetEtsyProductIntegration>(res))
  return setEtsyProductIntegration
}
