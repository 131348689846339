import React, { useEffect, useState } from 'react'
import { GetProduct, GetProductTemplate } from '../../../../api/product'
import Grid from '@mui/material/Grid'
import Button from '@mui/joy/Button'
import {
  getIntegration,
  GetIntegrationResult,
  IntegrationName,
  listProduct,
  unlistProduct,
} from '../../../../api/integration'
import { err, isDefined, unique } from '../../../../utils/functions'
import {
  CSVTemplateIntegration,
  CSVProductIntegration,
  CSVProductHeaderIntegration,
  CSVSheetHeaderIntegration,
  CSVTemplateHeaderIntegration,
  GetCSVSpreadsheetIntegration,
} from '../../../../types/CSV.types'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import { Integration } from '../../../../api/types'
import ListSyncComponent from '../common/ListSyncComponent'
import {
  getCSVIntegrations,
  setCSVProductIntegration,
  setCSVTemplateIntegration,
} from '../../../../api/integrations/csv'
import LoadingBox from '../../../common/LoadingBox'
import { ListUnlistButtons } from '../common/ListUnlistButtons'
import MissingIntegration from '../MissingIntegration'
import Typography from '@mui/joy/Typography'
import AccordionGroup from '@mui/joy/AccordionGroup'
import Accordion from '@mui/joy/Accordion'
import AccordionSummary from '@mui/joy/AccordionSummary'
import AccordionDetails from '@mui/joy/AccordionDetails'
import ListItemContent from '@mui/joy/ListItemContent'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Link } from 'react-router-dom'
import { SingleSelect } from '../../../common/SingleSelect'
import { PRODUCT_FIELD_NAMES } from '../../../../types'
import { extractAttributeName } from '../../../../classes/Parser'
import Tabs from '@mui/joy/Tabs'
import TabList from '@mui/joy/TabList'
import TabPanel from '@mui/joy/TabPanel'
import Tab from '@mui/joy/Tab'
import AttributeSelect from '../../../common/AttributeSelect'
import MissingTemplateIntegration from '../MissingTemplateIntegration'

interface CSVTemplateIntegrationComponentProps {
  integration?: GetIntegrationResult<IntegrationName.CSV> | undefined
  template?: GetProductTemplate
  product?: GetProduct
  onUpdate?: () => void
}

export default function CSVTemplateIntegrationComponent({
  integration: integrationResult,
  template,
  product,
}: CSVTemplateIntegrationComponentProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(!integrationResult)
  const [spreadsheetIntegrations, setSpreadsheetIntegrations] = useState<
    GetCSVSpreadsheetIntegration[]
  >([])
  const [templateIntegration, setTemplateIntegration] = useState<
    CSVTemplateIntegration | undefined
  >()
  const [productIntegration, setProductIntegration] = useState<
    CSVProductIntegration | undefined
  >()
  const [templateHeaderIntegrations, setTemplateHeaderIntegrations] = useState<
    Partial<CSVTemplateHeaderIntegration>[]
  >([])
  const [productHeaderIntegrations, setProductHeaderIntegrations] = useState<
    Partial<CSVProductHeaderIntegration>[]
  >([])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [integration, setIntegration] = useState<Integration | undefined>(
    integrationResult?.integration,
  )
  const [list, setList] = useState<boolean>(false)
  const [sync, setSync] = useState<boolean>(false)
  const [syncQuantity, setSyncQuantity] = useState<boolean>(false)

  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleListProduct = () => {
    const productId = product?.product.id
    if (!productId) {
      return
    }
    listProduct(IntegrationName.CSV, productId)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully listed product.')
      })
      .catch((e) => {
        setAlert({
          open: true,
          severity: 'error',
          message: `${e.response.data.message}`,
        })
      })
  }

  const handleUnlistProduct = () => {
    const productId = product?.product.id
    if (!integration?.id || !productId) return
    unlistProduct(IntegrationName.CSV, productId, integration.id)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully Unlisted.')
      })
      .catch((e) =>
        setAlert({
          open: true,
          severity: 'error',
          message: e.response.data.message,
        }),
      )
  }

  // Get and set CloverTemplateIntegration, CloverLocations
  useEffect(() => {
    // Integration
    if (!integration) {
      getIntegration(IntegrationName.CSV)
        .then((res) => {
          setLoading(false)
          if (res.success && res.data) {
            setIntegration(res.data.integration)
          }
        })
        .catch((e) => err(e))
    }
  }, [integration])

  useEffect(() => {
    if (!integration) {
      return
    }

    const integrationId = integration.id
    const templateId = template?.template.id || product?.template.id
    // CSV Template Integration
    if (integrationId && templateId) {
      setLoading(true)
      getCSVIntegrations(integrationId, templateId, product?.product.id)
        .then((res) => {
          setLoading(false)

          if (res.success && res.data) {
            const data = res.data
            const productIntegration =
              data.productIntegration?.csvProductIntegration
            const templateIntegration =
              data.templateIntegration?.csvTemplateIntegration
            setTemplateIntegration(templateIntegration)
            setTemplateHeaderIntegrations(
              data.templateIntegration?.csvTemplateHeaderIntegrations || [],
            )
            setProductIntegration(productIntegration)
            setProductHeaderIntegrations(
              data.productIntegration?.csvProductHeaderIntegrations || [],
            )

            setSpreadsheetIntegrations(data.spreadsheetIntegrations || [])

            setList(
              productIntegration?.list ?? templateIntegration?.list ?? false,
            )
            setSync(
              productIntegration?.sync ?? templateIntegration?.sync ?? false,
            )
            setSyncQuantity(
              productIntegration?.syncQuantity ??
                templateIntegration?.syncQuantity ??
                false,
            )
          }
        })
        .catch((e) => err(e))
    }
  }, [integration, product, template])

  const handleSave = () => {
    console.log('Save')
    console.log(product && templateIntegration)
    console.log(!integration || !template)
    // if product, set product integration
    if (product && templateIntegration) {
      const updateProductIntegration = {
        csvTemplateIntegrationId: templateIntegration?.id,
        productId: product.product.id,
        list,
        sync,
        syncQuantity,
      }

      setCSVProductIntegration(
        updateProductIntegration,
        productHeaderIntegrations,
      )
        .then((res) => {
          handleAlert(setAlert, res, 'Set CSV Product Integration.')
        })
        .catch((e) => {
          err(e)
        })
      return
    }

    // If Template, save template
    if (!integration || !template) return
    const csvTemplateIntegration = {
      templateId: template.template.id,
      integrationId: integration.id,
      list,
      sync,
      syncQuantity,
    }
    setCSVTemplateIntegration(
      csvTemplateIntegration,
      templateHeaderIntegrations,
    )
      .then((res) => {
        handleAlert(setAlert, res, 'Set CSV Template Integration.')
      })
      .catch((e) => {
        err(e)
      })
  }

  const handleTemplateHeaderChange = (
    header: CSVSheetHeaderIntegration,
    value: string | undefined,
  ) => {
    // update integration value
    // const headerIntegration

    const newTemplateHeaderIntegrations = templateHeaderIntegrations.slice()
    const index = newTemplateHeaderIntegrations.findIndex(
      (templateHeader) =>
        templateHeader.csvSheetHeaderIntegrationId === header.id,
    )

    const updatedTemplateHeader = {
      csvSheetHeaderIntegrationId: header.id,
      value: value || '',
    }
    if (index === -1) {
      newTemplateHeaderIntegrations.push(updatedTemplateHeader)
    } else {
      newTemplateHeaderIntegrations[index] = updatedTemplateHeader
    }
    setTemplateHeaderIntegrations(newTemplateHeaderIntegrations)
  }

  const handleProductHeaderChange = (
    header: CSVSheetHeaderIntegration,
    value: string | undefined,
  ) => {
    // update integration value
    // const headerIntegration

    const newProductHeaderIntegrations = productHeaderIntegrations.slice()
    const index = newProductHeaderIntegrations.findIndex(
      (productHeader) =>
        productHeader.csvSheetHeaderIntegrationId === header.id,
    )

    const updatedProductHeader = {
      csvSheetHeaderIntegrationId: header.id,
      value: value || '',
    }
    if (index === -1) {
      newProductHeaderIntegrations.push(updatedProductHeader)
    } else {
      newProductHeaderIntegrations[index] = updatedProductHeader
    }
    setProductHeaderIntegrations(newProductHeaderIntegrations)
  }

  // const getHeaderValue = (header: CSVSheetHeaderIntegration): string => {
  //   if (product) {
  //     return (
  //       productHeaderIntegrations.find(
  //         (productHeader) =>
  //           productHeader.csvSheetHeaderIntegrationId === header.id,
  //       )?.value || ''
  //     )
  //   } else {
  //     return (
  //       templateHeaderIntegrations.find(
  //         (templateHeader) =>
  //           templateHeader.csvSheetHeaderIntegrationId === header.id,
  //       )?.value || ''
  //     )
  //   }
  // }

  const attributeNames = unique(
    Object.keys(PRODUCT_FIELD_NAMES).concat(
      template?.attributes?.map((a) => a.attribute.name) ||
        product?.attributes?.map((a) => a.templateAttribute.name) ||
        [],
    ),
  )

  if (loading) {
    return <LoadingBox />
  }

  if (!integration && !loading) {
    return <MissingIntegration />
  }

  if (product && !templateIntegration) {
    return (
      <MissingTemplateIntegration
        templateId={product.product.templateId}
        channelName={IntegrationName.CSV}
      />
    )
  }

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <ListUnlistButtons
          list={list}
          hasProductIntegration={!!productIntegration}
          product={product}
          onList={handleListProduct}
          onUnlist={handleUnlistProduct}
        />
      </Grid>
      <Grid item xs={12}>
        <ListSyncComponent
          list={list}
          sync={sync}
          syncQuantity={syncQuantity}
          onListChange={setList}
          onSyncChange={setSync}
          onSyncQuantityChange={setSyncQuantity}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography level="title-lg"> Spreadsheets</Typography>
          </Grid>

          {spreadsheetIntegrations.map((csvSpreadsheetIntegration) => {
            const spreadsheetId = csvSpreadsheetIntegration.spreadsheetId
            const sheetIntegrations =
              csvSpreadsheetIntegration.csvSheetIntegrations

            return (
              <Grid item xs={12} key={csvSpreadsheetIntegration.id}>
                <AccordionGroup size="lg" variant="outlined">
                  <Accordion>
                    <AccordionSummary>
                      <ListItemContent sx={{ p: 1 }}>
                        <Typography level="title-lg" component="div">
                          {csvSpreadsheetIntegration.name}
                          {spreadsheetId ? (
                            <OpenInNewIcon
                              fontSize="small"
                              sx={{ opacity: 0.5, ml: 0.5, pt: 0.3 }}
                              onClick={(e) => {
                                e.stopPropagation()
                                window
                                  ?.open(
                                    `https://docs.google.com/spreadsheets/d/${spreadsheetId}/edit`,
                                    '_blank',
                                  )
                                  ?.focus()
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </Typography>
                        <Typography level="body-xs">{spreadsheetId}</Typography>
                      </ListItemContent>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        justifyContent="center"
                        spacing={4}
                        sx={{ p: 2 }}
                      >
                        <Grid item xs={12}>
                          <Tabs
                            defaultValue={
                              csvSpreadsheetIntegration
                                ?.csvSheetIntegrations?.[0]?.sheetTitle
                            }
                            size="lg"
                            variant="outlined"
                          >
                            <TabList
                              sx={{
                                overflow: 'auto',
                                scrollSnapType: 'x mandatory',
                                '&::-webkit-scrollbar': {
                                  display: 'none',
                                },
                              }}
                            >
                              {sheetIntegrations?.map((csvSheetIntegration) => {
                                const sheetTitle =
                                  csvSheetIntegration.sheetTitle

                                return (
                                  <Tab
                                    variant="plain"
                                    disableIndicator
                                    key={sheetTitle}
                                    value={sheetTitle}
                                  >
                                    <Typography level="title-md">
                                      {sheetTitle}
                                    </Typography>
                                  </Tab>
                                )
                              })}
                            </TabList>
                            {sheetIntegrations?.map((sheetIntegration) => {
                              const sheetTitle = sheetIntegration.sheetTitle
                              const headers = sheetIntegration.headers

                              return (
                                <TabPanel key={sheetTitle} value={sheetTitle}>
                                  <Grid
                                    container
                                    justifyContent="center"
                                    spacing={2}
                                  >
                                    <Grid item xs={12}>
                                      <Grid
                                        container
                                        justifyContent="center"
                                        spacing={2}
                                      >
                                        <Grid item xs={12}>
                                          <Typography level="title-lg">
                                            Headers
                                          </Typography>
                                        </Grid>
                                        {/* {headers?.length ? (
                                          headers.map((header) => {
                                            return header.options ? (
                                              <Grid
                                                item
                                                xs={12}
                                                key={header.id + header.cellId}
                                              >
                                                <Grid
                                                  container
                                                  justifyContent="center"
                                                  spacing={1}
                                                >
                                                  <Grid item xs={9}>
                                                    <NamedInput
                                                      name={header.name}
                                                      value={header.value}
                                                      nameLabel={
                                                        'cell ' + header.cellId
                                                      }
                                                      fullWidth
                                                      placeholder="Value"
                                                      onChange={(e) =>
                                                        handleSheetHeaderChange(
                                                          spreadsheetId,
                                                          sheetTitle,
                                                          header.cellId,
                                                          e,
                                                          header.name,
                                                        )
                                                      }
                                                      onNameChange={(e) =>
                                                        handleSheetHeaderChange(
                                                          spreadsheetId,
                                                          sheetTitle,
                                                          header.cellId,
                                                          header.value,
                                                          e,
                                                        )
                                                      }
                                                      endDecorator={
                                                        <VerticalIconMenu
                                                          size="small"
                                                          options={['Remove']}
                                                          onClick={(option) =>
                                                            onMenuClick(
                                                              option,
                                                              spreadsheetId,
                                                              sheetTitle,
                                                              header.cellId,
                                                            )
                                                          }
                                                        />
                                                      }
                                                    />
                                                  </Grid>
                                                  <Grid item xs={3}>
                                                    <SingleSelect
                                                      placeholder={
                                                        header?.strict
                                                          ? 'Possible Values'
                                                          : 'Example Values'
                                                      }
                                                      value={''}
                                                      freeSolo
                                                      addValues={true}
                                                      onAddValue={(v) =>
                                                        handleAddSheetHeaderOption(
                                                          spreadsheetId,
                                                          sheetTitle,
                                                          header.cellId,
                                                          v,
                                                        )
                                                      }
                                                      options={
                                                        header.options.map(
                                                          (o) => o.option || '',
                                                        ) || []
                                                      }
                                                      endDecorator={
                                                        <VerticalIconMenu
                                                          size="small"
                                                          options={[
                                                            'Remove Options',
                                                            'Make Strict',
                                                            'Make Unstrict',
                                                          ]}
                                                          onClick={(option) =>
                                                            onMenuClick(
                                                              option,
                                                              spreadsheetId,
                                                              sheetTitle,
                                                              header.cellId,
                                                            )
                                                          }
                                                        />
                                                      }
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            ) : (
                                              <Grid
                                                item
                                                xs={12}
                                                key={header.id + header.cellId}
                                              >
                                                <NamedInput
                                                  name={header.name}
                                                  value={header.value}
                                                  nameLabel={
                                                    'cell ' + header.cellId
                                                  }
                                                  fullWidth
                                                  placeholder="Value"
                                                  onChange={(e) =>
                                                    handleSheetHeaderChange(
                                                      spreadsheetId,
                                                      sheetTitle,
                                                      header.cellId,
                                                      e,
                                                      header.name,
                                                    )
                                                  }
                                                  onNameChange={(e) =>
                                                    handleSheetHeaderChange(
                                                      spreadsheetId,
                                                      sheetTitle,
                                                      header.cellId,
                                                      header.value,
                                                      e,
                                                    )
                                                  }
                                                  endDecorator={
                                                    <VerticalIconMenu
                                                      size="small"
                                                      options={[
                                                        'Add Options',
                                                        'Remove',
                                                      ]}
                                                      onClick={(option) =>
                                                        onMenuClick(
                                                          option,
                                                          spreadsheetId,
                                                          sheetTitle,
                                                          header.cellId,
                                                        )
                                                      }
                                                    />
                                                  }
                                                />
                                              </Grid>
                                            )
                                          })
                                        ) : (
                                          <Grid item xs={12}>
                                            <Box p={1}>
                                              <Typography level="body-sm">
                                                No Sheet Headers
                                              </Typography>
                                            </Box>
                                          </Grid>
                                        )} */}

                                        {headers?.length ? (
                                          headers?.map((header) => {
                                            const templateHeaderIntegration =
                                              templateHeaderIntegrations.find(
                                                (t) =>
                                                  t.csvSheetHeaderIntegrationId ===
                                                  header.id,
                                              )

                                            const productHeaderIntegration =
                                              productHeaderIntegrations.find(
                                                (t) =>
                                                  t.csvSheetHeaderIntegrationId ===
                                                  header.id,
                                              )

                                            const value =
                                              productHeaderIntegration?.value ||
                                              templateHeaderIntegration?.value

                                            const attributeName =
                                              extractAttributeName(value)

                                            let options: string[] = []
                                            let warningText = ''
                                            let errorText = ''
                                            if (header.options?.length) {
                                              const attribute =
                                                template?.attributes.find(
                                                  (a) =>
                                                    a.attribute.name ===
                                                    attributeName,
                                                )
                                              const attrOpts =
                                                attribute?.attributeOptions.map(
                                                  (o) => o.value,
                                                )

                                              const productValue =
                                                product?.attributes.find(
                                                  (a) =>
                                                    a.templateAttribute.name ===
                                                    attributeName,
                                                )?.attribute?.value || ''

                                              options =
                                                header?.options
                                                  ?.map((o) => o.option)
                                                  .filter(isDefined) || []

                                              const optionsNotInOptions =
                                                !!options?.length &&
                                                !!attrOpts?.length &&
                                                !options.some((o) =>
                                                  attrOpts.includes(o),
                                                )

                                              if (optionsNotInOptions) {
                                                warningText =
                                                  'No matching options'
                                              }

                                              const valueInOptions =
                                                options.some(
                                                  (o) => productValue === o,
                                                )

                                              const valueNotValid =
                                                header.strict &&
                                                !!options?.length &&
                                                !valueInOptions &&
                                                !!productValue

                                              const valueNeeded =
                                                header.strict &&
                                                attributeName &&
                                                !productValue

                                              const valueNotInOptions =
                                                !header.strict &&
                                                !!options?.length &&
                                                !valueInOptions &&
                                                !!productValue

                                              if (valueNeeded) {
                                                errorText = `${attributeName} missing value`
                                              }
                                              if (valueNotValid) {
                                                errorText = `'${productValue}' not allowed in options`
                                              }
                                              if (valueNotInOptions) {
                                                warningText = `'${productValue}' not in options`
                                              }

                                              if (value) {
                                                console.log(attrOpts)
                                                console.log(value)
                                                console.log(productValue)
                                                console.log(
                                                  extractAttributeName(value),
                                                )
                                              }

                                              return (
                                                <Grid
                                                  item
                                                  xs={12}
                                                  key={header.id}
                                                >
                                                  <Grid container spacing={2}>
                                                    <Grid item xs={8}>
                                                      <AttributeSelect
                                                        value={value || ''}
                                                        label="Value"
                                                        name={header.name}
                                                        nameLabel={
                                                          'column ' +
                                                          header.cellId
                                                        }
                                                        nameEditable={true}
                                                        nameEditDisabled={true}
                                                        onChange={(v) =>
                                                          product
                                                            ? handleProductHeaderChange(
                                                                header,
                                                                v,
                                                              )
                                                            : handleTemplateHeaderChange(
                                                                header,
                                                                v,
                                                              )
                                                        }
                                                        attributeNames={
                                                          attributeNames
                                                        }
                                                      />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                      <SingleSelect
                                                        placeholder={
                                                          header.strict
                                                            ? 'Possible Options'
                                                            : 'Example Options'
                                                        }
                                                        value={''}
                                                        onChange={() => {
                                                          // no-op
                                                        }}
                                                        options={options}
                                                        warningText={
                                                          warningText
                                                        }
                                                        errorText={errorText}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              )
                                            }

                                            return (
                                              <Grid
                                                item
                                                xs={12}
                                                key={header.id}
                                              >
                                                <AttributeSelect
                                                  value={value || ''}
                                                  label="Value"
                                                  name={header.name}
                                                  nameLabel={
                                                    'column ' + header.cellId
                                                  }
                                                  nameEditable={true}
                                                  nameEditDisabled={true}
                                                  onChange={(v) =>
                                                    product
                                                      ? handleProductHeaderChange(
                                                          header,
                                                          v,
                                                        )
                                                      : handleTemplateHeaderChange(
                                                          header,
                                                          v,
                                                        )
                                                  }
                                                  attributeNames={
                                                    attributeNames
                                                  }
                                                />
                                              </Grid>
                                            )
                                          })
                                        ) : (
                                          <>
                                            <Typography level="body-sm">
                                              No headers. Update this{' '}
                                              <Link to="/integrations">
                                                Integration
                                              </Link>
                                            </Typography>
                                          </>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </TabPanel>
                              )
                            })}
                          </Tabs>
                        </Grid>

                        <Grid item xs={12}>
                          {/* <Grid container justifyContent="center" spacing={2}>
                            <Grid item xs={12}>
                              <Button
                                sx={{ float: 'right' }}
                                variant="solid"
                                onClick={() => saveToSheet(spreadsheetId)}
                                endDecorator={
                                  saveLoading ? <CircularProgress /> : null
                                }
                              >
                                Update
                              </Button>
                            </Grid>
                          </Grid> */}
                          {/* <Button
                                      sx={{ float: 'left' }}
                                      color="danger"
                                      variant="plain"
                                    >
                                      Close
                                    </Button> */}
                        </Grid>
                      </Grid>

                      {/* <Grid
                        container
                        justifyContent="center"
                        spacing={1}
                        sx={{ px: 4, py: 2 }}
                      > */}
                      {/* <Grid item xs={12}>
                          <Typography level="title-md">
                            <strong>Headers</strong>
                          </Typography>
                        </Grid> */}
                      {/* {csvSpreadsheetHeaderIntegrations?.length ? (
                          csvSpreadsheetHeaderIntegrations?.map((header) => {
                            const templateHeaderIntegration =
                              templateHeaderIntegrations.find(
                                (t) =>
                                  t.csvSheetHeaderIntegrationId === header.id,
                              )

                            const productHeaderIntegration =
                              productHeaderIntegrations.find(
                                (t) =>
                                  t.csvSheetHeaderIntegrationId === header.id,
                              )

                            const value =
                              productHeaderIntegration?.value ||
                              templateHeaderIntegration?.value

                            let options: string[] = []
                            let warningText = ''
                            let errorText = ''
                            if (header.options?.length) {
                              const attrOpts = template?.attributes
                                .find(
                                  (a) =>
                                    a.attribute.name ===
                                    extractAttributeName(value),
                                )
                                ?.attributeOptions.map((o) => o.value)

                              const productValue =
                                product?.attributes.find(
                                  (a) =>
                                    a.templateAttribute.name ===
                                    extractAttributeName(value),
                                )?.attribute?.value || ''

                              options =
                                header?.options
                                  ?.map((o) => o.option)
                                  .filter(isDefined) || []

                              const optionsNotInOptions =
                                !!options?.length &&
                                !!attrOpts?.length &&
                                !options.some((o) => attrOpts.includes(o))

                              if (optionsNotInOptions) {
                                warningText = 'No matching options'
                              }

                              const valueInOptions = options.some(
                                (o) => productValue === o,
                              )

                              const valueNotValid =
                                header.strict &&
                                !!options?.length &&
                                !valueInOptions &&
                                !!productValue

                              const valueNotInOptions =
                                !header.strict &&
                                !!options?.length &&
                                !valueInOptions &&
                                !!productValue
                              if (valueNotValid) {
                                errorText = `'${productValue}' not allowed`
                              }
                              if (valueNotInOptions) {
                                warningText = `'${productValue}' not in options`
                              }

                              if (value) {
                                console.log(value)
                                console.log(productValue)
                                console.log(extractAttributeName(value))
                              }

                              return (
                                <Grid item xs={12} key={header.id}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                      <SingleSelect
                                        value={getHeaderValue(header)}
                                        onChange={(v) =>
                                          product
                                            ? handleProductHeaderChange(
                                                header,
                                                v,
                                              )
                                            : handleTemplateHeaderChange(
                                                header,
                                                v,
                                              )
                                        }
                                        freeSolo={true}
                                        options={Object.keys(
                                          PRODUCT_FIELD_NAMES,
                                        )
                                          .concat(
                                            template?.attributes?.map(
                                              (a) => a.attribute.name,
                                            ) ||
                                              product?.attributes?.map(
                                                (a) => a.templateAttribute.name,
                                              ) ||
                                              [],
                                          )
                                          ?.map((v) => getAttributeTag(v))}
                                        label={
                                          <Typography noWrap level="title-sm">
                                            <strong>{header.headerName}</strong>
                                            <Typography level="body-xs">
                                              {' cell '}
                                              {header.cellId}
                                            </Typography>
                                          </Typography>
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <SingleSelect
                                        label={
                                          <Typography level="body-sm">
                                            {header.strict
                                              ? 'Possible Options'
                                              : 'Example Options'}
                                          </Typography>
                                        }
                                        value={''}
                                        options={options}
                                        warningText={warningText}
                                        errorText={errorText}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            }

                            return (
                              <Grid item xs={12} key={header.id}>
                                <SingleSelect
                                  value={getHeaderValue(header)}
                                  onChange={(v) =>
                                    product
                                      ? handleProductHeaderChange(header, v)
                                      : handleTemplateHeaderChange(header, v)
                                  }
                                  freeSolo={true}
                                  options={Object.keys(PRODUCT_FIELD_NAMES)
                                    .concat(
                                      template?.attributes.map(
                                        (a) => a.attribute.name,
                                      ) || [],
                                    )
                                    ?.map((v) => getAttributeTag(v))}
                                  label={
                                    <Typography noWrap level="title-sm">
                                      <strong>{header.headerName}</strong>
                                      <Typography level="body-xs">
                                        {' cell '}
                                        {header.cellId}
                                      </Typography>
                                    </Typography>
                                  }
                                />
                              </Grid>
                            )
                          })
                        ) : (
                          <>
                            <Typography level="body-sm">
                              No headers. Update this{' '}
                              <Link to="/integrations">Integration</Link>
                            </Typography>
                          </>
                        )} */}
                      {/* </Grid> */}
                    </AccordionDetails>
                  </Accordion>
                </AccordionGroup>
              </Grid>
            )
          })}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button variant="solid" onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
