import './styles.css'
import React from 'react'
import CharacterCount from '@tiptap/extension-character-count'
import Document from '@tiptap/extension-document'
import Mention from '@tiptap/extension-mention'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import Text from '@tiptap/extension-text'
import { EditorContent, useEditor } from '@tiptap/react'
import suggestion from './suggestion'

export default function TipTapEditor({
  value,
  mentions,
  oneLine,
  placeholder,
  required,
  disabled,
  onChange,
  onBlur,
}: {
  value: string | undefined
  mentions?: string[]
  oneLine?: boolean
  placeholder?: string
  required?: boolean
  disabled?: boolean
  onChange: (value: string) => void
  onBlur?: () => void
}): JSX.Element {
  const suggestions = suggestion(mentions || [])

  const parseTextToEditor = (rawString: string) => {
    let parsedHtml = rawString
    if (oneLine) {
      parsedHtml = parsedHtml.replaceAll(/\n/gim, '')
    }

    parsedHtml = parsedHtml.replaceAll(/^(.+?)$/gim, (i, match) => {
      return `<p>${match}</p>`
    })

    parsedHtml = parsedHtml.replaceAll(/^\n/gim, (i, match) => {
      return `<p></p>`
    })
    // Parse mentions
    parsedHtml = parsedHtml.replaceAll(/\{\{\s*(.*?)\s*\}\}/gim, (i, match) => {
      return `<span data-type="mention" data-id="${match}"></span>`
    })

    return parsedHtml
  }

  const parseHtmlToEditor = (rawString: string) => {
    // Parse mentions
    let parsedHtml = rawString.replaceAll(
      /<span class="mention">@(.*?)<\/span>/gim,
      (i, match) => {
        return `{{ ${match} }}`
      },
    )
    // Parse newlines into paragraphs
    parsedHtml = parsedHtml.replaceAll(/<p>(.*?)<\/p>/gim, (i, match) => {
      return `${match}\n`
    })
    parsedHtml = parsedHtml.replaceAll(/<p><\/p>/gim, (i, match) => {
      return `\n`
    })

    return parsedHtml.trim()
  }

  let editorClassName = 'prose'
  if (!oneLine) {
    editorClassName += ' prose-area'
  }

  // if (error) {
  //   editorClassName += ' prose-error'
  // }

  const editor = useEditor({
    editorProps: {
      attributes: {
        class: editorClassName,
      },
    },
    onUpdate({ editor }) {
      onChange(parseHtmlToEditor(editor.getHTML()))
    },
    onBlur() {
      onBlur?.()
    },
    extensions: [
      Document.extend({
        content: oneLine ? 'block' : undefined,
      }),
      Placeholder.configure({
        placeholder: placeholder,
      }),
      Paragraph,
      Text,
      CharacterCount.configure({}),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        renderText: (props) => {
          // eslint-disable-next-line react/prop-types
          return props?.node?.attrs?.id ? `{{ ${props.node.attrs.id} }} ` : ''
        },
        suggestion: suggestions,
      }),
    ],
    editable: !disabled,
    content: parseTextToEditor(value || ''),
  })

  // const editor1 = useEditor({
  //   extensions: [
  //     Document,
  //     Paragraph,
  //     Text,
  //     CharacterCount.configure({}),
  //     Mention.configure({
  //       HTMLAttributes: {
  //         class: 'mention',
  //       },
  //       // suggestion: suggestion(['a']),
  //     }),
  //   ],
  //   content: `
  //     <p>
  //       What do you all think about the new <span data-type="mention" data-id="Winona Ryder"></span> movie?
  //     </p>
  //   `,
  // })

  return (
    <div>
      <EditorContent editor={editor} />
      {/* <EditorContent editor={editor1}/> */}
    </div>
  )
}
