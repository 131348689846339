import React from 'react'
import Grid from '@mui/material/Grid'
import Autocomplete from '@mui/joy/Autocomplete'
import {
  getAttributeName,
  getAttributeTag,
  isLike,
} from '../../../../utils/functions'
import {
  GetProductTemplateAttribute,
  ProductTemplateAttributeInput,
  updateProductTemplateAttributes,
} from '../../../../api/product'
import { DEFAULT_ATTRIBUTE_TYPE } from '../../../../utils/constants'
import Box from '@mui/material/Box'
import { AspectIntegrationInput } from './EbayTemplateIntegrationComponent'
import CodeInline from '../../../common/CodeInline'
import AutocompleteOption from '@mui/joy/AutocompleteOption'
import ListItemContent from '@mui/joy/ListItemContent'
import AttributeInline from '../../../common/AttributeInline'
import Typography from '@mui/joy/Typography'
import AddIcon from '@mui/icons-material/Add'

interface AttributeSelectProps {
  aspectName: string
  aspectIntegration: AspectIntegrationInput | undefined
  templateAttributes: GetProductTemplateAttribute[]
  required?: boolean
  multiple?: boolean
  onBlur?: () => void
  onChange: (value: string | undefined) => void
  onUpdate?: () => void
}

export default function AttributeSelect({
  aspectName,
  aspectIntegration,
  templateAttributes,
  required,
  multiple,
  onBlur,
  onChange,
  onUpdate,
}: AttributeSelectProps): JSX.Element {
  const addTemplateAttributeName = templateAttributes.find((a) =>
    isLike(a.attribute.name, aspectName),
  )
    ? undefined
    : aspectName
  const addTemplateAttributeTag = getAttributeTag(
    addTemplateAttributeName || '',
  )

  const options: string[] = templateAttributes.map((templateAttribute) =>
    getAttributeTag(templateAttribute.attribute.name),
  )

  if (addTemplateAttributeTag) {
    options.unshift(addTemplateAttributeTag)
  }

  const handleAddTemplateAttribute = async () => {
    // Get template id from first template attribute
    const templateId = templateAttributes?.[0]?.attribute.templateId
    const newIndex = templateAttributes.length // length+1
    if (!addTemplateAttributeName || !templateId) return
    // add attribute
    const addTemplateAttr: ProductTemplateAttributeInput = {
      attribute: {
        name: addTemplateAttributeName,
        type: DEFAULT_ATTRIBUTE_TYPE,
        index: newIndex,
      },
      attributeOptions: [],
    }
    const res = await updateProductTemplateAttributes(templateId, [
      addTemplateAttr,
    ])
    if (res.success && res.data) {
      console.log('success')
    }
    onUpdate?.()
  }

  return multiple ? (
    <Autocomplete
      clearOnEscape
      autoHighlight={true}
      freeSolo={true}
      options={options}
      multiple={multiple}
      value={aspectIntegration?.value?.split(' ') || []}
      onBlur={onBlur}
      required={required}
      placeholder={'Attribute'}
      onChange={(e, newValue) => {
        if (Array.isArray(newValue)) {
          onChange(newValue?.join(', ') || undefined)
          return
        }
        onChange(newValue || undefined)
      }}
      getOptionLabel={(option) => option}
      renderOption={(props, option) => {
        return (
          <Box {...props} component="li">
            {option === addTemplateAttributeTag ? (
              <Grid
                container
                justifyContent="space-between"
                onClick={handleAddTemplateAttribute}
              >
                <Grid item>
                  <span>
                    <strong>Add to Template:</strong>
                    {'  '}
                    <CodeInline>{option}</CodeInline>
                  </span>
                </Grid>
              </Grid>
            ) : (
              <CodeInline>{option}</CodeInline>
            )}
          </Box>
        )
      }}
    ></Autocomplete>
  ) : (
    <Autocomplete
      clearOnEscape
      autoHighlight={true}
      freeSolo={true}
      options={options}
      value={aspectIntegration?.value || ''}
      onBlur={onBlur}
      required={required}
      onChange={(e, newValue) => {
        console.log(newValue)
        onChange(newValue ? newValue : undefined)
      }}
      placeholder="Attributes"
      getOptionLabel={(option) => option}
      renderOption={(props, option) => {
        return (
          <AutocompleteOption
            {...props}
            key={option}
            sx={{ py: '3px', px: '6px' }}
          >
            <ListItemContent>
              {option === addTemplateAttributeTag ? (
                <Grid
                  container
                  justifyContent="space-between"
                  onClick={handleAddTemplateAttribute}
                >
                  <Grid item xs={12}>
                    <span>
                      <Typography
                        level="title-sm"
                        startDecorator={<AddIcon fontSize="small"></AddIcon>}
                      >
                        <strong>Add to Template:</strong>
                      </Typography>
                      {'  '}
                      <AttributeInline fullWidth>
                        {getAttributeName(option)}
                      </AttributeInline>
                    </span>
                  </Grid>
                </Grid>
              ) : (
                <AttributeInline fullWidth>
                  {getAttributeName(option)}
                </AttributeInline>
              )}
            </ListItemContent>
          </AutocompleteOption>
        )
      }}
    ></Autocomplete>
  )
}
