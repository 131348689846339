import React from 'react'
import Grid from '@mui/material/Grid'
import Switch from '@mui/joy/Switch'
import Typography from '@mui/joy/Typography'
import Tooltip from '@mui/material/Tooltip'

interface ListSyncComponentProps {
  list: boolean
  sync: boolean
  syncQuantity: boolean
  onListChange: (change: boolean) => void
  onSyncChange: (change: boolean) => void
  onSyncQuantityChange: (change: boolean) => void
}

export default function ListSyncComponent({
  list,
  sync,
  syncQuantity,
  onListChange,
  onSyncChange,
  onSyncQuantityChange,
}: ListSyncComponentProps): JSX.Element {
  const handleListChange = () => {
    if (list) {
      onSyncChange(false)
      onSyncQuantityChange(false)
    }
    onListChange(!list)
  }

  const handleSyncChange = () => {
    if (!list) return
    if (sync) {
      onSyncQuantityChange(false)
    }
    onSyncChange(!sync)
  }

  const handleSyncQuantityChange = () => {
    if (list && sync) {
      onSyncQuantityChange(!syncQuantity)
    }
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography level="title-lg">List and Sync</Typography>
      </Grid>

      <Grid item>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Typography
              component="label"
              endDecorator={
                <Switch checked={list} size="sm" onChange={handleListChange} />
              }
            >
              List
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={!list ? 'List  must be set.' : ''}>
              <Typography
                component="label"
                endDecorator={
                  <Switch
                    checked={sync}
                    size="sm"
                    onChange={handleSyncChange}
                  />
                }
              >
                Sync
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={!list || !sync ? 'List and Sync must be set.' : ''}>
              <Typography
                component="label"
                endDecorator={
                  <Switch
                    checked={syncQuantity}
                    size="sm"
                    onChange={handleSyncQuantityChange}
                  />
                }
              >
                Sync Quantity
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
