import * as React from 'react'
import Grid from '@mui/material/Grid'
import FloatLabelInput from './FloatLabelInput'
import { SxProps, Theme } from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/joy/Autocomplete'
import { useState } from 'react'
import AutocompleteOption from '@mui/joy/AutocompleteOption'
import ListItemDecorator from '@mui/joy/ListItemDecorator'
import Add from '@mui/icons-material/Add'
import Typography from '@mui/joy/Typography'

const filter = createFilterOptions<string>()
export default function NamedSingleSelect({
  value,
  name,
  nameEditable,
  onChange,
  onNameChange,
  onClick,
  onBlur,
  onNameBlur,
  onFocus,
  onKeyDown,
  label,
  nameLabel,
  placeholder,
  namePlaceholder,
  type,
  size,
  fullWidth,
  required,
  disabled,
  readOnly,
  errorText,
  helperText,
  autoComplete,
  autoCapitalize,
  autoCorrect,
  format,
  endDecorator,
  sx,
  xs,
  sm,
  md,
  lg,
  options,
  freeSolo,
  addValues,
  defaultOption,
  warningText,
  onAddValue,
}: {
  value: string
  name?: string
  nameEditable?: false
  onNameChange?: (value: string) => void
  onChange?: (value: string) => void
  onBlur?: (e?: React.FocusEvent<HTMLInputElement, Element>) => void
  onNameBlur?: (e?: React.FocusEvent<HTMLInputElement, Element>) => void
  onClick?: () => void
  onFocus?: () => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  label?: string | JSX.Element
  nameLabel?: string | JSX.Element
  placeholder?: string
  namePlaceholder?: string
  type?: React.HTMLInputTypeAttribute
  size?: 'sm' | 'md' | 'lg'
  fullWidth?: boolean
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  errorText?: string
  helperText?: string
  autoComplete?: string
  autoCapitalize?: boolean
  autoCorrect?: boolean
  format?: 'USD'
  endDecorator?: React.ReactNode
  sx?: SxProps<Theme> | undefined
  xs?: [number, number]
  sm?: [number, number]
  md?: [number, number]
  lg?: [number, number]

  options: string[]
  freeSolo?: boolean
  addValues?: boolean
  defaultOption?: boolean
  warningText?: string
  onAddValue?: (value: string) => void
}): JSX.Element {
  const [localValue, setLocalValue] = useState<string>(value)

  const handleChange = (newValue: string) => {
    setLocalValue(newValue)
    onChange?.(newValue)
  }

  const allOptions = defaultOption !== false ? [''].concat(options) : options

  return (
    <Grid container spacing={1} sx={sx}>
      <Grid
        item
        xs={xs?.[0] || 12}
        sm={sm?.[0] || 12}
        md={md?.[0] || 3}
        lg={lg?.[0] || 2}
      >
        {nameEditable ? (
          <FloatLabelInput
            value={name}
            onChange={onNameChange}
            onBlur={onNameBlur}
            label={nameLabel}
            placeholder={namePlaceholder}
            type={type}
            size={size}
            fullWidth={fullWidth}
            required={required}
            disabled={disabled}
            readOnly={readOnly}
            errorText={errorText}
            helperText={helperText}
            autoComplete={autoComplete}
            autoCapitalize={autoCapitalize}
            autoCorrect={autoCorrect}
            format={format}
          />
        ) : (
          <Typography level="title-md" sx={{ pt: '0.5em' }}>
            <strong>{name}</strong>
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={xs?.[1] || 12}
        sm={sm?.[1] || 12}
        md={md?.[1] || 9}
        lg={lg?.[1] || 10}
      >
        <Autocomplete
          value={value}
          inputValue={localValue}
          onInputChange={(e, v) => setLocalValue(v)}
          options={allOptions}
          freeSolo={freeSolo}
          disabled={disabled}
          autoHighlight={true}
          required={required}
          placeholder={placeholder}
          // onChange={(e, value) => {
          //   if (value && value.startsWith('Add "')) {
          //     setLocalValue('')
          //     const addValue = new RegExp(/Add "(.*)"/gim).exec(value)?.[1]
          //     if (!addValue) return
          //     onAddValue?.(addValue)
          //   } else {
          //     //
          //     handleChange(value || '')
          //   }
          // }}
          onChange={(e, value) => {
            if (addValues) {
              if (value && value.startsWith('Add "')) {
                const addValue = new RegExp(/Add "(.*)"/gim).exec(value)?.[1]
                if (!addValue) return
                onAddValue?.(addValue)
                handleChange(addValue)
              } else {
                //
                handleChange(value || '')
              }
            } else {
              handleChange(value || '')
            }
          }}
          filterOptions={
            addValues
              ? (options, params) => {
                  const filtered = filter(options, params)
                  if (params.inputValue !== '' && filtered.length === 0) {
                    filtered.push(`Add "${params.inputValue}"`)
                  }
                  return filtered
                }
              : undefined
          }
          onSelect={(e) => {
            const value = (e.target as HTMLInputElement).value
            if (value.startsWith('Add "')) {
              const addValue = new RegExp(/Add "(.*)"/gim).exec(value)?.[1]
              if (!addValue) return
              setLocalValue(addValue)
            }
          }}
          renderOption={
            addValues
              ? (props, option, state) => (
                  <AutocompleteOption
                    {...props}
                    onClick={(e) => {
                      if (option?.startsWith('Add "')) {
                        e.stopPropagation()
                        if (state.inputValue) {
                          onAddValue?.(state.inputValue)
                        }
                      } else {
                        handleChange(option)
                      }
                    }}
                  >
                    {option?.startsWith('Add "') && (
                      <ListItemDecorator>
                        <Add />
                      </ListItemDecorator>
                    )}
                    {option}
                  </AutocompleteOption>
                )
              : undefined
          }
          autoComplete
          openOnFocus
          clearOnEscape
          multiple={false}
          error={!!errorText}
          color={warningText ? 'warning' : undefined}
          isOptionEqualToValue={(option, value) => {
            return option === value || option === `Add "${value}"`
          }}
          endDecorator={endDecorator}
        ></Autocomplete>
      </Grid>
    </Grid>
  )
}
