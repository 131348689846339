import React, { useEffect, useState } from 'react'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import Grid from '@mui/material/Grid'
import {
  CategorySuggestion,
  CategoryTree,
  CategoryTreeNode,
} from '../../../../types/Ebay.types'
import { err, log } from '../../../../utils/functions'
import {
  getEbayCategories,
  getEbayCategorySuggestions,
} from '../../../../api/product'
import Component from '../../../common/Component'
import Button from '@mui/joy/Button'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Stack from '@mui/material/Stack'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import Typography from '@mui/joy/Typography'
import LabelInput from '../../../common/LabelInput'
import CircularProgress from '@mui/joy/CircularProgress'

export type EbayCategory = {
  id: number
  name: string
}
interface EbayCategoriesProps {
  category: EbayCategory | undefined
  categoryVersion: number
  onChange: (
    categoryVersion: number,
    category: EbayCategory | undefined,
  ) => void
}

export default function EbayCategories({
  category,
  onChange,
}: EbayCategoriesProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true)
  const [categories, setCategories] = useState<CategoryTree | undefined>()
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [categorySuggestions, setCategorySuggestions] = useState<
    CategorySuggestion[]
  >([])
  const [categorySuggestionQuery, setCategorySuggestionQuery] = useState('')
  const [viewMenu, setViewMenu] = useState<boolean>(false)

  const handleViewMenu = () => {
    setViewMenu(!viewMenu)
  }

  const handleQuery = () => {
    setLoading(true)
    setViewMenu(false) // do not show menu
    categorySuggestionQuery &&
      getEbayCategorySuggestions(categorySuggestionQuery)
        .then((res) => {
          setLoading(false)
          if (res.success && res.data) {
            setCategorySuggestions(res.data.categorySuggestions || [])
          } else {
            handleAlert(setAlert, res, 'Added service to Dashboard.')
          }
        })
        .catch((e) => log(e))
  }

  useEffect(() => {
    // Do not load if categories is set
    if (category) return
    getEbayCategories()
      .then((res) => {
        setLoading(false)
        if (res.success) {
          setCategories(res.data)
        } else {
          handleAlert(setAlert, res, 'Added service to Dashboard.')
        }
      })
      .catch((e) => err(e))
  }, [category])

  if (!categories && !loading) {
    return <Typography>Must Integrate with Ebay for this feature.</Typography>
  }

  if (loading) {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography>
          <Typography component="span" level="title-lg">
            Ebay Category
          </Typography>
          <Typography sx={{ ml: 1 }} component="span" level="body-sm">
            {categories?.categoryTreeVersion
              ? `(v.${categories.categoryTreeVersion})`
              : null}
          </Typography>
        </Typography>
      </Grid>

      {category?.id && category.name ? (
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={11}>
              <LabelInput
                helperText={`(id: ${category.id})`}
                value={category.name}
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={1}>
              <Button onClick={() => onChange(0, undefined)}>Edit</Button>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      {(!category?.id || !category?.name) && categories && (
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                <Grid item xs={11}>
                  <LabelInput
                    placeholder="Search Ebay Categories"
                    value={categorySuggestionQuery}
                    onChange={(v) => setCategorySuggestionQuery(v)}
                    onKeyDown={(key) => {
                      if (key.code === 'Enter') {
                        handleQuery()
                      }
                    }}
                    disabled={loading}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="solid"
                    onClick={handleQuery}
                    disabled={loading}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={handleViewMenu}
                    sx={{ width: '120px' }}
                    disabled={loading}
                  >
                    {viewMenu ? 'View Search' : 'View Menu'}
                  </Button>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      )}
      {(!category?.id || !category?.name) &&
        ((!viewMenu && categorySuggestions.length) ||
          (viewMenu && categories)) && (
          <Grid item xs={12}>
            <Component>
              {viewMenu ? (
                <TreeView
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={{ height: '300px', flexGrow: 1, overflowY: 'auto' }}
                >
                  {categories?.rootCategoryNode ? (
                    <DisplayCategories
                      node={categories.rootCategoryNode}
                      onChange={(category) => {
                        onChange(
                          parseInt(categories.categoryTreeVersion),
                          category,
                        )
                      }}
                    />
                  ) : null}
                </TreeView>
              ) : (
                <TreeView>
                  {categorySuggestions?.map((suggestion) => {
                    const suggestionArry: string[] = [
                      suggestion.category.categoryName,
                    ]
                    suggestion.categoryTreeNodeAncestors.forEach((ancestor) => {
                      suggestionArry.unshift(ancestor.categoryName)
                    })
                    return (
                      <TreeItem
                        key={suggestion.category.categoryId}
                        nodeId={suggestion.category.categoryId}
                        label={
                          <div>
                            {suggestionArry.slice(0, -1).join(' > ')}
                            {' > '}
                            <strong>{suggestionArry.slice(-1)[0]}</strong>
                          </div>
                        }
                        onClick={() =>
                          categories?.categoryTreeVersion &&
                          onChange(parseInt(categories.categoryTreeVersion), {
                            id: parseInt(suggestion.category.categoryId),
                            name: suggestion.category.categoryName,
                          })
                        }
                        sx={{ padding: '0.33em' }}
                      />
                    )
                  }) || 'Search Categories...'}
                </TreeView>
              )}
            </Component>
          </Grid>
        )}

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}

function DisplayCategories({
  node,
  onChange,
}: {
  node: CategoryTreeNode
  onChange: (category: EbayCategory | undefined) => void
}): JSX.Element {
  return (
    <div>
      {node.childCategoryTreeNodes?.map((node) => (
        <TreeNode
          node={node}
          key={node.category.categoryId}
          onChange={onChange}
        />
      ))}
    </div>
  )
}

function TreeNode({
  node,
  onChange,
}: {
  node: CategoryTreeNode
  onChange: (category: EbayCategory | undefined) => void
}): JSX.Element {
  return (
    <TreeItem
      key={node.category.categoryId}
      nodeId={node.category.categoryId}
      label={node.category.categoryName}
      onClick={() =>
        node.leafCategoryTreeNode &&
        onChange({
          id: parseInt(node.category.categoryId),
          name: node.category.categoryName,
        })
      }
    >
      {node.childCategoryTreeNodes?.map((node) => (
        <TreeNode
          node={node}
          key={node.category.categoryId}
          onChange={onChange}
        />
      ))}
    </TreeItem>
  )
}

export function search(
  array: CategoryTreeNode[],
  is: (obj: CategoryTreeNode) => boolean,
): CategoryTreeNode | undefined {
  let found: CategoryTreeNode | undefined = undefined
  array.forEach((node) => {
    log(node.category.categoryName)
    if (found) return
    if (is(node)) {
      found = node
      return
    }
    if (!found && node.childCategoryTreeNodes?.length) {
      found = search(node.childCategoryTreeNodes, is)
    }
  })
  return found
}
