import React, { useEffect, useState } from 'react'
import Button from '@mui/joy/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import { Link, useHistory } from 'react-router-dom'
import Component from '../../components/common/Component'
import { err, isSuperAdmin, sha256 } from '../../utils/functions'
import Alert, { AlertInput } from '../../components/common/Alert'
import { isValidEmail } from '.'
import { login } from '../../api/account'
import { useContext } from 'react'
import AppContext, { JWT } from '../../app/AppContext'
import jwt from 'jsonwebtoken'
import LabelInput from '../../components/common/LabelInput'

export default function Login(): JSX.Element {
  const redirectTo =
    new URLSearchParams(window.location.search).get('redirectTo') || undefined

  const emailQuery =
    new URLSearchParams(window.location.search).get('email') || undefined
  const tokenQuery =
    new URLSearchParams(window.location.search).get('token') || undefined

  const history = useHistory()
  const { appReducer } = useContext(AppContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  useEffect(() => {
    console.log(emailQuery, tokenQuery)

    if (!emailQuery || !tokenQuery) return
    // decode uri
    const decodedEmailQuery = decodeURIComponent(emailQuery)
    const decodedPassQuery = decodeURIComponent(tokenQuery)
    console.log(decodedEmailQuery, decodedPassQuery)

    login(decodedEmailQuery, '', decodedPassQuery)
      .then((res) => {
        if (res.success) {
          handleSetJWT(res.data)
        } else {
          setAlert({
            open: true,
            severity: 'error',
            message: res.message,
          })
        }
      })
      .catch((e) => err(e))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailQuery, tokenQuery])

  const handleSubmit = async () => {
    const hashPass = await sha256(password)

    // check email and password again
    if (!isValidEmail(email)) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Invalid email.',
      })
    } else {
      const result = await login(email, hashPass)

      if (result.success) {
        handleSetJWT(result.data)
      } else {
        setAlert({
          open: true,
          severity: 'error',
          message: result.message,
        })
      }
    }
  }

  const handleSetJWT = (jwtString: string | undefined) => {
    if (!jwtString) return
    const jwtData = jwt.decode(jwtString) as JWT
    appReducer({ type: 'jwt', payload: jwtData })

    // If user has multiple clients, redir to select
    if (jwtData.clientIds?.length || isSuperAdmin(jwtData.roleId)) {
      history.push(
        '/selectClient' + (redirectTo ? `?redirectTo=${redirectTo}` : ''),
      )

      // No client push to admin to create org
    } else if (!jwtData.clientId && !jwtData.clientIds?.length) {
      history.push(redirectTo || '/admin')

      // Send to dashboard
    } else {
      history.push(redirectTo || '/dashboard')
    }
  }

  return (
    <div
      style={{
        height: '100vh',
        background:
          'linear-gradient(180deg, rgba(2,173,151,1) 0%, rgba(2,173,151,1) 51%, #00796b 100%)',
      }}
    >
      <Grid container justifyContent="center" spacing={0}>
        <Grid item xs={12} mt={12}>
          <Grid container justifyContent="center">
            <Grid item xs={11} md={10} lg={6}>
              <Component p={4}>
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <Typography level="h1" component="h1" fontSize={48}>
                    Sellmotto
                  </Typography>
                </Link>

                <Typography level="h4" color="neutral" mt={1}>
                  Log in to your Sellmotto Account
                </Typography>

                <Grid container justifyItems="center">
                  <Grid item xs={12}>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        void handleSubmit()
                      }}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        spacing={2}
                        sx={{ pt: 4 }}
                      >
                        <Grid item xs={12}>
                          <LabelInput
                            label="Email"
                            type="text"
                            size="lg"
                            fullWidth
                            value={email}
                            required
                            onChange={setEmail}
                            autoComplete="email"
                            autoCapitalize={false}
                            autoCorrect={false}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <LabelInput
                            label="Password"
                            type="password"
                            fullWidth
                            value={password}
                            required
                            onChange={setPassword}
                            size="lg"
                            autoCapitalize={false}
                            autoCorrect={false}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container justifyContent="flex-end">
                            <Button
                              size="lg"
                              variant="solid"
                              color="primary"
                              type="submit"
                              sx={{ cursor: 'pointer' }}
                            >
                              Log In
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                  <Grid item xs={12} pt={4} pb={1}>
                    <Typography level="body-lg">
                      New to Sellmotto?{' '}
                      <Link
                        to="/createAccount"
                        style={{ textDecoration: 'none' }}
                      >
                        <Typography>Sign Up</Typography>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Component>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} pt={1}>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item>
              <Link to="/forgotPassword" style={{ textDecoration: 'none' }}>
                <Typography sx={{ color: '#fff' }}>Forgot Password?</Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      />
    </div>
  )
}
