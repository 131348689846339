import React, { useState } from 'react'
import { GetIntegrationResult, IntegrationName } from '../../api/integration'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import Button from '@mui/joy/Button'
import Alert, { AlertInput } from '../../components/common/Alert'

export default function SquareIntegration({
  integration,
}: {
  integration: GetIntegrationResult<IntegrationName.SQUARE>
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleAppStoreClick = () => {
    if (!integration.oAuthUrl) return
    window.open(integration.oAuthUrl, '_blank')
  }

  return (
    <Grid container spacing={6}>
      {integration.integration?.shop ? (
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography level="title-lg">Store Details</Typography>
              <Typography level="body-sm">
                Store ID:{' '}
                {integration.integration?.shop || 'Store name not set.'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant="solid" onClick={handleAppStoreClick}>
                Open Store
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          {integration.authenticated ? (
            <>
              <Grid item xs={12}>
                <Typography level="title-lg">Authentication</Typography>
                <Typography level="body-xs">
                  Re-authorize by re-installing the the Sellmotto app from the
                  Square App Marketplace
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="solid" onClick={handleAppStoreClick}>
                  Re-Authorize
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Typography level="body-xs">
                  Authentication Last Updated:{' '}
                  {integration.integration?.updatedAt
                    ? new Date(
                        integration.integration?.updatedAt,
                      ).toLocaleString()
                    : 'Unknown'}
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography level="title-lg">Authentication</Typography>
                <Typography level="body-sm">
                  To authenticate app access, add the Sellmotto Square app to
                  your store.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="solid" onClick={handleAppStoreClick}>
                  Open
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
