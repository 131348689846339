import React, { useState } from 'react'
import { GetIntegrationResult, IntegrationName } from '../../api/integration'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import Button from '@mui/joy/Button'
import Alert, { AlertInput } from '../../components/common/Alert'

export default function CloverIntegration({
  integration,
}: {
  integration: GetIntegrationResult<IntegrationName.CLOVER>
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleAppStoreClick = () => {
    if (!integration.oAuthUrl) return
    window.open(integration.oAuthUrl, '_blank')
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {/* Authentication */}
        <Grid container justifyContent="center" spacing={3}>
          {integration.authenticated ? (
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <Typography level="title-lg">Merchant ID</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {integration.integration?.shop || 'Store name not set.'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {integration.authenticated ? (
            <>
              <Grid item xs={12}>
                <Typography level="title-lg">Authentication</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="center"
                  spacing={1}
                  direction="column"
                >
                  <Grid item>
                    <Button variant="solid" onClick={handleAppStoreClick}>
                      Re-Authorize
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography level="body-xs">
                      Authentication Last Updated:{' '}
                      {integration.integration?.updatedAt
                        ? new Date(
                            integration.integration?.updatedAt,
                          ).toLocaleString()
                        : 'Unknown'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography level="title-lg">Authentication</Typography>
                <Typography level="body-sm">
                  To authenticate app access, please authorize the Sellmotto app
                  on your Clover Store.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button variant="solid" onClick={handleAppStoreClick}>
                  Open
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
