import React, { useEffect, useState } from 'react'
import { GetIntegrationResult, IntegrationName } from '../../api/integration'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import Button from '@mui/joy/Button'
import Alert, { AlertInput } from '../../components/common/Alert'
import {
  ShopifyCustomCollection,
  ShopifyLocation,
} from '../../types/Shopify.types'
import {
  getShopifyCollections,
  getShopifyLocations,
} from '../../api/integrations/shopify'
import { err } from '../../utils/functions'
import { SingleSelect } from '../../components/common/SingleSelect'

export default function ShopifyIntegration({
  integration,
}: {
  integration: GetIntegrationResult<IntegrationName.SHOPIFY>
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })
  const [locations, setLocations] = useState<ShopifyLocation[]>([])
  const [collections, setColletions] = useState<ShopifyCustomCollection[]>([])

  const handleAppStoreClick = () => {
    let appStoreUrl = 'https://apps.shopify.com/search?q=Sellmotto'
    if (integration.oAuthUrl) {
      appStoreUrl = integration.oAuthUrl
    }
    window.open(appStoreUrl, '_blank')
  }

  useEffect(() => {
    getShopifyLocations()
      .then((res) => {
        if (res.success && res.data) {
          setLocations(res.data)
        }
      })
      .catch((e) => err(e))
  }, [])

  useEffect(() => {
    getShopifyCollections()
      .then((res) => {
        if (res.success && res.data) {
          setColletions(res.data)
        }
      })
      .catch((e) => err(e))
  }, [])

  const handleOpenShop = () => {
    if (!integration?.integration?.shop) return
    const shopName = integration.integration.shop.replaceAll(
      '.myshopify.com',
      '',
    )
    const appStoreUrl = `https://admin.shopify.com/store/${shopName}/`
    window.open(appStoreUrl, '_blank')
  }

  return (
    <Grid container spacing={6}>
      {integration.integration?.shop ? (
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Typography level="title-lg">Shop Details</Typography>
              <Typography level="body-sm">
                Shop ID:{' '}
                {integration.integration.shop?.replace('.myshopify.com', '') ||
                  'Shop name not set.'}
              </Typography>
            </Grid>
            {integration.integration.shop ? (
              <Grid item xs={12}>
                <Button onClick={handleOpenShop}>Open Shop</Button>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      ) : null}

      {integration.integration?.shop ? (
        <>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Typography level="title-lg">Locations</Typography>
                <Typography level="body-xs">
                  Add locations in your Shopify{' '}
                  <a
                    href={`https://admin.shopify.com/store/${integration.integration.shop.replaceAll(
                      '.myshopify.com',
                      '',
                    )}/settings/locations`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    location settings
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <SingleSelect
                  placeholder="View Locations"
                  value=""
                  options={locations.map((l) => l.name)}
                  defaultOption={false}
                ></SingleSelect>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Typography level="title-lg">Collections</Typography>
                <Typography level="body-xs">
                  Manage{' '}
                  <a
                    href={`https://admin.shopify.com/store/${integration.integration.shop.replaceAll(
                      '.myshopify.com',
                      '',
                    )}/collections`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    collections
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <SingleSelect
                  placeholder="View Collections"
                  value=""
                  options={collections.map((c) => c.title)}
                  defaultOption={false}
                ></SingleSelect>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : null}

      {!integration.authenticated ? (
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12}>
              <Typography level="title-lg">Authentication</Typography>
              <Typography level="body-sm">
                To authenticate app access, add the Sellmotto Shopify app to
                your store.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant="solid" onClick={handleAppStoreClick}>
                Open App Store
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      {integration.authenticated ? (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography level="title-lg">Authentication</Typography>
              <Typography level="body-sm">
                To authenticate app access, add the Sellmotto Shopify app to
                your store.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant="solid" onClick={handleAppStoreClick}>
                Re-Authorize
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography level="body-xs">
                Authentication Last Updated:{' '}
                {integration.integration?.updatedAt
                  ? new Date(integration.integration.updatedAt).toLocaleString()
                  : 'Unknown'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
