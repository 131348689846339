import React, { useEffect, useState } from 'react'
import { GetProduct, GetProductTemplate } from '../../../../api/product'
import Grid from '@mui/material/Grid'
import Button from '@mui/joy/Button'
import { ProductAttributeInputText } from '../../../../pages/products/product/ProductAttributeInputText'
import {
  GetIntegrationResult,
  IntegrationName,
  getIntegration,
  listProduct,
  unlistProduct,
} from '../../../../api/integration'
import {
  err,
  getDescriptionValue,
  getTitleValue,
} from '../../../../utils/functions'
import {
  SetShopifyTemplateIntegration,
  ShopifyCustomCollection,
  ShopifyLocation,
  ShopifyProductIntegration,
  ShopifyTemplateIntegration,
} from '../../../../types/Shopify.types'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import {
  createShopifyCollections,
  getShopifyCollections,
  getShopifyIntegrations,
  GetShopifyIntegrationsResult,
  getShopifyLocations,
  setShopifyProductIntegration,
  setShopifyTemplateIntegration,
} from '../../../../api/integrations/shopify'
import { Integration } from '../../../../api/types'
import ListSyncComponent from '../common/ListSyncComponent'
import LoadingBox from '../../../common/LoadingBox'
import { ListUnlistButtons } from '../common/ListUnlistButtons'
import { ProductTitleDescription } from '../../ProductTitleDescription'
import MissingIntegration from '../MissingIntegration'
import NamedSingleSelect from '../../../common/NamedSingleSelect'
import NamedInput from '../../../common/NamedInput'
import NamedMultiSelect from '../../../common/NamedMultiSelect'
import Typography from '@mui/joy/Typography'
import AttributeSelect from '../../../common/AttributeSelect'
import MissingTemplateIntegration from '../MissingTemplateIntegration'

interface ShopifyTemplateIntegrationComponentProps {
  integration?: GetIntegrationResult<IntegrationName.SHOPIFY> | undefined
  template?: GetProductTemplate
  product?: GetProduct
  onUpdate?: () => void
}

export default function ShopifyTemplateIntegrationComponent({
  integration: integrationResult,
  template,
  product,
}: ShopifyTemplateIntegrationComponentProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(!integrationResult)

  const [templateIntegration, setTemplateIntegration] = useState<
    ShopifyTemplateIntegration | undefined
  >()
  const [productIntegration, setProductIntegration] = useState<
    ShopifyProductIntegration | undefined
  >()
  const [integration, setIntegration] = useState<Integration | undefined>(
    integrationResult?.integration,
  )
  const [title, setTitle] = useState<string | undefined>()
  const [description, setDescription] = useState<string | undefined>()
  const [productType, setProductType] = useState<string>()
  const [vendor, setVendor] = useState<string>('')
  const [tags, setTags] = useState<string>('')
  const [collectionIds, setCollectionIds] = useState<string>('')
  const [addCollectionTitles, setAddCollectionTitles] = useState<string[]>([])
  const [selectedCollections, setSelectedCollections] = useState<
    ShopifyCustomCollection[]
  >([])
  const [selectedLocation, setSelectedLocation] = useState<{
    name: string
    id: number
  }>()
  const [detailUpdate, setDetailUpdate] = useState<number>(0)

  const [list, setList] = useState<boolean>(false)
  const [sync, setSync] = useState<boolean>(false)
  const [syncQuantity, setSyncQuantity] = useState<boolean>(false)

  const [locations, setLocations] = useState<ShopifyLocation[]>([])
  const [collections, setCollections] = useState<ShopifyCustomCollection[]>([])
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleListProduct = () => {
    const productId = product?.product.id
    if (!productId) {
      return
    }
    listProduct(IntegrationName.SHOPIFY, productId)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully listed product.')
      })
      .catch((e) => {
        setAlert({
          open: true,
          severity: 'error',
          message: `${e.response.data.message}`,
        })
      })
  }

  const handleUnlistProduct = () => {
    const productId = product?.product.id
    if (!integration?.id || !productId) return
    unlistProduct(IntegrationName.SHOPIFY, productId, integration.id)
      .then((res) => {
        handleAlert(setAlert, res, 'Successfully Unlisted.')
      })
      .catch((e) =>
        setAlert({
          open: true,
          severity: 'error',
          message: e.response.data.message,
        }),
      )
  }

  // Get and set ShopifyTemplateIntegration
  useEffect(() => {
    // Shopify Shop Locations
    getShopifyLocations()
      .then((res) => {
        if (res.success && res.data) {
          setLocations(res.data)
        }
      })
      .catch((e) => err(e))

    // Shopify Shop Collections
    getShopifyCollections()
      .then((res) => {
        if (res.success && res.data) {
          setCollections(res.data)
        }
      })
      .catch((e) => err(e))

    // Integration
    if (!integration) {
      getIntegration(IntegrationName.SHOPIFY)
        .then((res) => {
          setLoading(false)
          if (res.success && res.data) {
            setIntegration(res.data.integration)
          }
        })
        .catch((e) => err(e))
    }

    if (!integration) {
      return
    }
  }, [integration])

  useEffect(() => {
    if (!integration) {
      return
    }

    const integrationId = integration.id
    const templateId = template?.template.id || product?.template.id
    // Square Template Integration
    if (integrationId && templateId) {
      getShopifyIntegrations(integrationId, templateId, product?.product.id)
        .then((res) => {
          if (res.success && res.data) {
            handleSetState(res.data)
          }
        })
        .catch((e) => err(e))
    }
  }, [integration, product, template])

  const handleSetState = (data: GetShopifyIntegrationsResult) => {
    const shopifyProductIntegration = data.productIntegration
    const shopifyTemplateIntegration = data.templateIntegration
    setTemplateIntegration(shopifyTemplateIntegration)
    setProductIntegration(shopifyProductIntegration)

    setList(
      shopifyProductIntegration?.list ??
        shopifyTemplateIntegration?.list ??
        false,
    )
    setSync(
      shopifyProductIntegration?.sync ??
        shopifyTemplateIntegration?.sync ??
        false,
    )
    setSyncQuantity(
      shopifyProductIntegration?.syncQuantity ??
        shopifyTemplateIntegration?.syncQuantity ??
        false,
    )

    setTags(
      shopifyProductIntegration?.tags ?? shopifyTemplateIntegration?.tags ?? '',
    )

    setCollectionIds(
      shopifyProductIntegration?.collectionIds ??
        shopifyTemplateIntegration?.collectionIds ??
        '',
    )

    setProductType(
      shopifyProductIntegration?.productType ??
        shopifyTemplateIntegration?.productType ??
        '',
    )
    setVendor(
      shopifyProductIntegration?.vendor ??
        shopifyTemplateIntegration?.vendor ??
        '',
    )
  }

  useEffect(() => {
    const useLocationId =
      productIntegration?.locationId ?? templateIntegration?.locationId
    if (!locations?.length || !useLocationId) return
    const location = locations.find(
      (l) => `${l.id}` === templateIntegration?.locationId,
    )
    if (!location) return
    setSelectedLocation({ id: location.id, name: location.name })
  }, [
    productIntegration?.locationId,
    templateIntegration?.locationId,
    locations,
  ])

  useEffect(() => {
    const useCollectionIds =
      productIntegration?.collectionIds ?? templateIntegration?.collectionIds
    if (!collections?.length || !useCollectionIds) return

    const collectionIdsArr = useCollectionIds?.split(',')
    const newSelectedCollections = collections.filter((c) =>
      collectionIdsArr.includes(`${c.id}`),
    )
    if (!newSelectedCollections?.length) return
    setSelectedCollections(newSelectedCollections)
  }, [
    templateIntegration?.collectionIds,
    productIntegration?.collectionIds,
    collections,
  ])

  // Set title and description state
  useEffect(() => {
    const title = getTitleValue(
      productIntegration,
      templateIntegration,
      product,
    )
    const description = getDescriptionValue(
      productIntegration,
      templateIntegration,
      product,
    )

    setTitle(title)
    setDescription(description)
    setDetailUpdate(detailUpdate + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, template, productIntegration, templateIntegration])

  const handleSelectLocation = (locationName: string | undefined) => {
    const location = locations.find((l) => l.name === locationName)
    if (!location) {
      setSelectedLocation(undefined)
      return
    }
    setSelectedLocation({ id: location.id, name: location.name })
  }

  const handleSelectCollection = (collectionNames: string[]) => {
    const selectedCollections = collections.filter((c) =>
      collectionNames.includes(c.title),
    )
    const newCollectionTitles = collectionNames.filter(
      (c) => !selectedCollections.map((s) => s.title).includes(c),
    )

    const addCollections = newCollectionTitles.map(
      (c): ShopifyCustomCollection => ({
        id: 0,
        title: c,
      }),
    )
    console.log('New Collections', addCollections)

    setAddCollectionTitles(newCollectionTitles)
    setSelectedCollections(selectedCollections.concat(addCollections))
    if (!selectedCollections?.length) {
      setCollectionIds('')
      return
    }
    setCollectionIds(selectedCollections.map((c) => c.id).join(','))
  }

  const handleSave = async () => {
    // Add New Collections
    let allCollectionIds = collectionIds
    if (addCollectionTitles?.length) {
      await createShopifyCollections(addCollectionTitles)
        .then((res) => {
          if (res.success && res.data) {
            // Add created collections
            setCollections(collections.concat(res.data)) // add created to collections
            setAddCollectionTitles([]) // clear add
            setSelectedCollections(selectedCollections.concat(res.data)) // set as selected

            const newCollectionids = res.data.map((c) => `${c.id}`)
            allCollectionIds = allCollectionIds
              .split(',')
              .concat(newCollectionids)
              .join(',')
          }
        })
        .catch((e) => err(e))
    }

    if (product && templateIntegration) {
      const updateProductIntegration = {
        templateIntegrationId: templateIntegration?.id,
        productId: product.product.id,
        title,
        description,
        locationId: selectedLocation ? `${selectedLocation.id}` : '',
        productType,
        collectionIds: allCollectionIds,
        vendor,
        tags,
        list,
        sync,
        syncQuantity,
      }
      setShopifyProductIntegration(updateProductIntegration)
        .then((res) => {
          if (res.success && res.data) {
            setProductIntegration(res.data)
          }
          handleAlert(setAlert, res, 'Set Shopify Product Integration.')
        })
        .catch((e) => {
          err(e)
        })
      return
    }

    if (!integration || !template) return
    const shopifyTemplateIntegration: SetShopifyTemplateIntegration = {
      integrationId: integration.id,
      templateId: template?.template.id,
      title: title || '',
      description: description || '',
      locationId: selectedLocation ? `${selectedLocation.id}` : '',
      productType,
      collectionIds: allCollectionIds,
      vendor,
      tags,
      list,
      sync,
      syncQuantity,
    }
    setShopifyTemplateIntegration(shopifyTemplateIntegration)
      .then((res) => {
        if (res.success && res.data) {
          setTemplateIntegration(res.data)
        }
        handleAlert(setAlert, res, 'Set Shopify Template Integration.')
      })
      .catch((e) => {
        err(e)
      })
  }

  const attributeNames =
    template?.attributes.map((a) => a.attribute.name) ||
    product?.attributes.map((a) => a.templateAttribute.name)

  if (loading) {
    return <LoadingBox />
  }

  if (!integration) {
    return <MissingIntegration />
  }

  if (product && !templateIntegration) {
    return (
      <MissingTemplateIntegration
        templateId={product.product.templateId}
        channelName={IntegrationName.CSV}
      />
    )
  }

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <ListUnlistButtons
          list={list}
          hasProductIntegration={!!productIntegration}
          product={product}
          onList={handleListProduct}
          onUnlist={handleUnlistProduct}
        />
      </Grid>
      <Grid item xs={12}>
        <ListSyncComponent
          list={list}
          sync={sync}
          syncQuantity={syncQuantity}
          onListChange={setList}
          onSyncChange={setSync}
          onSyncQuantityChange={setSyncQuantity}
        />
      </Grid>

      <Grid item xs={12}>
        <ProductTitleDescription
          key={`product-title-description-${detailUpdate}`}
          template={template}
          product={product}
          title={title}
          description={description}
          required={!!product}
          onTitleChange={setTitle}
          onDescriptionChange={setDescription}
          disableUnparsedEdit={true}
        />
      </Grid>

      {product && (
        <>
          <Grid item xs={12}>
            <ProductAttributeInputText
              label="SKU"
              value={product.product.sku}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <ProductAttributeInputText
              label="Condition"
              value={product.product.condition}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <NamedInput
              name="Price"
              nameEditable={false}
              value={product.product.price}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <NamedInput
              name="Quantity"
              nameEditable={false}
              value={product.product.quantity}
              disabled={true}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <NamedSingleSelect
          name="Shopify Location"
          nameEditable={false}
          value={selectedLocation?.name || ''}
          onChange={(location) => handleSelectLocation(location)}
          options={locations.map((l) => l.name)}
        />
      </Grid>

      <Grid item xs={12}>
        <AttributeSelect
          name="Product Type"
          nameEditable={false}
          value={productType}
          attributeNames={attributeNames}
          multiple={false}
          onChange={(v) => setProductType(v || '')}
        />
      </Grid>

      <Grid item xs={12}>
        <AttributeSelect
          name="Vendor"
          nameEditable={false}
          value={vendor}
          multiple={false}
          attributeNames={attributeNames}
          onChange={(v) => setVendor(v || '')}
        />
      </Grid>

      <Grid item xs={12}>
        <AttributeSelect
          name="Tags"
          values={tags ? tags.split(',') : []}
          onSelect={(values) => setTags((values as string[]).join(','))}
          attributeNames={attributeNames}
          multiple={true}
        />
      </Grid>

      <Grid item xs={12}>
        <NamedMultiSelect
          name="Collections"
          value={selectedCollections.map((c) => c.title)}
          onChange={(value) => handleSelectCollection(value)}
          options={collections.map((c) => c.title)}
          helperText={
            addCollectionTitles?.length ? (
              <Typography level="body-sm">
                Add new collections:{' '}
                {addCollectionTitles.map((title, i) => (
                  <Typography level="title-sm" key={title}>
                    <strong>{title}</strong>
                    {i !== addCollectionTitles.length - 1 ? ', ' : ''}
                  </Typography>
                ))}
              </Typography>
            ) : null
          }
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button variant="solid" onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
