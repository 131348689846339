import React, { useState } from 'react'
import { GetIntegrationResult, IntegrationName } from '../../api/integration'
import Grid from '@mui/material/Grid'
import Button from '@mui/joy/Button'
import Alert, { AlertInput } from '../../components/common/Alert'
import Typography from '@mui/joy/Typography'

export default function EtsyIntegration({
  integration,
}: {
  integration: GetIntegrationResult<IntegrationName.ETSY>
}): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const handleAppStoreClick = () => {
    if (!integration.oAuthUrl) return
    window.open(integration.oAuthUrl, '_blank')
  }

  return (
    <Grid container spacing={2}>
      {integration.authenticated ? (
        <>
          <Grid item xs={12}>
            {integration.integration?.shop ? (
              <Grid container justifyContent="center" spacing={4}>
                <Grid item xs={12}>
                  <Typography level="title-lg">Shop Details</Typography>
                  <Typography level="body-sm">
                    Shop ID:{' '}
                    {integration.integration.shop || 'Shop name not set.'}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </>
      ) : null}

      {!integration.authenticated ? (
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12}>
              <Typography level="title-lg">Authentication</Typography>
              <Typography level="body-sm">
                To authenticate app access, install the Sellmotto App to your
                store.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant="solid" onClick={handleAppStoreClick}>
                Open App Store
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      {integration.authenticated ? (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography level="title-lg">Authentication</Typography>
              <Typography level="body-sm">
                To re-authenticate app access, re-install the Sellmotto App to
                your store.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant="solid" onClick={handleAppStoreClick}>
                Re-Authorize
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography level="body-xs">
                Authentication Last Updated:{' '}
                {integration.integration?.updatedAt
                  ? new Date(integration.integration.updatedAt).toLocaleString()
                  : 'Unknown'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : null}

      <Grid item xs={12}></Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
