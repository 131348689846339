import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import { Aspect, AspectModeEnum } from '../../../../types/Ebay.types'
import { getAttributeName, isLike } from '../../../../utils/functions'
import {
  GetProductTemplateAttribute,
  ProductTemplateAttributeInput,
  updateProductTemplateAttributes,
} from '../../../../api/product'

import { useCallback } from 'react'
import { useMemo } from 'react'
import { DEFAULT_ATTRIBUTE_TYPE } from '../../../../utils/constants'
import AspectExamples from './AspectExamples'
import { AspectIntegrationInput } from './EbayTemplateIntegrationComponent'
import AttributeSelect from './AttributeSelect'

export default function AspectGroup({
  aspectIntegration,
  aspect,
  templateAttributes,
  onChange,
  onUpdate,
}: {
  aspect: Aspect
  aspectIntegration: AspectIntegrationInput | undefined
  templateAttributes: GetProductTemplateAttribute[]
  onChange: (aspect: AspectIntegrationInput) => void
  onUpdate?: () => void
}): JSX.Element {
  // const value = aspectIntegration?.value
  const [value, setValue] = useState<AspectIntegrationInput | undefined>(
    aspectIntegration,
  )

  const handleChange = useCallback(
    (newValue: string) => {
      const newAspect: AspectIntegrationInput = {
        value: newValue,
        aspectName: aspect.localizedAspectName,
        dataType: aspect.aspectConstraint.aspectDataType,
        required: aspect.aspectConstraint.aspectRequired,
      }
      setValue(newAspect)
    },
    [
      aspect.aspectConstraint.aspectDataType,
      aspect.aspectConstraint.aspectRequired,
      aspect.localizedAspectName,
      setValue,
    ],
  )

  const templateAttributeSelected = getAttributeName(aspectIntegration?.value)

  const selectedTemplateAttribute = templateAttributeSelected
    ? templateAttributes.find((a) =>
        isLike(a.attribute.name, templateAttributeSelected),
      )
    : undefined

  const freeSolo =
    aspect.aspectConstraint.aspectMode === AspectModeEnum.FREE_TEXT

  const aspectLabel = freeSolo ? 'Examples' : 'Possible'

  const options = useMemo(() => {
    return aspect.aspectValues?.map((value) => value.localizedValue) || []
  }, [aspect.aspectValues])

  const handleAddTemplateAttributeOption = async (options: string[]) => {
    if (!selectedTemplateAttribute) return
    // Get template id from template attribute
    const templateId = selectedTemplateAttribute.attribute.templateId
    const templateAttributeName = selectedTemplateAttribute.attribute.name

    const newIndex = templateAttributes.length // length+1
    const newOptionIndex = selectedTemplateAttribute.attributeOptions.length // length+1
    if (!templateId || !templateAttributeName) return

    // add attribute with options
    const addTemplateAttr: ProductTemplateAttributeInput = {
      attribute: {
        name: templateAttributeName,
        type: DEFAULT_ATTRIBUTE_TYPE,
        index: newIndex,
      },
      attributeOptions: options.map((o) => ({
        value: o,
        type: DEFAULT_ATTRIBUTE_TYPE,
        index: newOptionIndex,
      })),
    }

    const res = await updateProductTemplateAttributes(templateId, [
      addTemplateAttr,
    ])
    if (res.success && res.data) {
      console.log('success')
    }
    onUpdate?.()
  }

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={1}>
      <Grid item xs={12}>
        <Grid container alignContent="center" alignItems="center" spacing={0}>
          <Grid item xs={12} sm={12} md={3} lg={2}>
            <Typography
              sx={{
                mr: '0.5em',
                mb: '0.5em',
              }}
              level="title-sm"
            >
              <strong>{aspect.localizedAspectName}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={10}>
            <Grid container spacing={1} alignContent="center">
              <Grid
                item
                sm={options.length ? 7 : 7}
                lg={options.length ? 8 : 8}
                xs={12}
              >
                <Grid container spacing={1} alignContent="center">
                  <Grid item xs={12}>
                    <AttributeSelect
                      aspectName={aspect.localizedAspectName}
                      aspectIntegration={aspectIntegration}
                      templateAttributes={templateAttributes}
                      onChange={(newValue) => handleChange(newValue || '')}
                      onBlur={() => value && onChange(value)}
                      onUpdate={onUpdate}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {options.length ? (
                <Grid item sm={5} xs={12} lg={4}>
                  <AspectExamples
                    label={aspectLabel}
                    options={options}
                    templateAttribute={selectedTemplateAttribute}
                    onChange={handleAddTemplateAttributeOption}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
