import React from 'react'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/joy/Typography'
import { SingleSelect } from '../../../components/common/SingleSelect'

interface ProductAttributeInputSelectProps {
  label?: string
  value: string | undefined
  options: string[]
  required?: boolean
  disabled?: boolean
  onChange: (typeParam: string) => void
}

export function ProductAttributeInputSelect({
  label,
  value,
  options,
  required,
  disabled,
  onChange,
}: ProductAttributeInputSelectProps): JSX.Element {
  return (
    <Grid container spacing={1} justifyContent="center">
      {label && (
        <Grid item xs={12} md={3} lg={2}>
          <Stack
            direction="row"
            justifyContent="center"
            sx={{
              position: 'relative',
              height: '100%',
              top: 10,
            }}
          >
            <Typography
              sx={{
                mr: '0.5em',
                mb: '0.5em',
                color: disabled ? 'rgba(0, 0, 0, 0.38);' : 'inherit',
              }}
            >
              <strong>{label}</strong>
            </Typography>
          </Stack>
        </Grid>
      )}
      <Grid item xs={12} md={label ? 9 : 12} lg={label ? 10 : 12}>
        <SingleSelect
          value={value || ''}
          required={required}
          onChange={(v) => onChange(v || '')}
          disabled={disabled}
          options={options}
        ></SingleSelect>
      </Grid>
    </Grid>
  )
}
