import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import Box from '@mui/material/Box'
import {
  EbayProductListingConfiguration,
  EbayTemplateListingConfiguration,
} from '../../../../types/Ebay.types'
import { ProductAttributeInputText } from '../../../../pages/products/product/ProductAttributeInputText'
import { copy, err, log, round } from '../../../../utils/functions'
import Switch from '@mui/joy/Switch'
import {
  getEbayProductListingConfiguration,
  getEbayTemplateListingConfiguration,
  setEbayProductListingConfiguration,
  setEbayTemplateListingConfiguration,
} from '../../../../api/integrations/ebay'
import Button from '@mui/joy/Button'
import AccordionGroup from '@mui/joy/AccordionGroup'
import Accordion from '@mui/joy/Accordion'
import AccordionSummary from '@mui/joy/AccordionSummary'
import ListItemContent from '@mui/joy/ListItemContent'
import AccordionDetails from '@mui/joy/AccordionDetails'

const DEFAULT_PRICE = 100
const DEFAULT_MAX_PRICE = 150
const DEFAULT_REDUCE_BY = 10
const DEFAULT_REDUCE_EVERY = 14
const DEFAULT_BUY_IT_NOW_PRICE = 150
const DEFAULT_AUCTION_LENGTH = 7

const DEFAULT_PRODUCT_LISTING_CONFIGURATION = {
  price: false,
  basePrice: DEFAULT_PRICE,

  reducePrice: false,
  maxPrice: DEFAULT_MAX_PRICE,
  reduceBy: DEFAULT_REDUCE_BY,
  reduceEvery: DEFAULT_REDUCE_EVERY,
  lastReduced: undefined,

  listAuction: false,
  startPrice: DEFAULT_PRICE,
  buyItNowPrice: DEFAULT_BUY_IT_NOW_PRICE,
  auctionLength: DEFAULT_AUCTION_LENGTH,
  relistAuction: 0,

  relist: false,
  relistEvery: 60,
}

interface EbayListingConfigurationsComponentProps {
  templateIntegrationId?: number
  productIntegrationId?: number
  productId?: number
}

export default function EbayListingConfigurationsComponent({
  templateIntegrationId,
  productIntegrationId,
  productId,
}: EbayListingConfigurationsComponentProps): JSX.Element {
  const [expanded, setExpanded] = useState(false)
  const [templateListingConfiguration, setTemplateListingConfiguration] =
    useState<EbayTemplateListingConfiguration | undefined>(undefined)

  const [productListingConfiguration, setProductListingConfiguration] =
    useState<EbayProductListingConfiguration | undefined>(undefined)

  const handleCollapse = () => {
    if (expanded) {
      // collapsing, save
      handleSave()
    }
    setExpanded(!expanded)
  }
  const handleSave = () => {
    if (
      templateListingConfiguration &&
      templateListingConfiguration.ebayTemplateIntegrationId
    ) {
      setEbayTemplateListingConfiguration(templateListingConfiguration)
        .then((res) => {
          if (res.success && res.data) {
            log(res)
          }
        })
        .catch((e) => err(e))
    } else if (
      productListingConfiguration &&
      productListingConfiguration.ebayProductIntegrationId
    ) {
      setEbayProductListingConfiguration(productListingConfiguration)
        .then((res) => {
          if (res.success && res.data) {
            log(res)
          }
        })
        .catch((e) => err(e))
    }
  }

  useEffect(() => {
    if (templateIntegrationId) {
      getEbayTemplateListingConfiguration(templateIntegrationId)
        .then((res) => {
          if (res.success && res.data) {
            setTemplateListingConfiguration(res.data)
          } else if (res.success) {
            // success but value is undefined set to default value
            setTemplateListingConfiguration({
              ebayTemplateIntegrationId: templateIntegrationId,
              ...DEFAULT_PRODUCT_LISTING_CONFIGURATION,
            })
          }
        })
        .catch((e) => log(e))
    } else if (productIntegrationId) {
      getEbayProductListingConfiguration(productIntegrationId)
        .then((res) => {
          console.log(res.data)
          if (res.success && res.data) {
            setProductListingConfiguration(res.data)
          } else if (res.success) {
            // success but value is undefined set to default value
            setProductListingConfiguration({
              ebayProductIntegrationId: productIntegrationId,

              ...DEFAULT_PRODUCT_LISTING_CONFIGURATION,
            })
          }
        })
        .catch((e) => log(e))
    }
  }, [templateIntegrationId, productIntegrationId])

  const handleChange = (
    field:
      | keyof EbayTemplateListingConfiguration
      | keyof EbayProductListingConfiguration,
    value: string | number | boolean | undefined,
  ) => {
    if (templateListingConfiguration) {
      const fieldType = field as keyof EbayTemplateListingConfiguration
      const newTemplateListingConfiguration = copy(templateListingConfiguration)

      // Not safe type assertion
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newTemplateListingConfiguration[fieldType] = value as any
      setTemplateListingConfiguration(newTemplateListingConfiguration)
    } else if (productListingConfiguration) {
      const fieldType = field as keyof EbayProductListingConfiguration
      const newProductListingConfiguration = copy(productListingConfiguration)

      // Not safe type assertion
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newProductListingConfiguration[fieldType] = value as any
      setProductListingConfiguration(newProductListingConfiguration)
    }
  }

  const handlePriceReductionReset = async () => {
    if (productListingConfiguration) {
      const newProductListingConfiguration = copy(productListingConfiguration)
      newProductListingConfiguration.lastReduced = null
      setProductListingConfiguration(newProductListingConfiguration)
    }
  }

  const config = productListingConfiguration || templateListingConfiguration
  return (
    <AccordionGroup size="lg" variant="outlined" sx={{ borderRadius: '6px' }}>
      <Accordion>
        <AccordionSummary
          slotProps={{
            button: {
              style: {
                borderRadius: '6px',
              },
            },
          }}
        >
          <ListItemContent sx={{ p: 2 }} onClick={() => handleCollapse()}>
            <Typography level="title-lg">Listing and Pricing</Typography>
            <Typography level="body-xs">
              {!productId
                ? 'Configure how products of this template are listed to ebay.'
                : 'Configure how this product is listed to ebay.'}
            </Typography>
          </ListItemContent>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ pt: 6 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={1}>
                  <Typography
                    component="label"
                    level="title-lg"
                    endDecorator={
                      <Switch
                        onChange={() => handleChange('price', !config?.price)}
                        checked={config?.price || false}
                      />
                    }
                  >
                    Price Configuration
                  </Typography>
                  <Grid item xs={12}>
                    <Typography level="body-xs" component="div">
                      Price Configuration will be used for setting eBay listings
                      prices. <br /> Ex. A product of price $100 will be listed
                      for ${round(((config?.basePrice || 100) * 100) / 100)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ p: 2 }}>
                      <Grid container justifyContent="center" spacing={1}>
                        <Grid item xs={12}>
                          <ProductAttributeInputText
                            type="number"
                            label="Price (%)"
                            required
                            disabled={!config?.price}
                            value={`${config?.basePrice || ''}`}
                            onChange={(v) =>
                              handleChange('basePrice', parseFloat(v))
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={1}>
                  <Typography
                    component="label"
                    level="title-lg"
                    endDecorator={
                      <Switch
                        onChange={() =>
                          handleChange('reducePrice', !config?.reducePrice)
                        }
                        checked={config?.reducePrice || false}
                      />
                    }
                  >
                    Price Reduction Configuration
                  </Typography>

                  <Grid item xs={12}>
                    <Typography level="body-xs" component="div">
                      Listing will be started at {config?.maxPrice}% and reduced
                      by {config?.reduceBy}% every {config?.reduceEvery} days
                      until at the base configured price.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {config?.lastReduced ? (
                      <Grid container justifyContent="center" spacing={1}>
                        <Grid item xs={12}>
                          <Typography level="body-xs">
                            Price has last been reduced{' '}
                            {new Date(config.lastReduced).toUTCString()}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="solid"
                            size="sm"
                            onClick={handlePriceReductionReset}
                            disabled={!config?.reducePrice}
                          >
                            Restart from Max Price
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Typography>
                        Price Reduction will start when product is listed.
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ p: 2 }}>
                      <Grid container justifyContent="center" spacing={1}>
                        <Grid item xs={12}>
                          <ProductAttributeInputText
                            type="number"
                            label="Max Price (%)"
                            required
                            disabled={!config?.reducePrice}
                            value={`${config?.maxPrice}`}
                            onChange={(v) =>
                              handleChange('maxPrice', parseFloat(v))
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ProductAttributeInputText
                            type="text"
                            label="Price Reduction (%)"
                            required
                            disabled={!config?.reducePrice}
                            value={`${config?.reduceBy}`}
                            onChange={(v) =>
                              handleChange('reduceBy', parseFloat(v))
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ProductAttributeInputText
                            type="number"
                            label="Reduce Every"
                            required
                            disabled={!config?.reducePrice}
                            value={`${config?.reduceEvery}`}
                            onChange={(v) =>
                              handleChange('reduceEvery', parseFloat(v))
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={1}>
                  <Typography
                    component="label"
                    level="title-lg"
                    endDecorator={
                      <Switch
                        onChange={() =>
                          handleChange('listAuction', !config?.listAuction)
                        }
                        checked={config?.listAuction || false}
                      />
                    }
                  >
                    List As Auction
                  </Typography>
                  <Grid item xs={12}>
                    <Typography level="body-xs" component="div">
                      Only available for non-variant products. <br />
                      Item will be listed at {config?.startPrice}% with a Buy It
                      Now Price of {config?.buyItNowPrice}% for{' '}
                      {config?.auctionLength} days on the following{' '}
                      {config?.startDay} at {config?.startTime}. <br />
                      If Auction goes un-sold, it will be relisted as an auction{' '}
                      {config?.relistAuction} times. Afterwards it will be
                      listed according to the Price Reduction Configuration.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ p: 2 }}>
                      <Grid container justifyContent="center" spacing={1}>
                        <Grid item xs={12}>
                          <ProductAttributeInputText
                            type="number"
                            label="Start Price (%)"
                            required
                            disabled={!config?.listAuction}
                            value={`${config?.startPrice}`}
                            onChange={(v) =>
                              handleChange('startPrice', parseFloat(v))
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ProductAttributeInputText
                            type="number"
                            label="Buy It Now Price (%)"
                            required
                            disabled={!config?.listAuction}
                            value={`${config?.buyItNowPrice}`}
                            onChange={(v) =>
                              handleChange('buyItNowPrice', parseFloat(v))
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ProductAttributeInputText
                            type="number"
                            label="Auction Length"
                            required
                            disabled={!config?.listAuction}
                            value={`${config?.auctionLength}`}
                            onChange={(v) =>
                              handleChange('auctionLength', parseFloat(v))
                            }
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <ProductAttributeInputText
                            type="text"
                            label="Start Time"
                            disabled
                            value={`${config?.startTime || ''}`}
                            onChange={(v) => handleChange('startTime', v)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ProductAttributeInputText
                            type="text"
                            label="Start Day"
                            disabled
                            value={`${config?.startDay || ''}`}
                            onChange={(v) => handleChange('startDay', v)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ProductAttributeInputText
                            type="number"
                            label="Relist as Auction"
                            required
                            disabled={!config?.listAuction}
                            value={`${config?.relistAuction || 0}`}
                            onChange={(v) =>
                              handleChange('relistAuction', parseInt(v))
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={1}>
                  <Typography
                    component="label"
                    level="title-lg"
                    endDecorator={
                      <Switch
                        onChange={() => handleChange('relist', !config?.relist)}
                        checked={config?.relist || false}
                      />
                    }
                  >
                    Renew Listing
                  </Typography>

                  <Grid item xs={12}>
                    <Typography level="body-xs" component="div">
                      End and Re-List items to prevent stale listings. Item
                      Listing will be renewed every {config?.relistEvery} days.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ p: 2 }}>
                      <Grid container justifyContent="center" spacing={3}>
                        <Grid item xs={12}>
                          <ProductAttributeInputText
                            type="number"
                            label="Renew Every"
                            disabled={!config?.relist}
                            value={`${config?.relistEvery}`}
                            onChange={(v) =>
                              handleChange('relistEvery', parseInt(v))
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Button variant="solid" onClick={handleSave}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </AccordionGroup>

    // <Grid container justifyContent="center" spacing={3}>
    //   <Grid item xs={12}>
    //     <Grid
    //       container
    //       direction="row"
    //       justifyContent="center"
    //       onClick={() => handleCollapse()}
    //       sx={{ cursor: 'pointer' }}
    //     >
    //       <Grid item xs={11}>
    //         <Grid container justifyContent="center" direction="row" spacing={1}>
    //           <Grid item xs={12}>
    //             <Typography level="title-lg" component="div">
    //               Listing and Pricing
    //             </Typography>
    // <Typography level="body-xs">
    //   {!productId
    //     ? 'Configure how products of this template are listed to ebay.'
    //     : 'Configure how this product is listed to ebay.'}
    // </Typography>
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //       <Grid item xs={1}>
    //         <ExpandMore expand={expanded} onClick={() => handleCollapse()}>
    //           <ExpandMoreIcon />
    //         </ExpandMore>
    //       </Grid>
    //     </Grid>
    //   </Grid>
    //   <Grid item xs={12}>
    //     <Collapse
    //       in={expanded}
    //       sx={{ border: '1px solid lightgray', borderRadius: 2 }}
    //     >

    //     </Collapse>
    //   </Grid>
    // </Grid>
  )
}
