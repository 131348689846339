import React, { useEffect, useState } from 'react'
import { err } from '../../../../utils/functions'
import PaginatedTable from '../../../common/PaginatedTable'
import Button from '@mui/joy/Button'
import Alert, { AlertInput, handleAlert } from '../../../common/Alert'
import {
  cloverBulkImport,
  getCloverItems,
} from '../../../../api/integrations/clover'
import { CloverInventoryItem } from '../../../../types/Clover.types'
import { SingleSelect } from '../../../common/SingleSelect'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import Checkbox from '@mui/joy/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/joy/Box'

interface CloverImportOffersProps {
  templates?: string[]
}

export default function CloverImportItems({
  templates,
}: CloverImportOffersProps): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [cloverItems, setCloverItems] = useState<CloverInventoryItem[]>([])

  const [selectedTemplate, setSelectedTemplate] = useState<string | undefined>()

  const [loading, setLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)

  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([])

  const [updateExistingProducts, setUpdateExistingProducts] =
    useState<boolean>(false)

  const handleImport = () => {
    const ids = selectedItemIds
    if (!ids.length) return
    cloverBulkImport({
      ids,
      template: selectedTemplate,
      updateExistingProducts,
    })
      .then((res) => {
        handleAlert(setAlert, res, 'Import process started.')
      })
      .catch((e) => err(e))
  }

  const handleBulkImport = () => {
    cloverBulkImport({ template: selectedTemplate, updateExistingProducts })
      .then((res) => {
        handleAlert(setAlert, res, 'Bulk import process started.')
      })
      .catch((e) => err(e))
  }

  const handleGetProducts = () => {
    setLoading(true)
    const limit = pageSize
    const offset = pageSize * page
    getCloverItems({
      limit,
      offset,
    })
      .then((res) => {
        if (res.success && res.data) {
          setCloverItems(res.data.items)
          setLoading(false)
        }
      })
      .catch((e) => err(e))
  }

  useEffect(() => {
    setLoading(true)
    const limit = pageSize
    const offset = pageSize * page
    getCloverItems({
      offset,
      limit,
    })
      .then((res) => {
        if (res.success && res.data) {
          setCloverItems(res.data.items)
          setLoading(false)
        }
      })
      .catch((e) => err(e))
  }, [page, pageSize])

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
    handleGetProducts()
  }
  const handlePageSizeChange = (newPageSize: number) => {
    setPage(0)
    setPageSize(newPageSize)
  }

  const handleRowClick = (row: CloverInventoryItem) => {
    const id = row.id
    const newSelectedIds = selectedItemIds.slice()
    const isSelected = selectedItemIds.find((selectedId) => selectedId === id)

    if (isSelected) {
      const newSelectedIds = selectedItemIds.filter(
        (selectedId) => selectedId !== id,
      )

      setSelectedItemIds(newSelectedIds)
    } else {
      newSelectedIds.push(id)
      setSelectedItemIds(newSelectedIds)
    }

    // Set all selected
  }

  const handleSelectAll = () => {
    // Add or remove all

    const allIds = cloverItems.map((squareItem) => squareItem.id)
    if (!allIds) return

    const notSelected = allIds?.filter((id) => !selectedItemIds.includes(id))

    if (notSelected?.length) {
      // select all
      const newSelectedIds = selectedItemIds.concat(notSelected)
      setSelectedItemIds(newSelectedIds)
    } else {
      // remove all
      const newSelectedIds = selectedItemIds.filter(
        (selectedSku) => !allIds.includes(selectedSku),
      )
      setSelectedItemIds(newSelectedIds)
    }
  }

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12}>
        <Typography level="title-lg">Clover Item Import</Typography>
        <Typography level="body-xs">
          Variations are displayed. Variation Import is currently not supported.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box p={3}>
          <PaginatedTable
            count={undefined}
            page={page}
            pageSize={pageSize}
            pageSizeOptions={[5, 10, 25]}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            disabled={loading}
            head={[
              <span key="select" onClick={handleSelectAll}>
                Select All
              </span>,
              'Title',
              'SKU',
              // 'Offer',
              // 'Status',
              // 'Quantity',
              'Price',
              // 'Listing',
            ]}
            data={cloverItems}
            onRowClick={handleRowClick}
            row={(row, i) => {
              const id = row.id || ''
              const title = row.name
              const sku = row.sku
              const price = row.price

              const isSelected = selectedItemIds.find(
                (selectedId) => selectedId === id,
              )

              const rowArray = [
                <Checkbox
                  sx={{ p: 0, top: '3px' }}
                  key={id}
                  checked={!!isSelected}
                  size="sm"
                ></Checkbox>,
                title,
                sku,
                price,
              ]

              return rowArray
            }}
          ></PaginatedTable>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <Typography level="title-lg">Import Template</Typography>
            <Typography level="body-xs">
              Items will be merged into selected template. If none is selected,
              each item Category will be mapped to a new Template Example:
              &quot;Clover Category&quot;
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SingleSelect
              label="Select Template"
              freeSolo={true}
              value={selectedTemplate || ''}
              onChange={(template) => setSelectedTemplate(template)}
              options={templates || []}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={updateExistingProducts}
                        onClick={() =>
                          setUpdateExistingProducts(!updateExistingProducts)
                        }
                      />
                    }
                    label={
                      <Grid container justifyContent="center">
                        <Grid item xs={12}>
                          <Typography level="body-md">
                            <strong>Update Existing Products</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography level="body-xs">
                            Existing data may be overwritten
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {selectedItemIds.length} Items will be imported into{' '}
              {selectedTemplate ? (
                <strong>{selectedTemplate}</strong>
              ) : (
                'new Template(s)'
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button variant="solid" onClick={handleBulkImport}>
              Bulk Import All Inventory
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="solid"
              onClick={handleImport}
              disabled={!selectedItemIds.length}
            >
              Import
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </Grid>
  )
}
